import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/interceptors/global-error-handler';
import { GlobalHttpInterceptor } from './core/interceptors/global-http-interceptor';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { GenericFunctionFilterPipe } from './pipes/generic-function-filter.pipe';
import { TrendPipe } from './pipes/trend.pipe';
import { SupportModule } from './support/support.module';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
registerLocaleData(localeNl);

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AppComponent,
        TrendPipe,
        GenericFunctionFilterPipe,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        DashboardModule,
        LoginModule,
        SupportModule,
        TagCloudModule,
        MatTableModule,
        MatSortModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'nl-NL'},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true}
    ]
})
export class AppModule {
}
