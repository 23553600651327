<mat-card [ngClass]="cardClass">
    <mat-card-header >
        <mat-card-title>{{title}}</mat-card-title>

        <div id="orgScore" class="pill-sub-text" [style.color]="'black'">
            <span class="sub-prefix" *ngIf="prefix">{{prefix}}</span>
            <span> {{(orgScore | number:decimalFormatter) || 'n/a'}}</span>
            <span class="sub-postfix" *ngIf="postfix">{{postfix}}</span>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="pill-shape" [ngClass]="colorClass">
            <div id="teamScore" class="pill-text">
                <span class="prefix" *ngIf="prefix">{{prefix}}</span>
                <span>{{(teamScore | number:decimalFormatter) || 'n/a'}}</span>
                <span class="postfix" *ngIf="postfix">{{postfix}}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>
