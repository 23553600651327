<div [hidden]="errorMessage" *ngIf="dataSource$ | async as dataSource">
    <section class="row">
        <mat-card class="full-card limited">
            <mat-card-header>
                <mat-card-title>Client Happiness Trend<span class="subtitle">{{subtitle}}</span></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="data" type="line" [data]="data" [options]="options" height="80%"></p-chart>
            </mat-card-content>
        </mat-card>
    </section>
    <section class="row" *ngIf="dataSource$">
        <mat-card class="half-card">
            <mat-card-header>
                <mat-card-title>Ratings by Classification<span class="subtitle">{{subtitle}}</span></mat-card-title>
                <div class="toggles">
                    <mat-button-toggle-group class="filter-button" value="number">
                        <mat-button-toggle value="percentage" (click)="setNumberType('percentage')"> %
                        </mat-button-toggle>
                        <mat-button-toggle value="number" (click)="setNumberType('number')">#</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </mat-card-header>
            <mat-card-content>
                <mat-table #table [dataSource]="classificationSource">
                    <ng-container matColumnDef="happiness">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-header-cell *matCellDef="let element">
                            <img *ngIf="element.label !== 'grade'" alt={{element.label}} src={{element.img}}/>
                        </mat-header-cell>
                    </ng-container>
                    <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                        <mat-header-cell style="text-align: center;" *matHeaderCellDef>{{column}}</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div *ngIf="element.label === 'grade'" class="happiness-circle"
                                 [ngClass]="getColorClassByRating(getData(element, i, column))">
                                <div class="text">{{getData(element, i, column)}}</div>
                            </div>
                            <div style="text-align: center;" *ngIf="element.label !== 'grade'">
                                {{getData(element, i, column)}}
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="totalColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: totalColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </section>
</div>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="third-card">
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
