<mat-card class="trigger-analytics-container">

    <div class="sticky-header">
                <mat-card-header>
                    <mat-card-title class="trigger-title">
                        Trigger performance
                    </mat-card-title>
                    <div class="toggles header-items">

                        <mat-form-field>
                            <input matInput placeholder="Filter trigger/ path" [formControl]="filterControl">
                        </mat-form-field>

                        <div>
                            <button (click)="expandAll()" mat-raised-button class="expand-button">
                                {{panelOpenState === false? "Expand all" : "Hide all"}}
                            </button>
                        </div>
                        <div class="toggles">
                            <mat-button-toggle-group value ="percentage" [(ngModel)]="toggleSetting">
                                <mat-button-toggle class="test" value="percentage" > % </mat-button-toggle>
                                <mat-button-toggle class="test" value="number"> # </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </mat-card-header>

                <div class="thead-container">

                    <p class="trigger-name">Trigger name</p>
                    <p>Visitor rating</p>
                    <p>Invites sent</p>
                    <p>% of total chats</p>
                    <p>Chats</p>
                    <p>Leads</p>
                    <p>Service</p>
                    <p>Wasted</p>
                    <p>No dialog</p>
                    <p>CR X/ invite</p>
                    <p class="avgTimeSec" >Avg. initiation time (sec)</p>

                </div>
    </div>

    <div class="expansion-panel">
        <mat-accordion *ngFor="let trigger of pageSlice">
<!--            <mat-expansion-panel hideToggle>-->
<!--            </mat-expansion-panel>-->
            <mat-expansion-panel class="mat-elevation-z" [expanded]="panelOpenState">
                <mat-expansion-panel-header>
                    <mat-panel-title>

                        <div class="trigger-header-container">
                            <p class="trigger-name">{{trigger.name}}</p>
                            <p>{{trigger.triggerStats.visitorRating}}</p>
                            <p>{{trigger.triggerStats.visits}}</p>
                            <p>{{trigger.triggerStats.percentageChats}}</p>
                            <p>{{trigger.triggerStats.chats}}</p>
                            <p>{{calculateValueTrigger(trigger, 'leads')}}</p>
                            <p>{{calculateValueTrigger(trigger, 'service')}}</p>
                            <p>{{calculateValueTrigger(trigger, 'wasted')}}</p>
                            <p>{{calculateValueTrigger(trigger, 'geenDialoog')}}</p>
                            <p>{{calculateValueTrigger(trigger, 'crxInvite')}}</p>
                            <p>{{trigger.triggerStats.avgTimeSec}}</p>
                        </div>

                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div  *ngFor="let trigger of trigger.pathTriggerStats" class="table-body">

                    <div class="path-trigger-stats">
                        <p class="trigger-path">{{trigger.path}}</p>
                        <p>{{trigger.triggerStats.visitorRating}}</p>
                        <p>{{trigger.triggerStats.visits}}</p>
                        <p>{{trigger.triggerStats.target}}</p>
                        <p>{{trigger.triggerStats.chats}}</p>
                        <p>{{calculateValuePath(trigger, 'leads')}}</p>
                        <p>{{calculateValuePath(trigger, 'service')}}</p>
                        <p>{{calculateValuePath(trigger, 'wasted')}}</p>
                        <p>{{calculateValuePath(trigger, 'geenDialoog')}}</p>
                        <p>{{calculateValuePath(trigger, 'crxInvite')}}</p>
                        <p>{{trigger.triggerStats.avgTimeSec}}</p>
                    </div>

                </div>
                <div class="container-wrapper">
                    <div class="trigger-info-container">

                        <div class="trigger-info">
                            <div class="info-list">
                                <h3>Trigger information:</h3>
                                <p>Launch message: <span>Heey buy this car, now!</span></p>
                                <p>Send message to visitor if: <span>All conditions are met</span></p>
                                <p>Send message after: <span>30</span> seconds</p>
                                <p>Message text: <span>Welcome at Jaguar! Which question do you have about the E-PACE?</span></p>
                            </div>
                        </div>

                        <div class="trigger-conditions">
                            <div class="info-list">
                                <h3>Trigger conditions:</h3>
                                <p>Visitor sent message: <span>is False</span></p>
                                <p>Visitor is/was served: <span>is False</span></p>
                                <p>Visitor received auto-msg: <span>is False</span></p>
                                <p>Web page URL? contains: <span>{{trigger.name}}</span></p>
                            </div>
                        </div>
                        <div class="trigger-visitor-amount">
                            <div class="info-list">
                                <p>Number of visits: <span>1</span></p>
                                <p>Web page URL? not contains: <span>configurator</span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="sticky-container">
        <mat-card class="mat-card-sticky">
            <div  *ngFor="let total of totals" class="totals-bar">
                <p class="trigger-name">TOTAL</p>
                <p>{{total.visitorRating}}</p>
                <p>{{total.visits}}</p>
                <p>11.8</p>
                <p>{{total.chats}}</p>
                <p>{{total.leads}}</p>
                <p>{{total.service}}</p>
                <p>{{total.wasted}}</p>
                <p> {{total.geenDialoog}}</p>
                <p>{{total.crxInvite}}</p>
                <p class="avgTimeSec" >{{total.avgTimeSec}}</p>
            </div>
        </mat-card>


        <mat-toolbar class="trigger-toolbar">
            <mat-toolbar-row>
                <span class="example-spacer"></span>
                <mat-paginator [length]="this.filteredTriggers.length"
                               [pageSize]="pageSize"
                               [pageSizeOptions]="pageSizeOptions"
                               (page)="onPageChange($event)"
                               aria-label="Select page">

                </mat-paginator>
            </mat-toolbar-row>
        </mat-toolbar>

    </div>

    <div class="clear"></div>
</mat-card>

