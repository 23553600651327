import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ClassificationFieldValue } from '../../../../../model/support/review/classification-field-value.model';
import { Correction } from '../../../../../model/support/review/corrections.model';
import { AuthorizationService } from "../../../../../core/authorization.service";

@Component({
  selector: 'app-classification-fields',
  templateUrl: './classification-fields.component.html',
})

export class ClassificationFieldsComponent implements OnInit {
  @Input()
  public correction: Correction;
  public form: FormGroup;
  @Output()
  public saveFieldValues = new EventEmitter();
  @Output()
  public validEvent = new EventEmitter<{valid: boolean}>();
  public checkboxTouched = false;

  constructor(
    private authorizationService: AuthorizationService
  ) { }

  private static getValidators(cf: ClassificationFieldValue): (control: AbstractControl) => ValidationErrors {
    if (cf.fieldDefinition.required || cf.fieldDefinition.requirementSetting === 'YES') {
      return Validators.required;
    }

    return null;
  }

  public ngOnInit(): void {
    const formGroup = {};
    this.correction.classificationFieldValueDto.forEach((cf) => {
      formGroup[cf.name] = new FormControl(cf.value, ClassificationFieldsComponent.getValidators(cf));
    });
    this.form = new FormGroup(formGroup);

    this.validEvent.emit({valid: this.form.valid});

    this.form.valueChanges.subscribe(() => {
      this.validEvent.emit({valid: this.form.valid});
    });
  }

  public setClassificationFieldValues(): void {
    this.correction.classificationFieldValueDto.forEach((cf) => {
      cf.value = this.form.controls[cf.name].value;
    });
  }

  public requiredFieldPresent(fieldName: string): boolean {
    const value = this.form.controls[fieldName].value;
    return value !== null && value.length > 0;
  }

  public requiredBoxChecked(fieldName: string): boolean {
    const value = this.form.controls[fieldName].value;
    return value !== null && (value === 'true' || value);
  }

  public save(): void {
    this.setClassificationFieldValues();
    this.saveFieldValues.emit();
  }

  public clear(): void {
    this.form.reset();
    this.checkboxTouched = false;
  }

  public getClassificationFieldLanguageByUserLanguage(classificationFieldValue: ClassificationFieldValue): string {
    const currentUser = this.authorizationService.currentUser;
    const labelText = classificationFieldValue.fieldDefinition?.linkedField?.localizedLabels
      .find(label => label.language.languageCode === currentUser.primaryLanguage.languageCode)?.text;

    return labelText ? labelText : classificationFieldValue.name;
  }
}
