import {Injectable, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreParameters } from '../../model/core-parameters.model';
import { GraphFilterService } from '../graph-filter.service';

@Injectable()
export abstract class CoreParametersConsumer implements OnInit, OnDestroy {
  private coreParametersSubscription: Subscription;

  protected constructor(protected graphFilterService: GraphFilterService) {
  }

  ngOnInit(): void {
    this.coreParametersSubscription = this.graphFilterService.coreParameters$.subscribe(
      (coreParameters: CoreParameters) => {
        this.loadData(coreParameters);
      },
    );
  }

  ngOnDestroy(): void {
    this.coreParametersSubscription.unsubscribe();
  }

  abstract loadData(coreParameters: CoreParameters): void;
}
