<div class="row" *ngIf="_chatClassification">
    <mat-form-field [style.width.%]="50">
        <mat-select [(ngModel)]="_chatClassification.category" (selectionChange)="changeCategory($event.value)">
            <mat-option *ngFor="let c of categories" [value]="c['category']">
                <span>{{c['category']}}</span>
                <span *ngIf="c['category'] === oldCategory"> *</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field [style.width.%]="50">
        <mat-select [(ngModel)]="_chatClassification.department">
            <mat-option [value]="null">Geen department</mat-option>
            <mat-option *ngFor="let d of availableDepartments" [value]=d>
                <span>{{d}}</span>
                <span *ngIf="d === oldDepartment"> *</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div [formGroup]="form" *ngIf="activeCategory && form">
    <div *ngFor="let input of activeCategory.classificationInput"
         [ngSwitch]="input.type.toUpperCase()">
        <mat-form-field style="width: 100%" *ngSwitchCase="'SELECT'" [ngClass]="input.required && !requiredFieldPresent(input.name) ? 'field-required' : ''">
            <mat-select formControlName="{{input.name}}" value=""
                        placeholder="{{input.name }}" required="{{input.required}}">
                <mat-option *ngFor="let option of input.options"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
            <mat-error *ngIf="input.required">{{input.name}} is required</mat-error>
        </mat-form-field>

        <!--        legacy DROPDOWN case is identical to SELECT case-->
        <mat-form-field style="width: 100%" *ngSwitchCase="'DROPDOWN'" [ngClass]="input.required && !requiredFieldPresent(input.name) ? 'field-required' : ''">
            <mat-select formControlName="{{input.name}}" value=""
                        placeholder="{{input.name }}" required="{{input.required}}">
                <mat-option *ngFor="let option of input.options"
                            [value]="option">{{option}}</mat-option>
            </mat-select>
            <mat-error *ngIf="input.required">{{input.name}} is required</mat-error>
        </mat-form-field>

        <div style="width: 100%; padding-bottom: 1em" *ngSwitchCase="'CHECKBOX'" [ngClass]="input.required ? 'field-required' : ''">
            <mat-checkbox
                formControlName="{{input.name}}">{{input.name}}<span *ngIf="input.required"> *</span>
            </mat-checkbox>
        </div>
        <mat-form-field style="width: 100%" *ngSwitchDefault [ngClass]="input.required && !requiredFieldPresent(input.name) ? 'field-required' : ''">
            <input matInput formControlName="{{ input.name }}" placeholder="{{getClassificationNameBasedOnUserLanguage(input) + (input.required ? ' *' : '')}} "> <!-- required attribute causes validation bugs -->
            <mat-error *ngIf="input.required">{{ getClassificationNameBasedOnUserLanguage(input) }} is required</mat-error>
        </mat-form-field>
    </div>
</div>
<div style="display: flex; justify-content: center">
    <app-small-button (onClick)="save()"
                      [color]="'white'"
                      [bold]="true"
                      [disabled]="!enabled"
                      [label]="'Save'"></app-small-button>
</div>
