<div *ngIf="reviewService.review?.conversationUid === conversationUid">
    <app-message-send [historyOnly]="historyOnly"
                      [disabled]="disabled"
                      (sendMessage)="sendReviewMessage($event)"
                      [placeholder]="placeholder"
                      [type]="type"
                      [conversationUid]="conversationUid"
                      [staticMessages]="staticMessages"
                      [reviewMessages]="reviewMessages"
                      [review]="reviewService.review">
    </app-message-send>
</div>
