<div *ngIf="review">
    <mat-expansion-panel style="box-shadow: unset !important" hideToggle>
        <mat-expansion-panel-header collapsedHeight="15px" expandedHeight="15px">
            <mat-panel-description>
                <i class="fas fa-ellipsis-h" style="margin: auto;"></i>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <mat-list>
                <mat-list-item><span>LCD: <strong>{{lcdString()}}</strong></span></mat-list-item>
                <mat-list-item><span>Team lead: <strong>{{review.supervisorSet?.teamLead?.screenName}}</strong></span>
                </mat-list-item>
                <mat-list-item><span>Feedback participants: <strong>{{participantString()}}</strong></span>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-expansion-panel>
    <mat-tab-group mat-align-tabs="start"
                   [dynamicHeight]="true"
                   [selectedIndex]="tabIndex"
                   [disableRipple]="true"
                   [animationDuration]="'0'"
                   #tabGroup>
        <mat-tab [label]="'FEEDBACK'">
            <ng-template mat-tab-label>
                Feedback
            </ng-template>
            <app-feedback-bconnect-rating
                *ngIf="conversationUid"
                [conversationUid]="conversationUid"
                [openOnTab]="openOnTab"
                [review]="review"
                [tabsWithUpdate]="tabsWithUpdate"
                [pendingActions]="pendingActions"
                [operatorErrorOptions]="operatorErrors | async"
                (markAsSeenEvent)="markAsSeen(conversationUid, $event)"
                (updateActionsEvent)="updateActionsEvent.emit($event)"
                (reviewMessageEvent)="emitReviewMessageEvent($event)"
                (bconnectRatingEvent)="sessionInfo.bconnectRating = $event; bconnectRatingEvent.emit($event)"
            >
            </app-feedback-bconnect-rating>
        </mat-tab>
        <mat-tab [label]="'FAQ'">
            <ng-template mat-tab-label>
                Add FAQ
            </ng-template>
            <app-feedback-faqs *ngIf="review"
                               [review]="review"></app-feedback-faqs>
            <app-small-button (onClick)="markAsSeen(conversationUid, 'FAQ')"
                              *ngIf="hasUpdate('FAQ')"
                              [color]="'white'"
                              [bold]="true"
                              [label]="'Mark as seen'">
            </app-small-button>
        </mat-tab>
        <mat-tab [label]="'CORRECTION'" *ngIf="isTeamLeadOrBetter || hasUpdate('CORRECTION')">
            <ng-template mat-tab-label>
                Correction
            </ng-template>

            <app-feedback-correction [review]="review"></app-feedback-correction>
            <div style="display: flex; justify-content: space-around">
                <app-small-button (onClick)="markAsSeen(conversationUid, 'CORRECTION')"
                                  *ngIf="hasUpdate('CORRECTION')"
                                  [bold]="true"
                                  [color]="'white'"
                                  [label]="'Mark as seen'">
                </app-small-button>
            </div>
        </mat-tab>
        <mat-tab [label]="'ESCALATE'" *ngIf="isTeamLeadOrBetter" [disabled]="isAdmin && !review.escalationProcess">
            <ng-template mat-tab-label>
                Escalate
            </ng-template>
            <div class="flex-center p1em">
                <app-small-button *ngIf="review.escalationProcess && isEscalationInitByCurrentUser()"
                                  [color]="'white'" [label]="'Close escalation'"
                                  (onClick)="closeEscalation()"></app-small-button>
            </div>
            <app-messaging [conversationUid]="conversationUid"
                           [type]="'ESCALATE'"
                           (emitMessage)="sendEscalateMessage($event)"
                           [reviewMessages]="escalationMessages">
            </app-messaging>
            <div style="display: flex; justify-content: space-around">
                <app-small-button (onClick)="markAsSeen(conversationUid, 'ESCALATE')"
                                  *ngIf="hasUpdate('ESCALATE')"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Mark as seen'">
                </app-small-button>
            </div>
        </mat-tab>
        <mat-tab [label]="'OPTIMIZATION'">
            <ng-template mat-tab-label>
                Optimize
            </ng-template>
            <div class="flex" *ngIf="hasOptimizePermission()">
                <div class="w50 claim-text pr1em" [style.text-align]="'center'">
                    <span>Assigned to {{exclusiveClaimant('OPTIMIZATION') || 'nobody'}}</span>
                </div>

                <mat-form-field class="pl1em w50 mat-form-no-padding-bottom" *ngIf="optimizationAgents.length > 0"
                                appearance="outline">
                    <mat-label>Assign to</mat-label>
                    <mat-select (selectionChange)="assignActionTo('OPTIMIZATION', $event.value)">
                        <mat-option [value]="authorizationService.currentUser.id"
                                    [disabled]="hasExclusiveClaim('OPTIMIZATION')">
                            <strong>Me</strong>
                        </mat-option>
                        <mat-option
                            *ngFor="let a of optimizationAgents | genericFunctionFilter: {filterFns: [agentFilterFn], sortFn: agentSortFn}"
                            [value]="a.id">
                            {{a.screenName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <app-messaging [conversationUid]="conversationUid"
                           [type]="'OPTIMIZATION'"
                           (emitMessage)="sendOptimizationMessage($event)"
                           [reviewMessages]="optimizationMessages">
            </app-messaging>
            <div style="display: flex; justify-content: space-around">
                <app-small-button *ngIf="(!processIsClaimed('OPTIMIZATION') || hasExclusiveClaim('OPTIMIZATION'))
                                            && hasPendingActions('OPTIMIZATION')"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Resolve'"
                                  (onClick)="resolve('OPTIMIZATION')"></app-small-button>
            </div>
        </mat-tab>
        <mat-tab [label]="'CLIENTCOMMENT'" *ngIf="hasClientFeedback">
            <ng-template mat-tab-label>
                Client
            </ng-template>
            <div align="center">
                <div align="center"><label>Rating</label></div>
                <div align="center">
                    <button mat-button disabled>
                        <i class="far fa-laugh-beam" [ngStyle]="{'color': happinessColor(10)}"
                           style="font-size: 28px;"></i>
                    </button>
                    <button mat-button disabled>
                        <i class="far fa-meh" [ngStyle]="{'color': happinessColor(6)}" style="font-size: 28px;"></i>
                    </button>
                    <button mat-button disabled>
                        <i class="far fa-frown" [ngStyle]="{'color': happinessColor(2)}" style="font-size: 28px;"></i>
                    </button>
                </div>
            </div>
            <app-messaging [conversationUid]="conversationUid"
                           [staticMessages]="staticMessages"
                           [historyOnly]="!isLCDOrBetter"
                           [type]="'CLIENTCOMMENT'"
                           [disabled]="!isLCDOrBetter"
                           (emitMessage)="sendMessageToClient($event)"
                           [reviewMessages]="clientMessages">
            </app-messaging>

            <div class="flex-center">
                <app-small-button (onClick)="resolveClientFeedback()"
                                  *ngIf="hasPendingActions('CLIENTCOMMENT')"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Resolve'"></app-small-button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
