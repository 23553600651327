import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{
  constructor(private titleService: Title){
    Chart.defaults.datasets.line.pointHitRadius = 6;

  }

  ngOnInit(): void {
    this.titleService.setTitle('Support');
  }
}
