<div *ngIf="review.topChat" class="marked-alert">
    {{infoMessage}}
</div>
<div class="center" style="margin-top: 10px">
    <div *ngIf="isTeamLead && !isReviewForCurrentUser()">
        <div class="flex-center p1em">
            <app-small-button *ngIf="!review.topChat || review.topChat?.topChatRequestedBy === null || review.topChat?.processStage === 'SUBMITTED_BY_OPER'"
                              [color]="'white'"
                              [label]="'Submit top chat request'"
                              (onClick)="submitTopChatRequest()">
            </app-small-button>
            <app-small-button *ngIf="review.topChat?.processStage == 'SUBMITTED_BY_OPER'"
                              [color]="'white'"
                              [label]="'Decline request'"
                              (onClick)="markTopChat(false)">
            </app-small-button>
        </div>
        <app-simple-messaging
            [messages]="review.topChat?.comments"
            (addMessage)="addComment($event)"
            [disabled]="review.topChat?.processStage === 'ACCEPTED' || review.topChat?.processStage === 'REJECTED'"
            [placeholder]="'Leave a message'">
        </app-simple-messaging>
    </div>

    <div *ngIf = "isLCDOrBetter && !isReviewForCurrentUser()">
        <div style="display: flex; justify-content: center" *ngIf="isLCDOrBetter">
            <div style="padding-top: 10px">
                <app-small-button (onClick)="markTopChat(true)"
                                  *ngIf="!(review.topChat?.processStage === 'ACCEPTED' || review.topChat?.processStage === 'REJECTED')"
                                  [color]="'white'"
                                  [label]="'Mark top chat'">
                </app-small-button>
            </div>
            <div style="padding-top: 10px">
                <app-small-button (onClick)="markTopChat(false)"
                                  *ngIf="review.topChat?.processStage == 'UNDER_REVIEW'"
                                  [color]="'white'"
                                  [label]="'Decline request'">
                </app-small-button>
            </div>
        </div>
        <div style="padding-top: 10px" *ngIf="review.topChat?.processStage == 'ACCEPTED'">
            <app-small-button (onClick)="unmarkAsTopChat()"
                              [color]="'white'"
                              [label]="'Unmark as top chat'">
            </app-small-button>
        </div>
        <app-simple-messaging
            [messages]="review.topChat?.comments"
            (addMessage)="addComment($event)"
            [disabled]="false"
            [placeholder]="'Leave a comment'">
        </app-simple-messaging>
    </div>
    <div *ngIf="isReviewForCurrentUser()">
        <app-simple-messaging
            [messages]="review.topChat?.comments"
            (addMessage)="addComment($event)"
            [disabled]="!!review.topChat"
            [buttonText]="'Apply for top chat'"
            [placeholder]="'Leave a comment'">
        </app-simple-messaging>
    </div>
</div>
