<div class="added-faq-dialog" style="min-width: 70vw;">
    <h3>
        FAQ additions for conversation {{conversationUid}}
    </h3>

    <mat-list>
        <ng-container *ngFor="let f of faqs | async | genericFunctionFilter: {filterFns: [], sortFn: sortFn}">

            <div class="flex-start" style="padding-bottom: 6px;">
                <div class="status-label" style="margin: 2px 0;"
                     [style.background-color]="f.faqAdditionProcessStage === 'ACCEPTED' ? 'green' : 'grey'">{{toStageLabel(f)}}
                    at
                </div>
                <div
                    style="font-size: 13px; line-height: 2.1; padding-left: 3px;">{{f.closedAt | date : 'medium'}}</div>
            </div>

            <div *ngIf="f.qnas?.length > 0" style="min-height: 48px;
            border: 1px solid lightgrey;
            display: flex;
            flex-direction: column;
            padding: 0 12px;
            border-radius: 2px;" [style.margin-bottom]="!f.expanded ? '1em' : '0'">

                <div class="flex w100" style="align-items: center; margin: auto;">
                    <div style="width: 50px;" [style.padding-right.px]="15">
                        <span [ngClass]="flagService.getFlagClass(f.qnas[0].languageCode)"></span>
                    </div>

                    <div class="w90 flex" [style.flex-direction]="f.expanded ? 'column' : 'row'">

                        <div class="flex" [style.width]="f.expanded ? '100%' : '50%'"
                             [style.padding]="f.expanded ? '12px' : '0'">
                            <div class="bold" style="width: 2em">Q:</div>
                            <div style="width: calc(100% - 2em)">
                                {{ getQuestion(f, 0) }}
                            </div>
                        </div>

                        <div class="flex" [style.width]="f.expanded ? '100%' : '50%'"
                             [style.padding]="f.expanded ? '12px' : '0'">
                            <div class="bold" style="width: 2em">A:</div>
                            <div style="width: calc(100% - 2em)">
                                {{ getAnswer(f, 0) }}</div>
                        </div>

                    </div>

                    <div *ngIf="!f.expanded" class="bold pointer" style="color: grey; width: 4em; font-size: 13px;"
                         (click)="f.expanded = true">
                        Expand
                    </div>
                </div>

                <div *ngIf="f.expanded && f.qnas.length > 1">

                    <div *ngFor="let qna of f.qnas | slice:1;">

                        <mat-divider></mat-divider>

                        <div style="align-items: center;" class="flex w100 margin-auto">

                            <div style="width: 50px;" [style.padding-right.px]="15">
                                <span [ngClass]="flagService.getFlagClass(qna.languageCode)"></span>
                            </div>
                            <div class="w90 flex-column">

                                <div class="flex w100" style="padding: 12px;">
                                    <div class="bold" style="width: 2em">Q:</div>
                                    <div style="width: calc(100% - 2em)">
                                        {{ qna.question }}
                                    </div>
                                </div>

                                <div class="flex w100" style="padding: 12px;">
                                    <div class="bold" style="width: 2em">A:</div>
                                    <div style="width: calc(100% - 2em)">
                                        {{ qna.answer }}</div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>

            </div>


            <div class="p1em" style="border: 1px solid lightgrey; border-top: 0;" *ngIf="f.expanded">
                <app-simple-messaging-history [messages]="f.comments"
                                              [scrollable]="false"></app-simple-messaging-history>
            </div>

        </ng-container>
    </mat-list>

    <div class="buttons">

        <app-small-button (onClick)="markAsSeen()"
                          [backgroundColor]="'#000066'"
                          [color]="'white'"
                          [label]="'Mark as seen'"></app-small-button>

    </div>
</div>
