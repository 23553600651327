<section class="row" *ngIf="hasDatasource">
    <app-score class="third-card left" title= 'Total Happiness' [score]="averageScores?.totalScore"
               [colorClass]="getColorClass(averageScores?.totalScore)" [noOfCards]=3>
    </app-score>
    <app-score class="third-card left" title= 'Client Happiness' [score]="averageScores?.clientScore"
               [colorClass]="getColorClass(averageScores?.clientScore)" [noOfCards]=3>
    </app-score>
    <app-score class="third-card left" title= 'Visitor Happiness' [score]="averageScores?.visitorScore"
               [colorClass]="getColorClass(averageScores?.visitorScore)" [noOfCards]=3>
    </app-score>
    <app-score id="chatPerHour-text" class="third-card left" title= 'Chat per Hour' [score]="averageScores?.chatsHour"
              [noOfCards]=3 >
    </app-score>
    <mat-card class="third-card left">
        <mat-card-header>
            <mat-card-title>
                € per Chat
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
            {{averageScores?.euroPerChat | currency:'EUR'}}
        </mat-card-content>
    </mat-card>
    <mat-card class="third-card left">
        <mat-card-header>
            <mat-card-title>
                € Total
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
            {{averageScores?.euroTotal | currency:'EUR'}}
        </mat-card-content>
    </mat-card>
</section>

<section class="row">
    <mat-card class="full-card limited-larger" *ngIf="hasDatasource" >
        <mat-card-header >
            <div class="toggles">
                <mat-button-toggle-group #group="matButtonToggleGroup" value="{{ currentGraphSelector }}">
                    <mat-button-toggle value="categoryGraphs" [matMenuTriggerFor]="menuCategory">
                        {{'Chats per total hours online all operators: '+selectedDistributionGraph +'▼'}}
                    </mat-button-toggle>
                    <mat-button-toggle value="happinessGraphs" [matMenuTriggerFor]="menuHappiness">
                        {{'Happiness: '+selectedHappinessGraph +'▼'}}
                    </mat-button-toggle>
                    <mat-button-toggle value="euroGraphs" [matMenuTriggerFor]="menuEuro">
                        {{'€ per: '+selectedEuroGraph +'▼'}}
                    </mat-button-toggle>
                    <mat-menu #menuEuro="matMenu">
                        <button mat-menu-item (click)="setGraphType('euro per chat')">Chat</button>
                        <button mat-menu-item (click)="setGraphType('euro per hour')">Hour</button>
                        <button mat-menu-item (click)="setGraphType('euro')">{{period}}</button>
                    </mat-menu>
                    <mat-menu #menuHappiness="matMenu">
                        <button mat-menu-item (click)="setGraphType('TotalHappiness')">Total</button>
                        <button mat-menu-item (click)="setGraphType('Visitors')">Visitors</button>
                        <button mat-menu-item (click)="setGraphType('Clients')">Clients</button>
                    </mat-menu>
                    <mat-menu #menuCategory="matMenu">
                        <button mat-menu-item (click)="setGraphType('Total')">Total</button>
                        <button mat-menu-item (click)="setGraphType('Leads')">Leads</button>
                        <button mat-menu-item (click)="setGraphType('Service')">Service</button>
                        <button mat-menu-item (click)="setGraphType('Wasted')">Wasted</button>
                        <button mat-menu-item (click)="setGraphType('Geen Dialoog')">Geen Dialoog</button>
                        <button mat-menu-item (click)="setGraphType('Missed')">Missed</button>
                    </mat-menu>
                </mat-button-toggle-group>
            </div>
            <mat-card-title>
                CEO Home<span class="subtitle">{{subtitle}}</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content  >
            <p-chart type="line" [data]="dataSource" [options]="options" height="85%"></p-chart>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="hasDatasource" >
    <mat-card class="third-card-error">
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
