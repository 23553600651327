<section class="row">
    <mat-card class="full-card">
        <mat-card-header>
            <mat-card-title>
                FAQ's requiring your review
            </mat-card-title>
            <mat-card-subtitle>
                <span *ngIf="hasFaqs">Select 'Answer' to view and evaluate the question and (optionally suggested) answers.</span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="hasFaqs">
            <mat-table class="archive-table" [dataSource]="tableDataSource" *ngIf="hasFaqs">
                <ng-container matColumnDef="question">
                    <mat-header-cell style="min-width:75%" *matHeaderCellDef>Question</mat-header-cell>
                    <mat-cell style="min-width:75%" *matCellDef="let element">
                        <div class="flex-column">
                            <div class="pt1em pb1em" *ngFor="let qna of element.qnas | genericFunctionFilter: {filterFns: [questionFilterFn]}">
                                <span [ngClass]="flagService.getFlagClass(qna.languageCode)"></span> {{qna.question}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationUid">
                    <mat-header-cell *matHeaderCellDef>Related chat</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button pointer"
                                style="color: #141464; font-weight: bold;"
                                (click)="showSessionDetails(element.conversationUid)">
                            {{element.conversationUid}}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="answer">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button *ngIf="inToClientStage(element)" mat-stroked-button class="pointer"
                                style="color: #141464; font-weight: bold;"
                                (click)="answer(element)">
                            Answer
                        </button>
                        <span
                            *ngIf="clientHasResponded(element)"
                            [style.color]="'grey'">
                            Answered
                        </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
        <mat-card-content *ngIf="!hasFaqs">
            There are currently no FAQ's requiring your review.
        </mat-card-content>
    </mat-card>
</section>
