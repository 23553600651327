<section class="row"  *ngIf = "!conversationUid && !isData" >
    <app-no-data [title]="titleText"></app-no-data>
</section>

<section class="row" [hidden] = "conversationUid || !isData" >
    <mat-card class="full-card">
        <div style="float: right" class="toggles">
            <mat-button-toggle-group name="grouping" value="false" *ngIf="!isOperator">
                <mat-button-toggle value="true" (click)="toggleData()">Grouped rows</mat-button-toggle>
                <mat-button-toggle value="false" (click)="toggleData()">Single rows</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <mat-card-header>
            <mat-card-title>
                {{titleText}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content >
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
            <mat-table #table class="archive-table" [dataSource]="tableDataSource" matSort [matSortActive]="conversationUid"
                       [matSortDirection]="'desc'" [matSortDisableClear]="true" >
                <ng-container matColumnDef="sessionDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.sessionDate | date}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationUid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button pointer"  style="color: #141464; font-weight: bold;"
                                (click)="setConversationUid(element)">
                            {{element.conversationUid}}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.visitorRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.visitorRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.clientRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.clientRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="bconnectRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Bconnect Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.bconnectRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.bconnectRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="openReviewActions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Open Actions</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.openReviewActionList}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actionDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Action date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.actionDate | date :'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                <ng-container  matColumnDef="triggeredBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Triggered by</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{triggeredByText(element.triggeredBy)}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [length]=tableDataLength
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </mat-card-content>
    </mat-card>

</section>
<section class="row" *ngIf="conversationUid">
    <div class="two-third-card">
        <app-session-details [conversationUid]="conversationUid"
                             (unloadConversationDetailsEvent)="unsetConversationUid()"
                             (loadedSession)="setLoadedSession($event)"
                             (getNextEvent)="getNextReview($event)">
        </app-session-details>
    </div>
    <mat-card id="review-card" class="third-card" style="padding-top: 15px !important;"
              *ngIf="conversationUid === reviewService.review?.conversationUid">
        <div *ngIf="isClient">
            <!-- limited view for clients -->
            <mat-tab-group mat-align-tabs="start" [dynamicHeight]="true" [disableRipple] = "true" >
                <mat-tab>
                    <ng-template mat-tab-label>
                        Client
                    </ng-template>
                    <app-feedback-client-feedback
                        [review]="getReview()"
                        [sessionChatInfo]="sessionChatInfo">
                    </app-feedback-client-feedback>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Add FAQ
                    </ng-template>
                    <app-feedback-faqs *ngIf="getReview()" [review]="getReview()"></app-feedback-faqs>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- view for non-clients -->
        <app-feedback-details *ngIf="!isClient"
                              [openOnTab]="type"
                              [conversationUid]="conversationUid"
                              [review]="getReview()"
                              (updateActionsEvent)="updateActionsInTable($event)">
        </app-feedback-details>
    </mat-card>
</section>
