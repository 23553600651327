<section class="row" [hidden]="!operatorDataSource || errorMessage" >
    <mat-card class="full-card" >
        <mat-card-header>
            <mat-card-title>Operator leaderboard<span class="subtitle">{{teamFilter}}</span></mat-card-title>
            <div class="toggles">
                <mat-button-toggle-group class="filter-button" value = "percentage">
                    <mat-button-toggle value="percentage" (click)="setNumberType('percentage')"> % </mat-button-toggle>
                    <mat-button-toggle value="number" (click)="setNumberType('number')">#</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-card-header>
        <mat-card-content>
            <h3 *ngIf="teamDataSource">Teams</h3>
                    <mat-table *ngIf="teamDataSource" [dataSource]="teamDataSource" #teamSort="matSort" matSort matSortActive="name" matSortDirection="desc"
                               matSortDisableClear="true" >
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Operator</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="combinedRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.combinedRating ? (element.combinedRating | number:
                                '1.2-2') :'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="sessions">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.sessions || 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="visitorRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                                '1.2-2') : 'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="clientRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Client Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                                '1.2-2') :'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                            <mat-cell *matCellDef="let element">  {{ getData(column, element)}}  </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="duration">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average duration (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.duration || 'n/a')}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="averageResponseTime">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average reaction time (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.averageResponseTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="averagePickupTime">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average pickup time (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.averagePickupTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="chatsPerHour">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Chats per hour</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.chatsPerHour ? (element.chatsPerHour | number: '1.1-1') : 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="euroPerHour">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>€ per hour</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.euroPerHour ? (element.euroPerHour | number: '1.2-2') : 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="workLoad">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Work load</mat-header-cell>
                            <mat-cell *matCellDef="let element"><span [ngStyle]="{'color': getWorkLoadColor(element.workLoad)}">{{element.workLoad != null ? (element.workLoad | number: '1.1-2') + '%' : 'n/a'}}</span></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
            <h3 *ngIf="teamLeadDataSource && teamLeadDataSource.data.length > 0">Team Leads</h3>
            <mat-table *ngIf="teamLeadDataSource && teamLeadDataSource.data.length > 0" [dataSource]="teamLeadDataSource" #teamleadSort="matSort" matSort matSortActive="name" matSortDirection="desc"
                               matSortDisableClear="true" >
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Operator</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="combinedRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.combinedRating ? (element.combinedRating | number:
                                '1.2-2') :'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="sessions">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.sessions || 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="visitorRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                                '1.2-2') : 'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="clientRating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Client Score</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                                '1.2-2') :'n/a')}}
                            </mat-cell>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                            <mat-cell *matCellDef="let element">  {{ getData(column, element)}}  </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="duration">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average duration (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{(element.duration || 'n/a')}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="averageResponseTime">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average reaction time (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.averageResponseTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="averagePickupTime">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Average pickup time (s)</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.averagePickupTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="chatsPerHour">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Chats per hour</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.chatsPerHour ? (element.chatsPerHour | number: '1.1-1') : 'n/a'}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="euroPerHour">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>€ per hour</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.euroPerHour ? (element.euroPerHour | number: '1.2-2') : 'n/a'}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="workLoad">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Work load</mat-header-cell>
                            <mat-cell *matCellDef="let element"><span [ngStyle]="{'color': getWorkLoadColor(element.workLoad)}">{{element.workLoad != null ? (element.workLoad | number: '1.1-2') + '%' : 'n/a'}}</span></mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <h3 *ngIf="(isLCD() || isAdmin())  && lcdDataSource">Live Chat Director</h3>
            <mat-table *ngIf="lcdDataSource" [dataSource]="lcdDataSource" #lcdsort="matSort" matSort matSortActive="name" matSortDirection="desc"
                       matSortDisableClear="true" >
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Operator</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="combinedRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Total Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.combinedRating ? (element.combinedRating | number:
                        '1.2-2') :'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sessions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.sessions || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                        '1.2-2') : 'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                        '1.2-2') :'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">  {{ getData(column, element)}}  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="duration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Average duration (s)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.duration || 'n/a')}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="averageResponseTime">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Average reaction time (s)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.averageResponseTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="averagePickupTime">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Average pickup time (s)</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.averagePickupTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="chatsPerHour">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chats per hour</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.chatsPerHour ? (element.chatsPerHour | number: '1.1-1') : 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="euroPerHour">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>€ per hour</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.euroPerHour ? (element.euroPerHour | number: '1.2-2') : 'n/a'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="workLoad">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Work load</mat-header-cell>
                    <mat-cell *matCellDef="let element"><span [ngStyle]="{'color': getWorkLoadColor(element.workLoad)}">
                        {{element.workLoad != null ? (element.workLoad | number: '1.1-2' ) + '%' : 'n/a'}}</span></mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <h3 *ngIf="operatorDataSource && operatorDataSource.data.length > 0">Operators</h3>

<!--            <div *ngFor="let column of dynamicColumnsWithExpanded">-->
<!--                <app-category-table-->
<!--                    *ngIf="column.expanded"-->
<!--                    [categoryColumns]="categoryColumns"-->
<!--                    [parentColumn]="column"-->
<!--                    [numberView$]="numberView$"-->
<!--                    [operatorDataSource]="operatorDataSource.data"-->
<!--                    [left]="element.getBoundingClientRect().left - 200"-->
<!--                ></app-category-table>-->
<!--            </div>-->

            <div class="table-container">
                <mat-table class="custom-table" *ngIf="operatorDataSource && operatorDataSource.data.length > 0"
                           style="width: inherit"
                           [dataSource]="operatorDataSource"
                           #operatorSort="matSort"
                           matSort matSortActive="name" matSortDirection="desc" matSortDisableClear="true">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Operator</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="combinedRating">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Total Score</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.combinedRating ? (element.combinedRating | number:
                            '1.2-2') :'n/a')}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="visitorRating">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Score</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                            '1.2-2') : 'n/a')}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="clientRating">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Client Score</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                            '1.2-2') :'n/a')}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="sessions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.sessions || 'n/a'}}</mat-cell>
                    </ng-container>

                    <ng-container [matColumnDef]="column.name" *ngFor="let column of dynamicColumnsWithExpanded">
                        <mat-header-cell *matHeaderCellDef #matHeaderCell
                                         [ngStyle]="{'background-color': categoryCountsByCategoryTypeColumnNames.includes(column.name) ? '#f3f3f3' : 'inherit'}"
                        >
                            <button class="fa fa-arrow-alt-circle-right"
                                    style="border: 0; background-color: white; cursor: pointer;"
                                    *ngIf="onlyOneAccountActive && (column.name === 'Lead' || column.name === 'Service')"
                                    (click)="addOrRemoveColumnToDynamicColumns(column)"
                            ></button>
                            <span mat-sort-header>{{ column.name.length > 10 ? (column.name | slice:0:9) + '...' : column.name }}</span>
                        </mat-header-cell>

                        <mat-cell *matCellDef="let element"
                                  [ngStyle]="{'background-color': categoryCountsByCategoryTypeColumnNames.includes(column.name) ? '#f3f3f3' : 'inherit'}">
                            {{ getDataForSubCategoryOrCategory(column.name, element) }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="duration">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Average duration (s)</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.duration || 'n/a')}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="averageResponseTime">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Average reaction time (s)</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.averageResponseTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="averagePickupTime">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Average pickup time (s)</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.averagePickupTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="chatsPerHour">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Chats per hour</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.chatsPerHour ? (element.chatsPerHour | number: '1.1-1') : 'n/a'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="euroPerHour">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>€ per hour</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.euroPerHour ? (element.euroPerHour | number: '1.2-2') : 'n/a'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="workLoad">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Work load</mat-header-cell>
                        <mat-cell *matCellDef="let element"><span [ngStyle]="{'color': getWorkLoadColor(element.workLoad)}">{{element.workLoad != null ? (element.workLoad | number: '1.1-2') + '%' : 'n/a'}}</span></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="operatorDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: operatorDisplayedColumns;"></mat-row>
                </mat-table>
            </div>

            <div style="position: sticky; bottom: 0px; background-color: white; z-index: 2;">
            <h3>Organization</h3>
            <mat-table  [dataSource]="organizationDataSource">
                <ng-container matColumnDef="name">
                    <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="combinedRating">
                    <mat-cell *matCellDef="let element">{{(element.combinedRating ? (element.combinedRating | number:
                        '1.2-2') :'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sessions">
                    <mat-cell *matCellDef="let element">{{element.sessions || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                        '1.2-2') : 'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                        '1.2-2') :'n/a')}}
                    </mat-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                    <mat-cell *matCellDef="let element">  {{ getData(column, element)}}  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="duration">
                    <mat-cell *matCellDef="let element">{{(element.duration || 'n/a')}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="averageResponseTime">
                    <mat-cell *matCellDef="let element">{{element.averageResponseTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="averagePickupTime">
                    <mat-cell *matCellDef="let element">{{element.averagePickupTime | number: '1.2-2' || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="chatsPerHour">
                    <mat-cell *matCellDef="let element">{{element.chatsPerHour ? (element.chatsPerHour | number: '1.1-1') : 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="euroPerHour">
                    <mat-cell *matCellDef="let element">{{element.euroPerHour ? (element.euroPerHour | number: '1.2-2') : 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="workLoad">
                    <mat-cell *matCellDef="let element"><span [ngStyle]="{'color': getWorkLoadColor(element.workLoad)}">{{element.workLoad != null ? (element.workLoad | number: '1.2-2') + '%' : 'n/a'}}</span></mat-cell>
                </ng-container>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="full-card limited-larger" *ngIf="barSource">
        <mat-card-header>
            <div class="toggles">
                <mat-button-toggle-group #group="matButtonToggleGroup" value="Leads">
                    <mat-button-toggle value="Leads" (click)="setGraphType('leads')">
                        Leads
                    </mat-button-toggle>
                    <mat-button-toggle value="Combined rating" (click)="setGraphType('rating')">
                        Rating
                    </mat-button-toggle>
                    <mat-button-toggle value="Average response time" (click)="setGraphType('time')">
                        Time
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <mat-card-title>
                <span class="subtitle">{{subtitle}}</span>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p-chart *ngIf="barSource" type="bar" [data]="barSource" [options]="barOptions" height="86%"></p-chart>
        </mat-card-content>
    </mat-card>

    <mat-card class="half-card" [hidden]="!radarSource" >
        <mat-card-header>
            <mat-card-title>Operator performance radar </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p-chart type="radar" [data]="radarSource" [options]="options"></p-chart>
        </mat-card-content>
    </mat-card>

</section>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>

