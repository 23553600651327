<div align="center">
    <div align="center"><label>Rating</label></div>
    <div align="center">
        <button mat-button (click)="setHappiness(10)">
            <i class="far fa-laugh-beam" [ngStyle]="{'color': happinessColor(10)}"
               style="font-size: 28px;"></i>
        </button>
        <button mat-button (click)="setHappiness(6)">
            <i class="far fa-meh" [ngStyle]="{'color': happinessColor(6)}" style="font-size: 28px;"></i>
        </button>
        <button mat-button (click)="setHappiness(2)">
            <i class="far fa-frown" [ngStyle]="{'color': happinessColor(2)}" style="font-size: 28px;"></i>
        </button>
    </div>
</div>
<app-messaging [conversationUid]="_sessionChatInfo.sessionInfo.conversationUid"
               [type]="'CLIENTCOMMENT'"
               (emitMessage)="sendClientCommentReviewMessage($event)"
               [reviewMessages]="reviewMessages"
               [staticMessages]="staticMessages">
</app-messaging>

