<section class="row" [hidden]="errorMessage">
    <mat-card class="half-card left" *ngIf="dataHours">
        <mat-card-header>
            <mat-card-title>Average chats per hour <span class="subtitle">{{accountFilter}}</span></mat-card-title>
        </mat-card-header>
<!--        <mat-select placeholder="select day">-->
<!--                <mat-option>Monday</mat-option>-->
<!--                <mat-option>Tuesday</mat-option>-->
<!--                <mat-option>Wednesday</mat-option>-->
<!--                <mat-option>Thursday</mat-option>-->
<!--                <mat-option>Friday</mat-option>-->
<!--                <mat-option>Saturday</mat-option>-->
<!--                <mat-option>Sunday</mat-option>-->
<!--        </mat-select>-->
        <mat-card-content>
            <p-chart *ngIf="dataHours" type="bar" [data]="dataHours"  [options]="options"></p-chart>
        </mat-card-content>
    </mat-card>
    <mat-card class="half-card right" *ngIf="dataDays">
        <mat-card-header>
            <mat-card-title>Average chats per day <span class="subtitle">{{accountFilter}}</span></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p-chart *ngIf="dataDays" type="bar" [data]="dataDays"  [options]="options">
          </p-chart>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="errorMessage">
    <mat-card class="half-card" *ngIf="statistics">
        <mat-card-header>
            <mat-card-title>Statistics</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-chart-statistics [stats]="statistics"></app-chart-statistics>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>

