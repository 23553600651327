
<mat-card class="full-card">
    <mat-card-header >
        <mat-card-title>
            {{title}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content >
     Nothing for you to review!
    </mat-card-content>
</mat-card>
