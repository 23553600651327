<section class="row" [hidden]="errorMessage">
    <mat-card class="full-card limited" *ngIf="initialized">
        <mat-card-header>
            <mat-card-title>Start page statistics<span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p-chart type="bar" [data]="dataChart$ | async" [options]="options" height="80%"></p-chart>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="errorMessage">
    <mat-card class="full-card" *ngIf="initialized">
        <mat-card-header>
            <mat-card-title>Start page statistics</mat-card-title>
            <div class="toggles">
                <mat-button-toggle-group class="filter-button" value = "percentage">
                    <mat-button-toggle value="percentage" (click)="setNumberType('percentage')"> % </mat-button-toggle>
                    <mat-button-toggle value="number" (click)="setNumberType('number')">#</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <input #filter matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
            <span class="pointer" title="Clear filter" (click)="clearFilter()"><i class="far fa-times-circle"></i></span>
            <mat-table [dataSource]="dataTable" matSort matSortActive="numberOfChats" matSortDirection="desc" matSortDisableClear="true">
                <ng-container matColumnDef="path">
                    <mat-header-cell *matHeaderCellDef>Path</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.path}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>Totals</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="averageVisitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Average visitor rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.averageVisitorRating | number:'1.1-1') || 'n/a'}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{(getFooterRowAverageValue('averageVisitorRating') | number:'1.1-1') || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="averageClientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Average client rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.averageClientRating | number:'1.1-1') || 'n/a'}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{(getFooterRowAverageValue('averageClientRating') | number:'1.1-1') || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="reachInvites">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Reach</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.reachInvites | number:'1.1-1' | percentConcat) || 'n/a'}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{(getFooterRowSumValue('reachInvites') | percentConcat) || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="reachChats">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>CR-Chat</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.reachChats | number:'1.1-1' | percentConcat) || 'n/a'}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{(getFooterRowSumValue('reachChats') | percentConcat) || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="visits">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visits</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.visits || 'n/a'}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{getFooterRowSumValue('visits') || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="numberOfChats">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Number of chats</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.numberOfChats}}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{getFooterRowSumValue('numberOfChats') || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="percentageOfTotalChats">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Percentage of total chats</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.percentageOfTotalChats | number:'1.2-2'}}%</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{(getFooterRowSumValue('percentageOfTotalChats') | number:'1.2-2' | percentConcat) || 'n/a'}}</mat-footer-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ getDynamicData(i, element) }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{ getFooterRowValueForClassification(i) }}</mat-footer-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="!errorMessage">
    <mat-card class="third-card">
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
