<mat-card class="full-card" *ngIf="_messages" >
    <div style="padding-bottom: 20px">
        <mat-card-header style="justify-content: space-between">
            <mat-card-title>
                Transcript
            </mat-card-title>
            <div style="display: flex; justify-content: space-between; width: 50%">
                <div>
                    <button mat-button class="short-button" (click)="changeFontSize(2)">+</button>
                    <button mat-button class="short-button" (click)="changeFontSize(-2)">-</button>
                </div>
                <div style="display: flex; justify-content: flex-end" *ngIf="editable">
                    <app-small-button *ngIf="!pristine"
                                      (onClick)="saveMessages()"
                                      [color]="'white'"
                                      [bold]="true"
                                      [label]="'Save'"></app-small-button>
                    <app-small-button *ngIf="!pristine"
                                      (onClick)="reset()"
                                      [color]="'white'"
                                      [bold]="true"
                                      [label]="'Reset'"></app-small-button>
                    <app-small-button (onClick)="toggleEdit()"
                                      [color]="'white'"
                                      [bold]="true"
                                      [label]="'Edit'"></app-small-button>
                </div>
            </div>
        </mat-card-header>
    </div>

    <app-chat-messages (onChange)="pristine = false"
                       [maxHeight]="maxHeight"
                       [messages]="_messages"
                       [fontSize]="fontSize"
                       [edit]="edit"
                       [servedBy]="servedBy"></app-chat-messages>

</mat-card>
