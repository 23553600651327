import { Component } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { LabeledLabelNumericData } from '../../model/labeled-label-numeric-data';
import { TeamleadHomeScoresData } from '../../model/teamlead-home-scores-data';
import { TrendPipe } from '../../pipes/trend.pipe';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-teamlead-home',
  templateUrl: './teamlead-home.component.html',
})

export class TeamleadHomeComponent extends CoreParametersConsumer {
  options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          min: 0,
        },
      },
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };
  teamTotalScore: number;
  organizationTotalScore: number;
  trendPipe = new TrendPipe();
  currentGraph: string = 'Total Score';
  dataSource: LineChartData;
  subtitle: string;
  errorMessage: string = 'There is no data available';
  dataTotalRating;
  scores: TeamleadHomeScoresData;
  private organizationHappiness: LabeledLabelNumericData;
  private teamScores: LabeledLabelNumericData;
  private dataVisitorRating;
  private dataClientRating;
  private dataWorkload;
  private dataChatsPerHour;
  private dataEuroPerHour;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService,
              private authorizationService: AuthorizationService) {
    super(graphFilterService);
  }

  get isLCD(): boolean {
    return this.authorizationService.isLcd;
  }

  get isLineChartInitialized(): boolean {
    return this.dataSource !== undefined
      && this.dataSource.datasets.length > 0
      && this.dataSource.datasets[0].data.length > 0;
  }

  loadData(coreParameters: CoreParameters): void {
    this.dataSource = undefined;
    this.dataTotalRating = undefined;
    this.dataClientRating = undefined;
    this.dataVisitorRating = undefined;

    this.graphService.getTLScores(coreParameters).subscribe((scores) => {
      this.scores = scores;
      this.teamScores = scores.teamScores;
      this.organizationHappiness = scores.organizationScores;
      this.setGraphType(this.currentGraph);
    });
  }

  setGraphType(graph: string): void {
    this.currentGraph = graph;
    switch (graph) {
      case 'Visitor Score' : {
        if (!this.dataVisitorRating) {
          this.generateGraphData('dataVisitorRating', 'Visitor Score', 'Visitor Score');
        }
        this.dataSource = this.dataVisitorRating;
        this.subtitle = 'Visitor Happiness';
        break;
      }
      case 'Client Score' : {
        if (!this.dataClientRating) {
          this.generateGraphData('dataClientRating', 'Client Score', 'Client Score');
        }
        this.dataSource = this.dataClientRating;
        this.subtitle = 'Clients Happiness';
        break;
      }
      case 'Total Score' : {
        if (!this.dataTotalRating) {
          this.generateGraphData('dataTotalRating', 'Total Score', 'Total Score');
        }
        this.dataSource = this.dataTotalRating;
        this.subtitle = 'Total Happiness';
        break;
      }
      case 'Workload' : {
        if (!this.dataWorkload) {
          this.generateGraphData('dataWorkload', 'Workload', 'Workload');
        }
        this.dataSource = this.dataWorkload;
        this.subtitle = 'Workload';
        break;
      }
      case 'Chats per hour' : {
        if (!this.dataChatsPerHour) {
          this.generateGraphData('dataChatsPerHour', 'Chats', 'Chats');
        }
        this.dataSource = this.dataChatsPerHour;
        this.subtitle = 'Chats per hour';
        break;
      }
      case 'Euro per hour' : {
        if (!this.dataEuroPerHour) {
          this.generateGraphData('dataEuroPerHour', 'Euro', 'Euro');
        }
        this.dataSource = this.dataEuroPerHour;
        this.subtitle = 'Euro per hour';
        break;
      }
    }
    this.currentGraph = graph;
  }

  generateLineChart(label: string, dataLabelName: string, data: LabeledLabelNumericData): LineChartData {
    const dataObject = data.data.find((x) => x.label === dataLabelName);
    return {
      datasets: [{
        borderColor: this.colorService.getColor(0),
        data: data.data.find((x) => x.label === dataLabelName).data,
        fill: false,
        label,
      },
        this.generateTrendLine(dataObject, this.colorService.getColor(7), false),
      ],
      labels: data.labels,
    };
  }

  private generateGraphData(category: string, label: string, datakey: string) {
    if (this.teamScores !== undefined) {
      if (this.teamScores.labels.length > 0) {
        this.errorMessage = null;
      } else {
        this.errorMessage = 'There is no data available';
      }
      this[category] = this.generateLineChart(label, datakey, this.teamScores);
      this.teamTotalScore = +this.teamScores.keyValuePairs.find((x) => (x.key === 'Total Score')).value;
      this.organizationTotalScore = +this.organizationHappiness.keyValuePairs.find((x) => (x.key === 'Total Score')).value;
    }
  }

  private generateTrendLine(result: { label: string, data: number[] }, color: string, hide: boolean): any {
    return {
      borderColor: color,
      borderDash: [10, 15],
      data: this.trendPipe.transform(result.data),
      fill: false,
      hidden: hide,
      label: 'Trend line' + ' ' + result.label,
      pointBorderWidth: 0,
      radius: 0,
    };
  }
  private greaterThanAlertFn(teamScore: number, organizationScore: number) {
    if (teamScore > organizationScore) {
      return 'happiness-good';
    }
    if (teamScore === organizationScore) {
      return 'happiness-neutral';
    } else {
      return 'happiness-bad';
    }
  }

  private smallerThanAlertFn(teamScore: number, organizationScore: number) {
    if (teamScore < organizationScore) {
      return 'happiness-good';
    }
    if (teamScore === organizationScore) {
      return 'happiness-neutral';
    } else {
      return 'happiness-bad';
    }
  }
}

export interface LineChartData {
  labels: string[];
  datasets: Array<{ label: string, data: number[], fill?: boolean, borderColor?: string }>;
}
