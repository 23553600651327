import {Injectable, OnDestroy, OnInit} from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { GraphFilterService } from '../../../graph/graph-filter.service';
import { CoreParameters } from '../../../model/core-parameters.model';
import { FilterParameters } from '../../../model/support/filter-parameters.model';
import { SessionInfo } from '../../../model/support/session-info.model';
import { SupportFilterService } from '../support-filter.service';

@Injectable()
export abstract class SupportParametersConsumer implements OnInit, OnDestroy {
  public filterParameterFilter: (data: SessionInfo, filter: string) => boolean;
  private coreParametersSubscription: Subscription;
  private supportParametersSubscription: Subscription;

  protected constructor(protected graphFilterService: GraphFilterService,
                        protected supportFilterService: SupportFilterService,
                        public authorizationService: AuthorizationService) {
  }

  compareQualityChecks(filter: FilterParameters, data: SessionInfo): boolean {
    let found: boolean = true;
    if (data.qualityChecks === undefined) {
      return false;
    }

    found = filter.qualityChecks
      .filter((filterQ) => filterQ.value != null)
      .every((filterQ) => data.qualityChecks
        .some((dataQ) => dataQ.key === filterQ.key && dataQ.value === filterQ.value));

    return found;
  }

  compareOperatorErrors(filter: FilterParameters, data: SessionInfo): boolean {
    if (filter.operatorErrors == null || filter.operatorErrors.length === 0) {
      return true;
    } else if (data.operatorErrors === undefined) {
      return filter.operatorErrors.length <= 0;
    }
    let found: boolean = true;
    if (filter.operatorErrors) {
      filter.operatorErrors.forEach((or) => {
        if (data.operatorErrors.indexOf(or) < 0) {
          found = false;
        }
      });
    }
    return found;
  }

  compareShowTlChats(filter: FilterParameters, data: SessionInfo): boolean {
    return filter.showTlChats === undefined ||
      ((data.servedByRole === 'ROLE_TL') && filter.showTlChats) ||
      (data.servedByRole !== 'ROLE_TL' && !filter.showTlChats);
  }

  compareClientReviewed(filter: FilterParameters, data: SessionInfo): boolean {
    const clientReviewed = ((data.clientComments != null) && data.clientComments.length > 0) ||
      (data.clientFeedback != null && data.clientFeedback) ||
       data.clientRating > 0;
    return filter.clientComments === undefined ||
      (filter.clientComments === clientReviewed);
  }

  compareOperatorComments(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.operatorComments == null || filter.operatorComments ===
      (data.operatorComments !== undefined && data.operatorComments.length > 0));
  }

  compareOperatorFeedback(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.operatorFeedback == null || filter.operatorFeedback === data.operatorFeedback)
  }

  compareBconnectComments(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.bconnectComments == null || filter.bconnectComments === data.bconnectFeedback
      || (filter.bconnectComments === false && data.bconnectFeedback == null));
  }

  compareTopChats(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.markedTopChat == null || filter.markedTopChat === data.markedTopChat
      || (filter.markedTopChat === false && data.markedTopChat == null));
  }

  compareHqReviews(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.hqReview == null || filter.hqReview === data.hqReview
      || (filter.hqReview === false && data.hqReview == null));
  }

  compareCorrections(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.correction == null || filter.correction === data.correction || (filter.correction === false && data.correction == null));
  }

  compareRecentlyClosedReviews(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.closedReviews == null || filter.closedReviews ===
      (data.reviewClosedAt !== undefined && data.reviewClosedAt.valueOf() > 0));
  }

  compareLanguageReview(filter: FilterParameters, data: SessionInfo): boolean {
    return (filter.languageReview == null || filter.languageReview === data.languageReview
      || (filter.languageReview === false && data.languageReview == null));
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  ngOnInit(): void {
    this.filterParameterFilter = (data: SessionInfo, filterString: string) => {
      const filter: FilterParameters = JSON.parse(filterString);
      return (this.isValid(filter.categories, !this.isClient
        && !this.supportFilterService.singleAccountSelected ? data.classificationCategory : data.classification)
        &&
        this.isValid(filter.visitorRatings, data.visitorRating)
        &&
        this.isValid(filter.bconnectRating, data.bconnectRating)
        &&
        this.isValid(filter.clientRatings, data.clientRating)
        &&
        this.isValid(filter.departments, data.department)

        && this.searchData(filter, data)
        && this.searchClassificationData(filter, data)

        && this.compareClientReviewed(filter, data)
        && this.compareBconnectComments(filter, data)
        && (!filter.hasQualityChecks || this.compareQualityChecks(filter, data))
        && this.compareOperatorErrors(filter, data)
        && this.compareTopChats(filter, data)
        && this.compareCorrections(filter, data)
        && this.compareHqReviews(filter, data)
        && this.compareOperatorComments(filter, data)
        && this.compareOperatorFeedback(filter, data)
        && this.compareShowTlChats(filter, data)
        && this.compareRecentlyClosedReviews(filter, data)
        && this.compareLanguageReview(filter, data)

      );
    };

    this.coreParametersSubscription = this.graphFilterService.coreParameters$.subscribe(
      (coreParameters: CoreParameters) => {
        this.loadData(coreParameters);
      });

    this.supportParametersSubscription = this.supportFilterService.filterParameters$.subscribe(
      (filterParameters: FilterParameters) => this.filterData(filterParameters),
    );

  }

  ngOnDestroy(): void {
    this.coreParametersSubscription.unsubscribe();
    this.supportParametersSubscription.unsubscribe();
  }

  abstract loadData(coreParameters: CoreParameters): void;

  abstract filterData(filterParameters: FilterParameters): void;

  private isValid(filterNumbers: any[], dataNumbers: any) {
    return (filterNumbers == null ||
      filterNumbers.length === 0 ||
      filterNumbers.includes(dataNumbers));
  }

  private searchData(filter: FilterParameters, data: SessionInfo): boolean {
    if (filter.search === undefined || filter.search.length === 0) {
      return true;
    }
    return Object.keys(data).some((k) => data[k].toString().toLowerCase().includes(filter.search.toLowerCase()));
  }

  private searchClassificationData(filter: FilterParameters, data: SessionInfo): boolean {
    if (filter.searchClassifications === undefined || filter.searchClassifications.length === 0) {
      return true;
    }
    return data.classificationFields?.some(dat => dat.value.toLowerCase().includes(filter.searchClassifications.toLowerCase()));
  }
}
