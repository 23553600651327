<section class="row"  *ngIf = "!conversationUid && !hasData" >
    <app-no-data [title]="'New ' + title"></app-no-data>
</section>
<section class="row"  *ngIf="!conversationUid && hasData" >
    <mat-card class="full-card">
        <mat-card-header >
            <mat-card-title>
                {{title}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content >
            <mat-table class="archive-table" [dataSource]="tableDataSource" matSort matSortActive="conversationUid"
                       matSortDirection="desc" matSortDisableClear="true">
                <ng-container matColumnDef="startDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.startDate | date}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationUid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button pointer" style="color: #141464; font-weight: bold;"
                                (click)="showUpdate(element)">
                            {{element.conversationUid}}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actionType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.actionType}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>
                <ng-container  matColumnDef="servedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Served By</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.servedBy}}</mat-cell>
                </ng-container>
                <ng-container  matColumnDef="involvedUsers">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Involved Users</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.involvedUsers}}</mat-cell>
                </ng-container>
                <ng-container  matColumnDef="reviewProcessState">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.reviewProcessState}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="remove">
                    <mat-header-cell *matHeaderCellDef>

                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <i class="fas fa-eye pointer" [ngStyle]="{'color': '#141464', 'font-size': '16px'}" (click)="markAsSeenBySessionAndType(element.conversationUid, element.actionType)" matTooltip="Mark update as seen"></i>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [length]=tableDataLength
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" *ngIf="conversationUid">
    <div class="two-third-card">
        <app-session-details [conversationUid]="conversationUid"
                             (unloadConversationDetailsEvent)="unsetConversationUid()"
                             (loadedSession)="setLoadedSession($event)"
                             (getNextEvent)="getNextReview($event)">
        </app-session-details>
    </div>
    <mat-card id="review-card" class="third-card" style="padding-top: 15px !important;">
        <app-feedback-details *ngIf="!isClient && reviewService.review"
                              [conversationUid]="conversationUid"
                              (markAsSeenEvent)="markAsSeen($event)"
                              [openOnTab]="openOnTab"
                              [tabsWithUpdate]="tabsWithUpdate"
                              [review]="reviewService.review">
        </app-feedback-details>
        <app-feedback-client-feedback *ngIf="isClient"
                                      [review]="reviewService.review"
                                      [sessionChatInfo]="sessionChatInfo">
        </app-feedback-client-feedback>
    </mat-card>
</section>


