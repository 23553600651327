<section class="login-block">
    <mat-card>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                <mat-error *ngIf="this.message.length > 13">
                    The username and password were not recognized
                </mat-error>
                <mat-form-field class="full-width-input">
                    <input matInput type="username" id="username"
                           name="username" placeholder="Username" name="username" formControlName="username" required >
                    <mat-error>
                        Please provide a username
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                    <input matInput type="password" id="password"
                           name="password" type="password" placeholder="Password" formControlName="password" required >
                    <mat-error>
                        Please provide a password
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button style="background-color: #000066; width: 100%" color="primary">Login</button>
            </form>
        </mat-card-content>
    </mat-card>
</section>
