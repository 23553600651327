<div class="chat-messages" #chatMessages [ngStyle]="{'max-height': maxHeight}">
    <ul>
        <li *ngFor="let message of messages" [className]="message.sender"
            [ngStyle]="{'font-size': fontSize + 'px'}"> <i>{{message.date | date:"HH:mm:ss"}}</i> -
            {{getMessageSender(message)}}<span *ngIf="message.sender != 'system'">: </span>
            <textarea matInput cdkTextareaAutosize
                      style="padding: 20px 10px 30px; background-color: white; margin: 10px;"
                      [(ngModel)]="message.message"
                      (ngModelChange)="onChange.emit()"
                      *ngIf="edit && message.sender != 'system'; else noEdit">
                    {{message.message}}
                </textarea>
            <ng-template #noEdit>{{message.message}}</ng-template>
        </li>
    </ul>
</div>
