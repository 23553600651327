<div *ngIf="_faqAddition" [style.font-size.px]="14">
    <div class="faq-dialog" [style.overflow-y]="'scroll'">
        <div class="flex-between" style="padding: 0.5em 0">
            <div>
                <button *ngIf="mayEnableEditing()" (click)="enableEditing()" class="edit-button">
                    <i class="fas fa-edit"></i>
                </button>
            </div>

            <i class="fas fa-times dialog-close-button" (click)="close()"></i>
        </div>

        <div style="background-color: lightgrey; margin-top: 4px; border-radius: 3px;" class="flex-center p1">{{stageText}}</div>

        <div>
            <mat-form-field class="mat-form-no-padding-bottom" style="width: 100%" *ngIf="!isOperator && !isClient"
                            appearance="outline">
                <mat-select placeholder="Product or Service"
                            [(ngModel)]="_faqAddition.product"
                            (ngModelChange)="onChange()"
                            [disabled]="!canEdit">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search product" noEntriesFoundLabel="Nothing found" [formControl]="productSearchControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let product of products | async | genericFunctionFilter: {filterFns: [productFilterFn], pipeForcedAt: pipeForcedAt}" [value]="product">
                        {{product}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-tab-group dynamicHeight>
                <mat-tab *ngFor="let qna of _faqAddition.qnas | genericFunctionFilter: {sortFn: feedbackService.qnaSortFn}; let i = index" [label]="qna.languageCode">
                    <ng-template mat-tab-label>
                        {{qna.languageCode}}
                        <i class="fas fa-flag fa-xs"
                           [style.color]="'#ffaf49'"
                           [style.margin-left]="'0.45em'" *ngIf="qna.answer?.length > 0 || qna.question?.length > 0"></i>
                    </ng-template>
                    <div class="flex-center qna-label">
                        Question
                    </div>
                    <div class="mat-form-tiny-padding">
                        <mat-form-field class="mat-form-no-padding-bottom" style="width: 100%;" appearance="outline">
                             <textarea matInput placeholder="Question"
                                       [cdkTextareaAutosize]="true" [rows]="4"
                                       [(ngModel)]="qna.question"
                                       (ngModelChange)="onChange()"
                                       [disabled]="!canEdit">
                             </textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-center qna-label">
                        Answer
                    </div>
                    <div class="mat-form-tiny-padding">
                        <mat-form-field class="mat-form-no-padding-bottom" style="width: 100%;" appearance="outline">
                    <textarea title="answer" matInput placeholder="Answer"
                              [cdkTextareaAutosize]="true" [rows]="4"
                              [(ngModel)]="qna.answer"
                              (ngModelChange)="onChange()"
                              [disabled]="!canEdit">
                    </textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-center qna-label">
                        Tags
                    </div>
                    <div class="mat-form-no-padding">
                        <mat-form-field *ngIf="!isClient"
                                        class="mat-form-no-padding-bottom" style="width: 100%;"
                                        appearance="outline">
                            <mat-chip-list #chipList [disabled]="!canEdit">
                                <mat-chip *ngFor="let tag of qna.tags"
                                          [selectable]="true"
                                          [removable]="true"
                                          (removed)="remove(tag, qna)"
                                          [disabled]="!canEdit">
                                    {{tag.tag}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input style="height: 3em;" title="tags" placeholder="Tags"
                                       [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="true"
                                       (matChipInputTokenEnd)="add($event, qna)"
                                       (change)="onChange()">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </div>
        <div class="pt1em">
            <app-simple-messaging
                [scrollable]="false"
                [messages]="_faqAddition.comments"
                (addMessage)="addComment($event); onChange();">
            </app-simple-messaging>
        </div>
    </div>

    <div>
        <app-process-faq [faqAddition]="_faqAddition"
                         [review]="review"
                         [disabled]="!canEdit"
                         (addCommentEvent)="addComment($event)"
                         (savedEvent)="saved($event)"
                         (deletedEvent)="deleted()">
        </app-process-faq>
    </div>

</div>
