import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';

import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthorizationService } from '../../core/authorization.service';
import { GraphFilterService } from '../../graph/graph-filter.service';
import { GraphService } from '../../graph/graph.service';
import { Account } from '../../model/account.model';
import { DatePreset } from '../../model/date-preset.enum';
import { DateRange } from '../../model/date-range.model';
import { Domain } from '../../model/domain.model';
import { Roles } from '../../model/roles.enum';
import { TeamDetails } from '../../model/team-details.model';
import { TeamMembership } from '../../model/team-member.model';
import { EnumService } from '../../src/main/typescript/shared/enum.service';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-support-core-filter',
  templateUrl: './support-core-filter.component.html',
})
export class SupportCoreFilterComponent implements OnInit, OnDestroy {

  accountSearchControl = new FormControl();

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  get selectedTeam(): TeamDetails {
    return this.coreFilterForm.get('team').value;
  }

  private static compareAlphabetically(a: TeamMembership, b: TeamMembership): number {
    if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
      return -1;
    } else if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  private static computeLastWeek(now: Moment): DateRange {
    const lastWeek: Moment = moment(now).subtract(1, 'weeks');
    const lastMonday: Moment = moment(lastWeek).startOf('week');
    const lastSunday: Moment = moment(lastWeek).endOf('week');

    return {start: lastMonday, end: lastSunday};
  }

  private static computeLastMonth(now: Moment): DateRange {
    const lastMonth: Moment = moment(now).subtract(1, 'months');
    const firstDay: Moment = moment(lastMonth).startOf('month');
    const lastDay: Moment = moment(lastMonth).endOf('month');

    return {start: firstDay, end: lastDay};
  }

  private static computeLastYear(now: Moment): DateRange {
    const lastYear: Moment = moment(now).subtract(1, 'years');
    const firstDay: Moment = moment(lastYear).startOf('year');
    const lastDay: Moment = moment(lastYear).endOf('year');

    return {start: firstDay, end: lastDay};
  }

  private static computeThisWeek(now: Moment): DateRange {
    const lastMonday: Moment = moment(now).startOf('week');
    const lastSunday: Moment = moment(now).endOf('week');

    return {start: lastMonday, end: lastSunday};
  }

  private static computeThisMonth(now: Moment): DateRange {
    const firstDay: Moment = moment(now).startOf('month');
    const lastDay: Moment = moment(now).endOf('month');

    return {start: firstDay, end: lastDay};
  }

  private static computeThisYear(now: Moment): DateRange {
    const firstDay: Moment = moment(now).startOf('year');
    const lastDay: Moment = moment(now).subtract(1, 'days');

    return {start: firstDay, end: lastDay};
  }

  channels: string[];
  readonly channelPlaceholder = 'Channel';
  coreFilterForm: FormGroup;
  accountPlaceholder = 'Account';
  domainPlaceholder = 'Domain';
  datePresetPlaceholder = 'Period select';
  startDatePlaceholder = 'Start date';
  endDatePlaceholder = 'End date';
  summarizeBy = 'day';
  teams: TeamDetails[];
  chosenTeam: TeamDetails;
  operators: TeamMembership[];

  accounts: Account[];
  selectedAccounts: Account[] = [];

  domains: Domain[];

  selectedOperators: TeamMembership[] = [];
  completeOperatorList: TeamMembership[] = [];
  readonly datePresets = [
    {
      description: 'Last week',
      id: DatePreset.LastWeek,
    },
    {
      description: 'Last month',
      id: DatePreset.LastMonth,
    },
    {
      description: 'Last year',
      id: DatePreset.LastYear,
    },
    {
      description: 'Week-to-date',
      id: DatePreset.WeekToDate,
    },
    {
      description: 'Month-to-date',
      id: DatePreset.MonthToDate,
    },
    {
      description: 'Year-to-date',
      id: DatePreset.YearToDate,
    },
    {
      description: 'Custom',
      id: DatePreset.Custom,
    },
  ];

  componentDestroyed$ = new Subject<void>();
  accountFilterPipeForcedAt: Date;

  constructor(
    private formBuilder: FormBuilder,
    private graphService: GraphService,
    private graphFilterService: GraphFilterService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private datePipe: DatePipe,
    private enumService: EnumService
  ) { }

  ngOnInit(): void {
    this.enumService.getConversationChannels().subscribe(
      (channels: string[]) => {
        const filterValues = ['SMARTSUPP', 'GIOSG'];

        this.channels = channels
          .filter(channel => !filterValues.includes(channel))
          .map(channel => channel.charAt(0).toUpperCase() + channel.slice(1).toLowerCase());
      });

    this.createForm();
    this.onChanges();
    this.initializeDatePreset();
    this.graphService.getAccounts('SS').subscribe((acc) => {
      this.accounts = acc;

      if (this.accounts.length === 1) {
        this.coreFilterForm.get('accounts').setValue([this.accounts[0]]);
      } else if (this.authorizationService.isClient) {
        this.coreFilterForm.get('accounts').setValue(acc);
      }

    });

    this.accountSearchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.accountFilterPipeForcedAt = new Date();
      });

    this.graphService.getTeams().subscribe((teams: TeamDetails[]) => {
      this.teams = teams;
    });

    this.graphService.getAllMemberships().subscribe((teamMembers: TeamMembership[]) => {
      this.completeOperatorList = JSON.parse(JSON.stringify(teamMembers));
      this.operators = this.membershipsDistinctByUser(teamMembers);
    });
  }

  accountFilterFn = (account: Account) => {
    const search = this.accountSearchControl?.value;
    return (!search || account.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  accountSortFn = (a: Account, b: Account) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  toggleAllElements(checkbox: MatCheckbox, select: MatSelect) {
    if (checkbox.checked) {
      select.options.forEach((item: MatOption) => {
        if (item.value) {
          item.select();
        }
      });
    } else {
      select.options.forEach((item: MatOption) => {
        if (item.value) {
          item.deselect();
        }
      });
    }
  }

  membershipsDistinctByUser(memberships: TeamMembership[]) {
    const inList = [];
    return memberships.filter((m) => {
      const isInList = inList.indexOf(m.userName) >= 0;
      if (!isInList) {
        inList.push(m.userName);
      }
      return !isInList;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  onSubmit(): void {
    if (this.router.isActive('dashboard', true)) {
      this.router.navigate(['dashboard/default']);
    }
    if (this.coreFilterForm.valid) {
      const form = this.coreFilterForm.value;
      this.graphFilterService.generate({
        accounts: form.accounts,
        domain: form.domain,
        endDate: form.dateRange.end,
        operators: form.operators,
        showHybrids: form.showHybrids,
        channels: form.channels,
        startDate: form.dateRange.start,
        summarizeBy: form.summarizeBy,
        team: form.team,
      });
    }
  }

  setShowHybridChats() {
    this.graphFilterService.showHybridChats = !this.graphFilterService.showHybridChats;
  }

  resetTeam(): void {
    this.resetOperator();
    this.coreFilterForm.get('team').reset();
  }

  getShowHybridMessage(): string {
    return this.graphFilterService.showHybridChats ? 'Exclude hybrid chats' : 'Fetch hybrid chats only';
  }

  resetDomain(): void {
    this.coreFilterForm.get('domain').reset();
  }

  resetChannels(): void {
    this.coreFilterForm.get('channels').reset();
  }

  operatorSelectable(teamMember: TeamMembership): boolean {
    return !this.selectedTeam || this.isActiveInPeriod(teamMember);
  }

  getMembershipsForUser(userName: string, teamId: number): TeamMembership[] {
    if (this.teams) {
      return this.teams.find((t) => t.id === teamId)
        .teamMemberships
        .filter((c) => c.userName === userName)
        .sort((c) => c.joinDate.valueOf());
    } else {
      return [];
    }
  }

  operatorDatesToolTip(operator: TeamMembership): string {
    if (this.teams) {
      const memberships = this.getMembershipsForUser(operator.userName, operator.teamId);
      let response = '';
      let i = 1;
      for (const m of memberships) {
        if (m.joinDate != null) {
          response = response.concat('joined: ').concat(this.datePipe.transform(m.joinDate, 'dd-MM-yyyy'));
          if (m.leaveDate) {
            response = response.concat(' left: ').concat(this.datePipe.transform(m.leaveDate, 'dd-MM-yyyy'));
          }
        }
        if (i < memberships.length) {
          response = response.concat(' | ');
        }
        i++;
      }
      return response;
    }
  }

  accountToolTip(): string {
    return (this.selectedAccounts && this.selectedAccounts.length > 0) ?
      this.selectedAccounts.map((a: Account) => a.name).join(', ') : '';
  }

  operatorToolTip(): string {
    return (this.selectedOperators.length > 0) ?
      this.selectedOperators.map((t: TeamMembership) => t.userName).join(', ') : '';
  }

  clearAccount(event): void {
    this.resetAccount();
    this.graphFilterService.showHybridChats = false;
    event.stopPropagation();
  }

  clearOperator(event): void {
    this.resetOperator();
    this.selectedOperators = [];
    event.stopPropagation();
  }

  private createForm(): void {
    this.coreFilterForm = this.formBuilder.group({
      accounts: [''],
      datePreset: [''],
      dateRange: this.formBuilder.group({
        end: ['', Validators.required],
        start: ['', Validators.required],
      }),
      domain: [''],
      operators: [{value: '', disabled: true}],
      // showHybrids: [false],
      channels: [],
      summarizeBy: ['day', Validators.required],
      team: [{value: undefined, disabled: true}],
    });

    this.authorizationService.hasRole(Roles.TeamLead).subscribe((result: boolean) => {
      if (result) {
        this.coreFilterForm.get('operators').enable();
        this.coreFilterForm.get('team').enable();
      }
    });
  }

  private onChanges(): void {
    this.coreFilterForm.get('accounts').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
      (accounts: Account[]) => {
        if (accounts && accounts.length === 1) {
          this.graphService.getDomains(accounts[0].id).subscribe((dom) => {
            this.domains = dom;
            if (this.domains.length === 1) {
              this.coreFilterForm.get('domain').setValue(this.domains[0]);
            }
          });
          this.resetDomain();
        }
        if (accounts && accounts.length > 1) {
          this.domains = [];
          this.coreFilterForm.get('domain').setValue(null);
          this.resetDomain();
        }
        if (accounts && accounts.length === 0 && this.coreFilterForm.get('domain').value) {
          this.domains = [];
          this.coreFilterForm.get('domain').setValue(null);
          this.resetDomain();
        }

        if (accounts) {
          this.selectedAccounts = accounts;
        }
      },
    );
    this.coreFilterForm.get('datePreset').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
      (datePreset: DatePreset) => {
        this.onDatePresetChange(datePreset);
      },
    );
    this.coreFilterForm.get('dateRange').statusChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
      () => {
        this.onDateChanges();
      },
    );
    this.coreFilterForm.get('team').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
      (team: TeamDetails) => {
        if (team) {
          this.operators = this.membershipsDistinctByUser(team.teamMemberships);
          this.coreFilterForm.get('operators').reset();
          this.sortOperatorsByActive();
        } else {
          this.operators = JSON.parse(JSON.stringify(this.completeOperatorList));
        }
        this.chosenTeam = team;
      },
    );
    this.coreFilterForm.get('operators').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
      (oper: TeamMembership[]) => {
        if (oper) {
          this.selectedOperators = oper;
        }
      },
    );
  }
  private initializeDatePreset() {
    this.onDatePresetChange(DatePreset.MonthToDate);
    this.coreFilterForm.get('datePreset').setValue(DatePreset.MonthToDate);
  }

  private resetAccount(): void {
    this.resetDomain();
    this.coreFilterForm.get('accounts').reset();
    this.selectedAccounts = [];
  }

  private resetOperator(): void {
    this.coreFilterForm.get('operators').reset();
  }

  private onDateChanges(): void {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');

    if (dateRangeGroup.dirty) {
      const datePresetField = this.coreFilterForm.get('datePreset');
      datePresetField.reset(DatePreset.Custom);
    }
    this.sortOperatorsByActive();
  }

  private sortOperatorsByActive() {
    if (this.operators) {
      const active = [];
      const inActive = [];
      this.operators.forEach((o) => {
        if (this.operatorSelectable(o)) {
          active.push(o);
        } else {
          inActive.push(o);
        }
      });
      active.sort((a, b) => SupportCoreFilterComponent.compareAlphabetically(a, b));
      inActive.sort((a, b) => SupportCoreFilterComponent.compareAlphabetically(a, b));
      this.operators = active.concat(inActive);
    }
  }

  private isActiveInPeriod(membership: TeamMembership): boolean {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');
    const start = Date.parse(dateRangeGroup.get('start').value);
    const end = Date.parse(dateRangeGroup.get('end').value);
    const memberships = this.getMembershipsForUser(membership.userName, membership.teamId);
    return memberships.find((m) => {
      const joinedBeforeEnd = new Date(m.joinDate).valueOf() < end;
      const notLeftBeforeStart = (m.leaveDate === null || m.leaveDate.valueOf() > start);
      return joinedBeforeEnd && notLeftBeforeStart;
    }) !== undefined;
  }

  private onDatePresetChange(datePreset: DatePreset): void {
    const now: Moment = moment();

    switch (datePreset) {
      case DatePreset.LastWeek:
        this.setDateFields(SupportCoreFilterComponent.computeLastWeek(now));
        break;
      case DatePreset.LastMonth:
        this.setDateFields(SupportCoreFilterComponent.computeLastMonth(now));
        break;
      case DatePreset.LastYear:
        this.setDateFields(SupportCoreFilterComponent.computeLastYear(now));
        break;
      case DatePreset.WeekToDate:
        this.setDateFields(SupportCoreFilterComponent.computeThisWeek(now));
        break;
      case DatePreset.MonthToDate:
        this.setDateFields(SupportCoreFilterComponent.computeThisMonth(now));
        break;
      case DatePreset.YearToDate:
        this.setDateFields(SupportCoreFilterComponent.computeThisYear(now));
        break;
      case DatePreset.Custom:
        break;
    }
    this.sortOperatorsByActive();
  }

  private setDateFields(dateRange: DateRange): void {
    const dateRangeGroup: AbstractControl = this.coreFilterForm.get('dateRange');

    dateRangeGroup.reset({
      end: dateRange.end.toISOString(true),
      start: dateRange.start.toISOString(true),
    }, {
      emitEvent: false,
    });
  }

  get showHybridsToggle(): boolean {
    return this.accounts?.some((a) => a.hasHybridChats);
  }
}
