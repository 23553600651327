<div class="spinner-container">
    <span *ngIf="saving">
        <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
    </span>
</div>
<div>
    <mat-table class="review-table" *ngIf="tableDataSource" [dataSource]="tableDataSource">
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef  style="margin-left: 10%;"></mat-header-cell>
            <mat-cell *matCellDef="let element" style="margin-left: 10%;">
                <mat-checkbox (change)="toggleValue(element)"
                              [checked]="element.value"
                              [disabled]="disabled"
                              class="toggle-error">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="error">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.key}}
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
