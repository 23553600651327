import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OpenReview } from '../../../model/support/open-review.model';
import { Category } from '../../../model/support/review/category.model';
import { ClassificationDto } from '../../../model/support/review/classification-dto';
import { CategoryField } from '../../../model/support/review/classification-input.model';
import { AlertifyService } from '../../service/alertify.service';
import { OpenReviewService } from '../../service/open-review.service';
import { AuthorizationService } from "../../../core/authorization.service";

@Component({
  selector: 'app-classify',
  templateUrl: './classify.component.html',
})
export class ClassifyComponent {

  _chatClassification: ClassificationDto;

  oldCategory: string;
  oldDepartment: string;

  categories: Category[];
  departments: string[];
  availableDepartments: string[];

  activeCategory: Category;

  _openReview: OpenReview;

  form: FormGroup; // for classification fields

  @Input()
  enabled: boolean;

  @Output()
  validatorEvent = new EventEmitter<boolean>();

  constructor(
    private openReviewService: OpenReviewService,
    private alertifyService: AlertifyService,
    private authorizationService: AuthorizationService
  ) { }

  @Input()
  set chatClassification(chatClassification: ClassificationDto) {
    this._chatClassification = chatClassification;
    if (chatClassification) {
      this.oldCategory = chatClassification.category;
      this.oldDepartment = chatClassification.department;
    }
  }

  @Input()
  set openReview(openReview: OpenReview) {
    this._openReview = openReview;
    this.openReviewService.getDepartments(openReview.account).subscribe(d => {
      this.departments = d;
      this.openReviewService.getCategories(openReview.parentDomain).subscribe((c) => {
        this.categories = c;
        // when categories load, set active category
        this.changeCategory(this._chatClassification.category);
      });
    });

  }

  changeCategory(category: string) {
    const match = this.categories.find((c) => c.category === category);
    this._chatClassification.classificationCategory = match.classificationCategory;
    this.activeCategory = match;
    const formGroup = {};
    match.classificationInput.forEach((input) => {
      const field = this._chatClassification.classificationFields.find((f) => f.categoryLinkedFieldId === input.linkedFieldId);
      let value: any = field ? field.value : '';
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }
      formGroup[input.name] = new FormControl(value);

    });
    this.form = new FormGroup(formGroup);
    this.listenToInputChanges(match.classificationInput);
    this.setValidators(match.classificationInput);
    this.validatorEvent.emit(this.form.valid);

    this.availableDepartments = match.chatDeliveryTargets?.length > 0 ? match.chatDeliveryTargets.map(t => t.name) : this.departments;

    if (!this.availableDepartments.some(d => d === this._chatClassification.department)) {
      this._chatClassification.department = null;
    }
  }

  setValidators(inputs: CategoryField[]) {
    inputs.forEach((i) => {
      if (i.required) {
        if (i.type.toUpperCase() !== 'CHECKBOX') {
          this.form.get(i.name)?.setValidators([Validators.required]);
        } else {
          this.form.get(i.name)?.setValidators([Validators.requiredTrue]);
        }
      } else {
        this.form.get(i.name)?.setValidators([]);
      }
      this.form.get(i.name)?.updateValueAndValidity();
    });
  }

  listenToInputChanges(inputs: CategoryField[]) {
    inputs.forEach((i) => {
      this.form.get(i.name)?.valueChanges.subscribe((change) => {
        if (this._chatClassification.classificationFields.find((f) => f.name === i.name)) {
          // chat classification already has this field, only update the value
          this._chatClassification.classificationFields.forEach((f) => {
            if (f.name === i.name) {
              f.value = change; // set the value of the field to the changed value
            }
          });
        } else {
          // field not yet present in the chat classification, add it
          this._chatClassification.classificationFields.push({
            id: 0,
            name: i.name,
            // tslint:disable-next-line:object-literal-sort-keys
            categoryLinkedFieldId: i.linkedFieldId,
            required: this.activeCategory.classificationInput.find((ci) => ci.name === i.name).required,
            type: this.activeCategory.classificationInput.find((ci) => ci.name === i.name).type,
            inTranscript: this.activeCategory.classificationInput.find((ci) => ci.name === i.name).inTranscript,
            sequence: this.activeCategory.classificationInput.find((ci) => ci.name === i.name).position,
            value: change,
          });
        }
        this.validatorEvent.emit(this.form.valid);
      });
    });
  }

  getClassificationNameBasedOnUserLanguage(categoryField: CategoryField): string {
    const currentUser = this.authorizationService.currentUser;
    const labelText = categoryField.linkedField.localizedLabels
      .find(label => label.language.languageCode === currentUser.primaryLanguage.languageCode)?.text;

    return labelText ? labelText : categoryField.name;
  }

  requiredFieldPresent(fieldName: string): boolean {
    const value = this.form.controls[fieldName].value;
    return value !== null && value.length > 0;
  }

  save() {
    if (this.form.valid) {
      this.openReviewService.updateChatClassification(this._chatClassification, this._openReview.reviewType).subscribe((response) => {
        this.alertifyService.success('Saved chat classification.');
        this._chatClassification = response;
        this.validatorEvent.emit(true);
      });
    } else {
      const errors = [];
      for (const name in this.form.controls) {
        if (this.form.controls[name].invalid) {
          errors.push(name);
        }
      }
      this.alertifyService.warning(`Classification has errors. (${errors})`);
    }
  }
}
