<section>
    <div class="flex flex-column w100 h100" style="position: relative">
        <mat-form-field class="w100" appearance="outline">
                <textarea matInput placeholder={{placeholderText}}
                          [id]="id"
                          [style.height.em]="5"
                          (keydown.enter)="$event.preventDefault(); sendComment()"
                          [readonly]="disabled"
                          [formControl]="message"></textarea>

            <span (click)="$event.stopImmediatePropagation()">
                <app-simple-messaging-history [scrollable]="scrollable" [messages]="messages"></app-simple-messaging-history>
            </span>
        </mat-form-field>

        <button class="send-button" mat-mini-fab (click)="sendComment()" [disabled]="!hasMessage()">
            <i class="far fa-paper-plane"></i>
        </button>
    </div>
</section>

