<div *ngIf="dataSource$ | async as dataSource">
    <section class="row" [hidden]="errorMessage">
        <mat-card class="full-card-catagory limited-larger">
            <mat-card-header>
                <mat-card-title>Chats by category <span class="subtitle">{{subtitle}}</span></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="dataLineChart" type="line" [data]="dataLineChart" [options]="optionsLineChart"
                         height="86%"></p-chart>
            </mat-card-content>
        </mat-card>
    </section>
    <section class="row" [hidden]="errorMessage">
        <mat-card class="half-card left">
            <mat-card-header>
                <mat-card-title>{{titlePieChart}}<span class="subtitle">pie chart</span></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="dataPieChart" type="pie" [data]="dataPieChart" [options]="optionsPieChart"
                         height="100%" ></p-chart>
            </mat-card-content>
        </mat-card>
    </section>
</div>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>

