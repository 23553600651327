<div *ngIf="statisticsDataSource">
    <section class="graph">
        <mat-table #table [dataSource]="statisticsDataSource">
            <ng-container matColumnDef="key">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.key}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="value1">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.value1}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="value2">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.value2}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </section>
</div>
