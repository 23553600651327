<button #button mat-stroked-button class="small-button"
        (click)="click($event); $event.stopPropagation();"
        [title]="title ? title : ''"
        [style.color]="_color"
        [disabled]="disabled"
        [style.border]="border"
        [style.font-weight]="bold ? 'bolder' : 'normal'"
        [style.background-color]="disabled ? disabledColor : backgroundColor">
    <i *ngIf="icon" [ngClass]="icon" (click)="click($event); $event.stopPropagation()"></i>
    <span *ngIf="label">{{label}}</span>
</button>
