<div>
    <div class="buttons" style="padding-bottom: 1em">

        <app-small-button (onClick)="save()"
                          [color]="'white'"
                          [disabled]="form.pristine"
                          [bold]="true"
                          [label]="'Save'"></app-small-button>
        <app-small-button (onClick)="clear()"
                          [color]="'white'"
                          [disabled]="(correction.processStage !== 'TO_SUPERVISOR')"
                          [bold]="true"
                          [label]="'Clear'"></app-small-button>

    </div>
    <div [formGroup]="form">
        <div *ngFor="let clsField of correction.classificationFieldValueDto"
             [ngSwitch]="clsField.fieldDefinition.type">
            <mat-form-field style="width: 100%" *ngSwitchCase="'SELECT'" [ngClass]="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES') && !requiredFieldPresent(clsField.name) ? 'field-required' : ''">
                <mat-select formControlName="{{clsField.name}}" value="{{clsField.value}}"
                            placeholder="{{clsField.name}}" required="{{(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES')}}">
                    <mat-option *ngFor="let option of clsField.fieldDefinition.options"
                                [value]="option">{{option}}</mat-option>
                </mat-select>
                <mat-error *ngIf="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES')">{{clsField.name}} is required</mat-error>
            </mat-form-field>
            <div style="width: 100%; padding-bottom: 1em" *ngSwitchCase="'CHECKBOX'" [ngClass]="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES') && !requiredBoxChecked(clsField.name) ? 'field-required' : ''">
                <mat-checkbox
                    formControlName="{{clsField.name}}"
                    required="{{(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES')}}">{{clsField.name}} <span *ngIf="clsField.fieldDefinition.required"> *</span></mat-checkbox>
                <mat-error style="font-size: 10.5px"
                           *ngIf="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES') && !requiredBoxChecked(clsField.name) && this.checkboxTouched">{{clsField.name}}
                    is required
                </mat-error>
            </div>
            <mat-form-field style="width: 100%" *ngSwitchDefault [ngClass]="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES') && !requiredFieldPresent(clsField.name) ? 'field-required' : ''">
                <input matInput formControlName="{{clsField.name}}"  placeholder="{{ getClassificationFieldLanguageByUserLanguage(clsField) }}"
                       required="{{(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES')}}">
                <mat-error *ngIf="(clsField.fieldDefinition.required || clsField.fieldDefinition.requirementSetting === 'YES')">
                    {{ getClassificationFieldLanguageByUserLanguage(clsField) }} is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
