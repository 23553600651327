<section>
    <div class="review-messages" #historyMessages>
        <ul *ngFor="let message of staticMessages">
            <li>
                <div>{{message.sentBy}}: {{message.message}}</div>
            </li>
        </ul>
        <ul *ngFor="let message of reviewMessages | genericFunctionFilter: {sortFn: sortFn}">
            <li [className]="getMessageClass(message.sentBy?.userName)">
                <div *ngFor="let line of getMessageLines(message); let last = last">
                    <span>{{line}}</span>
                    <img *ngIf="isImg(message)" alt="good" style="display:inline-block; vertical-align: middle;" width="30px"
                                  height="30px" class="center-image" src={{getImgSource(message)}}/>
                    <span *ngIf="!last"><br/></span>
                </div>
                <span *ngIf="message.visibleToServedBy !== undefined && !message.visibleToServedBy" class="not-visible-icon" title="Message not visible to operator">
                    <i class="fas fa-eye-slash"></i>
                </span>
            </li>
        </ul>
    </div>
</section>
