<div style="font-size: 14px">
    <a *ngIf="isTeamleadOrBetter" routerLink="overview" routerLinkActive="active" >
        Overview
    </a>
    <a routerLink="chatarchive" routerLinkActive="active" >
        Chat Archive
    </a>

    <mat-divider></mat-divider>

    <a *ngIf="!isClient" routerLink="review" routerLinkActive="active">
        Review
        <div class="badge-count" *ngIf="getCountByView('REVIEW') > 0" [hidden]="isActiveRoute('review')">
            {{ getCountByView('REVIEW') }}
        </div>
    </a>

    <a  routerLink="tickets/feedback" routerLinkActive="active">
        Feedback
        <div class="badge-count" *ngIf="getCountByView('FEEDBACK') > 0" [hidden]="isActiveRoute('tickets/feedback')">
            {{ getCountByView('FEEDBACK') }}
        </div>
    </a>

    <a *ngIf="isLCDOrBetter || hasAction('TOPCHAT')" routerLink="tickets/topchat" routerLinkActive="active">
        Top Chat
        <div class="badge-count" *ngIf="getCountByView('TOPCHAT') > 0" [hidden]="isActiveRoute('tickets/topchat')">
            {{ getCountByView('TOPCHAT') }}
        </div>
    </a>

    <a *ngIf="!isClient && (hasPermission('FAQS', 'Faqs') || hasAction('FAQS'))"
       routerLink="tickets/faqs" routerLinkActive="active">
        Faqs
        <div class="badge-count" *ngIf="getCountByView('FAQS') > 0" [hidden]="isActiveRoute('tickets/faqs')">
            {{ getCountByView('FAQS') }}
        </div>
    </a>

    <a *ngIf="isClient" routerLink="feedback/faqs" routerLinkActive="active" style="padding-left: 13%; font-size: 13px">
        Faqs
        <div class="badge-count" *ngIf="getCountByView('FAQS') > 0" [hidden]="isActiveRoute('feedback/faqs')">
            {{ getCountByView('FAQS') }}
        </div>
    </a>

    <a *ngIf="hasPermission('CORRECTIONS', 'Corrections') || hasAction('CORRECTIONS')"
       routerLink="tickets/corrections" routerLinkActive="active">
        Corrections
        <div class="badge-count" *ngIf="getCountByView('CORRECTIONS') > 0" [hidden]="isActiveRoute('tickets/corrections')">
            {{ getCountByView('CORRECTIONS') }}
        </div>
    </a>

    <a *ngIf="hasPermission('OPTIMIZATION', 'Optimization') || hasAction('OPTIMIZATION')"
       routerLink="tickets/optimization" routerLinkActive="active">
        Optimization
        <div class="badge-count" *ngIf="getCountByView('OPTIMIZATION') > 0" [hidden]="isActiveRoute('tickets/optimization')">
            {{ getCountByView('OPTIMIZATION') }}
        </div>
    </a>

    <a *ngIf="isLCDOrBetter || hasAction('ESCALATE')" routerLink="tickets/escalate" routerLinkActive="active">
        Escalate
        <div class="badge-count" *ngIf="getCountByView('ESCALATE') > 0" [hidden]="isActiveRoute('tickets/escalate')">
            {{ getCountByView('ESCALATE') }}
        </div>
    </a>

    <a *ngIf="hasPermission('CLIENT_FEEDBACK', 'Client Feedback')  || hasAction('CLIENT_FEEDBACK')" routerLink="tickets/client-feedback" routerLinkActive="active">
        Client feeback
        <div class="badge-count" *ngIf="getCountByView('CLIENT_FEEDBACK') > 0" [hidden]="isActiveRoute('tickets/client-feedback')">
            {{ getCountByView('CLIENT_FEEDBACK') }}
        </div>
    </a>

    <a routerLink="updates" routerLinkActive="active">
        Updates
        <div class="badge-count" *ngIf="getCountByView('UPDATES') > 0" [hidden]="isActiveRoute('tickets/updates')">
            {{ getCountByView('UPDATES') }}
        </div>
    </a>

    <mat-divider></mat-divider>

    <a *ngIf="hasPermission('HQREVIEW', 'HQ Review')" routerLink="hqreview" routerLinkActive="active">
        HQ Review
    </a>
    <a *ngIf="hasPermission('LANGREVIEW', 'Language Review')" routerLink="langreview" routerLinkActive="active">
        Language Review
    </a>
    <a *ngIf="hasPermission('QUEUE_ERROR', 'Queue Errors')" routerLink="queueErrors" routerLinkActive="active">
        Queue Errors
    </a>
</div>
