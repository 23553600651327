<section class="row" [hidden]="conversationUid || !hasData">
    <mat-card class="full-card">
        <mat-card-header >
            <mat-card-title>
                Please Review:
            </mat-card-title>
        </mat-card-header>
        <mat-card-content >
            <mat-table class="archive-table" [dataSource]="tableDataSource" matSort [matSortActive]="conversationUid"
                       [matSortDirection]="'desc'" [matSortDisableClear]="true">
                <ng-container matColumnDef="startDate">
                    <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.startDate | date : 'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.visitorRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.visitorRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.clientRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.clientRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationUid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button"  style="color: #141464; font-weight: bold;"
                                (click)="setConversationUid(element.conversationUid)">
                            {{element.conversationUid}}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="classificationCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.classificationCategory}}</mat-cell>
                </ng-container>
                <ng-container *ngIf="isTeamLead" matColumnDef="servedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Served By</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.servedBy}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [length]=tableDataLength
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</section>
<app-review-details style="width: 100%;"
                    *ngIf="conversationUid"
                    [conversationUid]="conversationUid"
                    [list]="true"
                    (getNextEvent)="getNextReview($event)"
                    (unloadSessionDetailsEvent)="unsetConversationUid()"
                    (reviewMessageEvent)="processReviewMessageEvent()"
                    [review]="getReview()">
</app-review-details>

<mat-card class="full-card" *ngIf="!hasData">
    <mat-card-header>
        <mat-card-title>

        </mat-card-title>
    </mat-card-header>
    <mat-card-content><span>Nothing for you to review: well done!</span></mat-card-content>
</mat-card>
