<div style="font-size: 13px" [hidden]=isHidden>
    <br>
    <mat-divider></mat-divider>
    <br>
    <form [formGroup]="supportFilterForm">
        <mat-form-field>
            <mat-select formControlName="categories"
                        placeholder="Category"
                        multiple
                        matTooltip="{{toolTip('categoryList')}}"
                        matTooltipPosition="after"
                        matTooltipClass="tooltip-multiline">
                <mat-select-trigger>
                    {{categoryList && categoryList.length > 0 ? categoryList[0].substr(0, 17) : ''}}
                    <span class="limit-width" *ngIf="categoryList && categoryList.length > 1">
                    (+{{categoryList.length - 1}} )
                </span>
                    <button mat-icon-button class="clear"
                            (click)="clearThisForm($event, 'categoryList', 'categories');" type="button">
                        <i class="fa fa-times align-top"></i>
                    </button>
                </mat-select-trigger>
                <mat-option *ngFor="let category of categories" [value]="category">
                    {{category}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="departments"
                        placeholder="{{'Department'}}"
                        multiple
                        matTooltip="{{departmentToolTip()}}"
                        matTooltipPosition="after"
                        matTooltipClass="tooltip-multiline">
                <mat-select-trigger>
                    {{departmentList && departmentList.length > 0 ? departmentList[0].substr(0, 17) : ''}}
                    <span class="limit-width" *ngIf="departmentList && departmentList.length > 1">
                    (+{{departmentList.length - 1}} )
                </span>
                    <button mat-icon-button class="clear"
                            (click)="clearThisForm($event, 'departmentList', 'departments');" type="button">
                        <i class="material-icons align-top">
                            clear
                        </i>
                    </button>
                </mat-select-trigger>
                <mat-option *ngFor="let department of departments" [value]="department">
                    {{department}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <div>
            <label>Visitor Rating: </label>
            <mat-button-toggle-group multiple #group="matButtonToggleGroup"
                                     class="filter-button" ngDefaultControl>
                <mat-button-toggle (change)="clicked(10, $event, 'visitorRatingList')">
                    <i class="far fa-laugh-beam" [ngStyle]="{'color': visitorHappinessColor(10)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(6, $event, 'visitorRatingList')">
                    <i class="far fa-meh" [ngStyle]="{'color': visitorHappinessColor(6)}" style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(2, $event, 'visitorRatingList')">
                    <i class="far fa-frown" [ngStyle]="{'color': visitorHappinessColor(2)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div>
            <label>Client Rating: </label>
            <mat-button-toggle-group multiple #group="matButtonToggleGroup" type=checkbox
                                     class="filter-button" ngDefaultControl>
                <mat-button-toggle (change)="clicked(10, $event, 'clientRatingList')">
                    <i class="far fa-laugh-beam" [ngStyle]="{'color': clientHappinessColor(10)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(6, $event, 'clientRatingList')">
                    <i class="far fa-meh" [ngStyle]="{'color': clientHappinessColor(6)}" style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(2, $event, 'clientRatingList')">
                    <i class="far fa-frown" [ngStyle]="{'color': clientHappinessColor(2)}" style="font-size: 18px;"></i>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>Bconnect Rating: </label>
            <mat-button-toggle-group multiple #group="matButtonToggleGroup" type=checkbox
                                     class="filter-button" ngDefaultControl>
                <mat-button-toggle (change)="clicked(10, $event, 'bConnectRatingList')">
                    <i class="far fa-laugh-beam" [ngStyle]="{'color': bConnectRatingListHappinessColor(10)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(6, $event, 'bConnectRatingList')">
                    <i class="far fa-meh" [ngStyle]="{'color': bConnectRatingListHappinessColor(6)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
                <mat-button-toggle (change)="clicked(2, $event, 'bConnectRatingList')">
                    <i class="far fa-frown" [ngStyle]="{'color': bConnectRatingListHappinessColor(2)}"
                       style="font-size: 18px;"></i>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div>
            <label>Client Reviewed: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('clientRemark', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('clientRemark', true), 'color': getFontColor('clientRemark', true)}">
                    yes
                </button>
                <button (click)="toggle('clientRemark', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('clientRemark', false), 'color': getFontColor('clientRemark', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient && isOperator">
            <label>Teamlead chats: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('showTlChats', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('showTlChats', true), 'color': getFontColor('showTlChats', true)}">
                    yes
                </button>
                <button (click)="toggle('showTlChats', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('showTlChats', false), 'color': getFontColor('showTlChats', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>Bconnect Feedback: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('bconnectRemark', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('bconnectRemark', true), 'color': getFontColor('bconnectRemark', true)}">
                    yes
                </button>
                <button (click)="toggle('bconnectRemark', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('bconnectRemark', false), 'color': getFontColor('bconnectRemark', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>Operator Comments: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('operatorFeedback', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em',
                        'backgroundColor': getBackgroundColor('operatorFeedback', true),
                        'color': getFontColor('operatorFeedback', true)}">
                    yes
                </button>
                <button (click)="toggle('operatorFeedback', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em',
                        'backgroundColor': getBackgroundColor('operatorFeedback', false),
                        'color': getFontColor('operatorFeedback', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isOperator && !isClient">
            <div *ngIf="!isClient">
                <label>Correction: </label>
                <mat-button-toggle-group class="filter-button">
                    <button (click)="toggle('correction', true)"
                            [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('correction', true), 'color': getFontColor('correction', true)}">
                        yes
                    </button>
                    <button (click)="toggle('correction', false)"
                            [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('correction', false), 'color': getFontColor('correction', false)}">
                        no
                    </button>
                </mat-button-toggle-group>
            </div>

        </div>
        <div *ngIf="!isClient">
            <label>Top Chat: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('markedTopChat', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('markedTopChat', true), 'color': getFontColor('markedTopChat', true)}">
                    yes
                </button>
                <button (click)="toggle('markedTopChat', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('markedTopChat', false), 'color': getFontColor('markedTopChat', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>HQ Review: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('hqReview', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('hqReview', true), 'color': getFontColor('hqReview', true)}">
                    yes
                </button>
                <button (click)="toggle('hqReview', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('hqReview', false), 'color': getFontColor('hqReview', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>Closed Reviews: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('closedReviews', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('closedReviews', true), 'color': getFontColor('closedReviews', true)}">
                    yes
                </button>
                <button (click)="toggle('closedReviews', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('closedReviews', false), 'color': getFontColor('closedReviews', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!isClient">
            <label>Language Review: </label>
            <mat-button-toggle-group class="filter-button">
                <button (click)="toggle('languageReview', true)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('languageReview', true), 'color': getFontColor('languageReview', true)}">
                    yes
                </button>
                <button (click)="toggle('languageReview', false)"
                        [ngStyle]="{'height':'3em', 'width':'3em','backgroundColor': getBackgroundColor('languageReview', false), 'color': getFontColor('languageReview', false)}">
                    no
                </button>
            </mat-button-toggle-group>
        </div>

        <div *ngIf="!isOperator && !isClient">
            <mat-form-field>
                <mat-select
                    formControlName="qualityChecks"
                    multiple
                    matTooltip="{{toolTip('qualityCheckList')}}"
                    placeholder="Quality Checks"
                    matTooltipPosition="after"
                    matTooltipClass="tooltip-multiline">
                    <mat-select-trigger>
                        {{qualityCheckList && qualityCheckList.length > 0 ? qualityCheckList[0].substr(0, 17) : ''}}
                        <span class="limit-width" *ngIf="qualityCheckList && qualityCheckList.length > 1">
                        (+{{qualityCheckList.length - 1}} )
                        </span>
                        <button mat-icon-button class="clear"
                                (click)="clearQualityCheckList($event);" type="button">
                            <i class="fa fa-times align-top"></i>
                        </button>
                    </mat-select-trigger>
                    <mat-option *ngFor="let quality of qualities" (click)="toggleQualityValue(quality, $event)"
                                [value]="quality">
                        {{quality}} <i [class]="getQualityValue(quality)"></i>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select
                    formControlName="operatorErrors"
                    placeholder="Operator Errors"
                    multiple
                    matTooltip="{{toolTip('operatorErrorList')}}"
                    matTooltipPosition="after"
                    matTooltipClass="tooltip-multiline">
                    <mat-select-trigger>
                        {{operatorErrorList && operatorErrorList.length > 0 ? operatorErrorList[0].substr(0, 17) : ''}}
                        <span class="limit-width" *ngIf="operatorErrorList && operatorErrorList.length > 1">
                    (+{{operatorErrorList.length - 1}} )
                </span>
                        <button mat-icon-button class="clear"
                                (click)="clearThisForm($event, 'operatorErrorList', 'operatorErrors');" type="button">
                            <i class="fa fa-times align-top"></i>
                        </button>
                    </mat-select-trigger>
                    <mat-option *ngFor="let operatorError of operatorErrors" [value]="operatorError">
                        {{operatorError}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

</div>

