import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { of ,  Observable } from 'rxjs';
import { Account } from '../model/account.model';
import { ChatsByClassification } from '../model/chats-by-classification.model';
import { CoreParameters } from '../model/core-parameters.model';
import { Domain } from '../model/domain.model';
import { FinanceData } from '../model/finance-data.model';
import { Happiness } from '../model/happiness.model';
import { LabeledLabelNumericData } from '../model/labeled-label-numeric-data';
import { LabeledNumericData } from '../model/labeled-numeric-data';
import { LeaderBoard } from '../model/leader-board.model';
import { OnlineLoggingRow } from '../model/online-logging.model';
import { OperatorScoreRowsWrapper } from '../model/operator-score-rows-wrapper-model';
import { Scores } from '../model/scores.model';
import { StartPathStatistics } from '../model/start-path-statistics.model';
import { TeamDetails } from '../model/team-details.model';
import { TeamMembership } from '../model/team-member.model';
import { TeamleadHomeScoresData } from '../model/teamlead-home-scores-data';
import { WordCloud } from '../model/word-cloud.model';
import { WordCount } from '../model/word-count.model';
import { OperatorFunnelDataWrapper } from './funnel-operator/funnel-operator.component';
import { FunnelDataWrapper } from './funnel/funnel.component';
import {AutomatedResponseFunnelData} from "../model/automated-response-funnel-data.model";

@Injectable()
export class GraphService {

  constructor(private http: HttpClient) {
  }

  getLabel(coreParameters: CoreParameters): string {
    let label: string = '';
    if (coreParameters.operators && coreParameters.operators.length > 0) {
      label += coreParameters.operators.map((o) => o.userName).join(', ');
    } else if (coreParameters.team) {
      label += coreParameters.team.name;
    }

    if (label.length > 0) {
      label += ' for ';
    }

    if (coreParameters.domain) {
      label += coreParameters.domain.name;
    } else if (coreParameters.accounts) {
      label += coreParameters.accounts.map((a: Account) => a.name).join(', ') + ' ';
    } else {
      label += 'all accounts ';
    }

    return label;
  }

  getParams(coreParameters: CoreParameters): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (coreParameters.accounts) {
    httpParams = httpParams.append('accounts', `${coreParameters.accounts.map((a: Account) => a.id)}`);
  }
    httpParams = httpParams.append('start', `${moment(coreParameters.startDate).format('YYYYMMDD')}`);
    httpParams = httpParams.append('end', `${moment(coreParameters.endDate).format('YYYYMMDD')}`);
    httpParams = httpParams.append('summarizeBy', `${coreParameters.summarizeBy}`);
    httpParams = httpParams.append('showHybrids', `${coreParameters.showHybrids}`);
    if (coreParameters.domain) {
      httpParams = httpParams.append('domain', `${coreParameters.domain.id}`);
    }
    if (coreParameters.team) {
      httpParams = httpParams.append('team', `${coreParameters.team.id}`);
    }
    if (coreParameters.operators) {
      httpParams = httpParams.append('operators', `${coreParameters.operators.map((t: TeamMembership) => t.userId)}`);
    }

    if (coreParameters.channels) {
      httpParams = httpParams.append('channels', `${coreParameters.channels}`);
    }
    return httpParams;
  }

  getAccounts(system: 'SS' | 'DB'): Observable<Account[]> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('system', system);
    return this.http.get<Account[]>('/api/accounts', {params: httpParams});
  }

  getDomains(account: number): Observable<Domain[]> {
    if (account) {
      return this.http.get<Domain[]>(`/api/accounts/${account}/domains`);
    } else {
      return of([]);
    }
  }

  getWordcloud(coreParameters: CoreParameters): Observable<WordCloud[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<WordCloud[]>('/api/nlp/wordcloud', {params: httpParams});
    // return new Promise((resolve, reject) => {
    //   .toPromise().then((res) => {
    //     resolve(res);
    //   }).catch((reason) => {
    //     reject(reason);
    //   });
    // });
  }

  getTeams(): Observable<TeamDetails[]> {
    return this.http.get<TeamDetails[]>('/api/teams');
  }

  getAllMemberships(): Observable<TeamMembership[]> {
    return this.http.get<TeamMembership[]>('/api/users/allmemberships');
  }

  getChatsForList(userId: number): Observable<Account[]> {
    return this.http.get<Account[]>(`/api/accounts/chatsfor/${userId}`);
  }

  getChats(coreParameters: CoreParameters): Observable<ChatsByClassification> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<ChatsByClassification>('/api/sessiondata/byclassification', { params: httpParams });
  }

  getVisitorHappiness(coreParameters: CoreParameters): Observable<Happiness> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<Happiness>('/api/happiness/visitor', { params: httpParams });
  }

  getTotalRating(coreParameters: CoreParameters): Observable<Happiness> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<Happiness>('/api/happiness/total', {params: httpParams});
  }

  getVisitorHappinessCategory(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/happiness/visitorbyclassification', { params: httpParams });
  }

  getClientHappinessCategory(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/happiness/clientbyclassification', { params: httpParams });
  }

  getClientHappiness(coreParameters: CoreParameters): Observable<Happiness> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<Happiness>('/api/happiness/client', { params: httpParams });
  }

  getStartPath(coreParameters: CoreParameters): Observable<StartPathStatistics[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<StartPathStatistics[]>('/api/startpath/stats', { params: httpParams });
  }

  getAvgChatsPerHour(coreParameters: CoreParameters): Observable<LabeledNumericData<number>> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledNumericData<number>>('/api/sessiondata/chatsperhour/average', { params: httpParams });
  }

  getAvgPickupTime(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/pickuptime/average', { params: httpParams });
  }

  getMissedChats(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/missedchats/total', { params: httpParams });
  }

  getAvgDuration(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/duration/average', { params: httpParams });
  }
  getEuroPerChatAndHour(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/euro', { params: httpParams });
  }

  getChatsPerOnlineHour(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/chatsPerOnlineHour', { params: httpParams });
  }

  getAvgResponseTime(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/sessiondata/responsetime/average', { params: httpParams });
  }

  getChatsPerHour(coreParameters: CoreParameters): Observable<LabeledNumericData<number>> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledNumericData<number>>('/api/sessiondata/chatsperhour/distribution', { params: httpParams });
  }

  getChatsPerDay(coreParameters: CoreParameters): Observable<LabeledNumericData<number>> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledNumericData<number>>('/api/sessiondata/chatsperday/distribution', { params: httpParams });
  }

  getFunnelData(coreParameters: CoreParameters): Observable<FunnelDataWrapper> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<FunnelDataWrapper>('/api/funnel/full', { params: httpParams });
  }
  getOperatorFunnelData(coreParameters: CoreParameters): Observable<OperatorFunnelDataWrapper> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<OperatorFunnelDataWrapper>('/api/funnel/operator', { params: httpParams });
  }

  getLeadsInPeriod(coreParameters: CoreParameters): Observable<number> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<number>('/api/sessiondata/leads', { params: httpParams });
  }

  getOperatorScores(coreParameters: CoreParameters): Observable<Scores> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<Scores>('/api/dashboard/operator', { params: httpParams });
  }

  getAccountLeaderBoardData(coreParameters: CoreParameters): Observable<LeaderBoard[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LeaderBoard[]>('/api/leaderboard/account', { params: httpParams });
  }

  getAdminHomeData(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('api/adminhome/classifications', { params: httpParams });
  }

  getOperatorLeaderBoardData(coreParameters: CoreParameters): Observable<OperatorScoreRowsWrapper> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<OperatorScoreRowsWrapper>('/api/operatorleaderboard/score', { params: httpParams });
  }

  getFinanceData(coreParameters: CoreParameters): Observable<FinanceData[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<FinanceData[]>('/api/finance/data', { params: httpParams });
  }

  getFinanceDataCsv(coreParameters: CoreParameters) {
    const httpParams = this.getParams(coreParameters);
    return this.http.get('/api/finance/data/csv', {responseType: 'text', params: httpParams}).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'text/csv'});
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'FinanceData_' +
          coreParameters.startDate.split('T')[0] +
          '_' + coreParameters.endDate.split('T')[0] + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    );
  }

  getHappinessScores(coreParameters: CoreParameters): Observable<LabeledLabelNumericData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<LabeledLabelNumericData>('/api/adminhome/scores', { params: httpParams });
  }

  getTLScores(coreParameters: CoreParameters): Observable<TeamleadHomeScoresData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<TeamleadHomeScoresData>('/api/tlhome/scores', { params: httpParams });
  }

  getCOScores(coreParameters: CoreParameters): Observable<TeamleadHomeScoresData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<TeamleadHomeScoresData>('/api/cohome/scores', { params: httpParams });
  }

  getOnlineLogging(coreParameters: CoreParameters): Observable<OnlineLoggingRow[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<OnlineLoggingRow[]>('/api/online-logging/list', {params: httpParams});
  }

  getAutomatedResponseFunnel(coreParameters: CoreParameters): Observable<AutomatedResponseFunnelData> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<AutomatedResponseFunnelData>('/api/automated-response/funnel', {params: httpParams});
  }
}
