<div class="spinner-container">
    <span *ngIf="saving">
        <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
    </span>
</div>
<div>
    <mat-table class="review-table" [dataSource]="tableDataSource">
        <ng-container matColumnDef="buttonLeft">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-button [ngClass]="{'mat-raised-button': getQualityValues(element.rowLeft) === 'none',
                                                    'table-button': true,
                                                    'table-button-false': getQualityValues(element.rowLeft) === 'false',
                                                    'table-button-true': getQualityValues(element.rowLeft) === 'true'}"
                        (click)="toggleQuality(element.rowLeft)"
                        [disabled]="disabled">
                    {{element.rowLeft.name}}
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="buttonCenter">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-button *ngIf = "element.rowCenter" [ngClass]="{'mat-raised-button': getQualityValues(element.rowCenter) === 'none',
                                                    'table-button': true,
                                                    'table-button-false': getQualityValues(element.rowCenter) === 'false',
                                                    'table-button-true': getQualityValues(element.rowCenter) === 'true'}"
                        (click)="toggleQuality(element.rowCenter)"
                        [disabled]="disabled">
                    {{element.rowCenter.name}}
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="buttonRight">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-button *ngIf = "element.rowRight" [ngClass]="{'mat-raised-button': getQualityValues(element.rowRight) === 'none',
                                                    'table-button': true,
                                                    'table-button-false': getQualityValues(element.rowRight) === 'false',
                                                    'table-button-true': getQualityValues(element.rowRight) === 'true'}"
                        (click)="toggleQuality(element.rowRight)"
                        [disabled]="disabled">
                    {{element.rowRight.name}}
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" style="justify-content: center; display: flex"></mat-row>
    </mat-table>

</div>
