<!--<header>-->
<!--    <div id="logo">-->
<!--        <a routerLink="./">-->
<!--            <img alt="Logo" class="logo" src="assets/img/bconnect.png"/>-->
<!--        </a>-->
<!--    </div>-->
<!--    <div class="application-links" *ngIf="authenticated">-->
<!--        <a *ngIf = "canSeeCR" href="https://chat.bconnectnederland.nl" target="_blank"><button class="button" mat-button>Chat Rocket</button></a>-->
<!--        <a *ngIf = "canSeeKB" href="https://kb.bconnectnederland.nl" target="_blank"><button class="button" mat-button>Kennisbank</button></a>-->
<!--    </div>-->
<!--    <app-logout-button></app-logout-button>-->
<!--</header>-->

<header>
    <div class="header">
        <div id="logo">
            <a class="navbar-brand" routerLink="" style="height: 100%; display: flex;">
                <img alt="Logo" class="logo" src="assets/img/Bconnect_Logo_Full_White.svg"/>
            </a>
        </div>
        <div *ngIf="authenticated" class="button-bar" style="display: flex; flex-direction: row; align-items: baseline">
            <p><a *ngIf="canSeeCR" style="margin-right: 10px; text-decoration: none; color: white;" class="clickable white" href="https://chat.bconnectnederland.nl">Chat Rocket</a></p>
            <p><a *ngIf="canSeeKB" style="margin-left: 10px; text-decoration: none; color: white;" class="clickable white" href="https://kb.bconnect.chat">Knowledgebase</a></p>
            <p style="margin-left: 10px; margin-right: 10px"><app-logout-button></app-logout-button></p>
        </div>
    </div>
</header>
