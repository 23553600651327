import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from '../../../core/authorization.service';
import { ColorService } from '../../../graph/graph-services/color.service';
import { ClassificationInfo } from '../../../model/support/classification-info';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import { SessionChatInfoService } from '../../service/session-chat-info.service';
import { SupportService } from '../../service/support.service';
import { VisitorRemarkViewComponent } from '../visitor-remark-view/visitor-remark-view.component';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
})
export class SessionDetailsComponent implements OnChanges {
  @ViewChild('chatMessages', {static: false})
  private chatMessages: ElementRef;
  @Input()
  public conversationUid: string;
  @Input()
  public list: boolean = true;
  @Output()
  public getNextEvent = new EventEmitter<number>();
  @Output()
  public unloadConversationDetailsEvent = new EventEmitter<string>();
  @Output()
  public loadedSession = new EventEmitter<SessionChatInfo>();
  public datePiper: DatePipe;

  constructor(
    private supportService: SupportService,
    private colorService: ColorService,
    private datePipe: DatePipe,
    private sessionChatInfoService: SessionChatInfoService,
    private authorizationService: AuthorizationService,
    public dialog: MatDialog
  ) {
    this.datePiper = datePipe;
  }

  private loadChatSession(conversationUid: string): void {
    this.sessionChatInfoService.sessionChatInfo = undefined;

    this.sessionChatInfoService.setConversation(conversationUid).pipe(
      tap((s: SessionChatInfo) => {
        if (s) {
          this.loadedSession.emit(s);
        }
      }),
    ).subscribe();
  }

  private scrollMessagesToTop(): void {
    if (this.chatMessages) {
      this.chatMessages.nativeElement.scrollTo(0, 0);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.loadChatSession(this.conversationUid);
    if ((changes.conversationUid.previousValue !== changes.conversationUid.currentValue) && !changes.conversationUid.firstChange) {
      this.scrollMessagesToTop();
    }
  }

  public get sessionChatInfo(): SessionChatInfo {
    return this.sessionChatInfoService.sessionChatInfo;
  }

  public get classificationFields(): ClassificationInfo[] {
    return this.sessionChatInfo.sessionInfo.classificationFields;
  }

  public get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  public goBackToOverview(): void {
    this.unloadConversationDetailsEvent.emit(this.conversationUid);
  }

  public getNextReview($event): void {
    this.getNextEvent.emit($event);
  }

  public getImgSource(value: number): string {
    return this.supportService.getImgSource(value);
  }

  public getFieldNameBasedOffUserLanguage(classificationInfo: ClassificationInfo): string {
    const currentUser = this.authorizationService.currentUser;
    const labelText = classificationInfo.fieldDefinition?.linkedField.localizedLabels
      .find(label => label.language.languageCode === currentUser.primaryLanguage.languageCode)?.text;

    return labelText ? labelText : classificationInfo.name;
  }

  public openVisitorCommentDialog(): void {
    this.dialog.open(VisitorRemarkViewComponent, {
      data: this.sessionChatInfoService.sessionChatInfo.sessionInfo.visitorComments ?
        this.sessionChatInfoService.sessionChatInfo.sessionInfo.visitorComments : 'No visitor remark available',
    });
  }
}
