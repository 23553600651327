import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ColorService } from '../../../graph/graph-services/color.service';
import { AlertifyDto } from '../../../model/support/alertify-dto';
import { ReviewMessage } from '../../../model/support/review-message.model';
import { Review } from '../../../model/support/review/review.model';
import { SessionChatInfo } from '../../../model/support/session-chat-info.model';
import {
  GenericInputPromptDialogComponent
} from '../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import { AlertifyService } from '../../service/alertify.service';
import { MessageService } from '../../service/message.service';
import { ReviewService } from '../../service/review.service';
import { SessionChatInfoService } from '../../service/session-chat-info.service';
import {SimplerComment} from "../../../model/support/simpler-comment.model";

@Component({
  selector: 'app-feedback-client-feedback',
  templateUrl: './feedback-client-feedback.component.html',
})
export class FeedbackClientFeedbackComponent implements OnChanges, OnDestroy {

  _sessionChatInfo: SessionChatInfo;
  @Input()
  review: Review;
  oldRating: number;
  updateChatMessageDate: Date = new Date(Date.now());
  reviewMessages: ReviewMessage[];
  subscriptions: Subscription[] = [];
  staticMessages: SimplerComment[];

  constructor(private alertifyService: AlertifyService,
              private sessionChatInfoService: SessionChatInfoService,
              private colorService: ColorService,
              private reviewService: ReviewService,
              private messageService: MessageService,
              private dialog: MatDialog) {
  }

  @Input()
  set sessionChatInfo(sessionChatInfo: SessionChatInfo) {
    this._sessionChatInfo = sessionChatInfo;
    this.messageService.getMessagesByConversationUid(sessionChatInfo.sessionInfo.conversationUid, 'clientcomment').subscribe((messages) => {
      this.reviewMessages = messages;

      if (sessionChatInfo.sessionInfo.clientComments && sessionChatInfo.sessionInfo.clientComments.length > 0) {
        this.staticMessages = [{message: sessionChatInfo.sessionInfo.clientComments, sentBy: 'Rating comment'}];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.oldRating = this._sessionChatInfo.sessionInfo.clientRating;
  }

  public setHappiness(rating: number) {

    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        html: '<p>Thank you for your rating. Do you have any additional remarks?</p>',
        optional: true,
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed().subscribe((response: {message: string, noComment: boolean}) => {

      if (response.message || response.noComment) {
        this.reviewService.updateClientRating(this.review.id, rating, response.message).subscribe((alertify: AlertifyDto) => {
          this.alertifyService[alertify.type](alertify.message);
          this.updateChatMessageDate = new Date(Date.now());

          this._sessionChatInfo.sessionInfo.clientRating = rating;
          // if (this.review.closedAt !== null) {
          //   this.subscriptions.push(this.reviewService.reopenReview(this.review.id).subscribe());
          // }
        });
      }
    });
  }

  public happinessColor(n: number): string {
    if (this._sessionChatInfo.sessionInfo.clientRating === n) {
      return this.colorService.getColorByGrade(n);
    } else {
      return 'gray';
    }
  }

  sendClientCommentReviewMessage(reviewMessage: ReviewMessage) {
    const sub = this.messageService.sendReviewMessage(reviewMessage).subscribe((result) => {
      this.reviewMessages.push(result);
      if (this.review.closedAt !== null) {
        this.subscriptions.push(this.reviewService.reopenReview(this.review.id).subscribe());
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
