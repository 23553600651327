<aside>
    <div class="title">
        <p>Menu</p>
    </div>
    <app-menu></app-menu>
</aside>
<main style="min-width: 1200px; background-color: #ececec;">
    <div id="app-support-core-filter-container" class="graph-core-style">
        <app-support-core-filter id="app-support-core-filter" [hidden] = isHidden></app-support-core-filter>
    </div>
    <div class="content">
        <div class="scrollbox">
            <router-outlet></router-outlet>
        </div>
    </div>
</main>
