<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
<section class="row" [hidden]="!initialized || conversationUid">
    <mat-card class="full-card">
        <mat-card-header>
            <mat-card-title>
                Chat Archive
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="margin-right: 10px;">
                <input width="100%" matInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
            </mat-form-field>
            <mat-form-field *ngIf="authorizationService.isInAdminGroup || authorizationService.isClient">
                <input width="100%" matInput (keyup)="applyClassificationSearchFilter($event.target.value)" placeholder="Search by classification field">
            </mat-form-field>
            <mat-table class="archive-table" [dataSource]="tableDataSource" matSort [matSortActive]="'conversationUid'"
                       [matSortDirection]="'desc'" [matSortDisableClear]="true">
                <ng-container matColumnDef="selectBox">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox *ngIf="selectable(element)"
                                      (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(element.conversationUid) : null"
                                      [checked]="selection.isSelected(element.conversationUid)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{datePipe.transform(element.startDate, 'dd-MM-yyyy')}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationUid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button" style="color: #141464; font-weight: bold;"
                                (click)="setConversationUid(element)">
                            <span class="pointer">{{element.conversationUid}}</span>
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="servedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Operator</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.servedBy}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="classificationCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{!singleAccountSelected ? element.classificationCategory : element.classification}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="department">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Department</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.department}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.clientRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.clientRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.visitorRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.visitorRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="bconnectRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Bconnect Rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.bconnectRating != null" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.bconnectRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="clientComments">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Review</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.clientRating || element.clientFeedback || element.clientComments?.length > 0) ? "yes" : "no"}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="markedTopChat">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Top Chat</mat-header-cell>
                    <mat-cell *matCellDef="let element"><i class="fas fa-award top-chat" *ngIf="element.markedTopChat"></i></mat-cell>
                </ng-container>
                <ng-container matColumnDef="lastClientActivity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Last client activity</mat-header-cell>
                    <mat-cell *matCellDef="let element"><span *ngIf="element.lastClientActivity">{{element.lastClientActivity | date :'dd-MM-yyyy'}}</span></mat-cell>
                </ng-container>
                <ng-container matColumnDef="reviewClosedAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Review Closed</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.reviewClosedAt | date :'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [length]=tableDataLength
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </mat-card-content>
        <button mat-raised-button type="button" (click)="submitForReview()">
            Review selected chats
        </button>
    </mat-card>
</section>
<section class="row" [hidden]="initialized || loading">
    <mat-card class="full-card">
        <mat-card-content>
            <span>There is no data available</span>
        </mat-card-content>
    </mat-card>
</section>

<section class="row" *ngIf="conversationUid && seeCurrent">
    <div class="two-third-card" *ngIf="isClient">
        <app-session-details (loadedSession) = "setLoadedSession($event)"
                             [list]="!viewCurrent"
                             [conversationUid]="conversationUid"
                             (unloadConversationDetailsEvent)="unsetConversationId()"
                             (getNextEvent)="getNextReview($event)">
        </app-session-details>
    </div>
    <mat-card class="third-card" *ngIf="isClient && sessionChatInfo">
        <mat-tab-group mat-align-tabs="start" [dynamicHeight]="true" [disableRipple] = "true" >
            <mat-tab label="Client Feedback">
                <app-feedback-client-feedback
                    [review]="getReview()"
                    [sessionChatInfo]="sessionChatInfo"></app-feedback-client-feedback>
            </mat-tab>
            <mat-tab label="Add FAQ">
                <app-feedback-faqs *ngIf="getReview()" [review]="getReview()"></app-feedback-faqs>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
    <div class="full-card" *ngIf="!isClient">
        <app-session-details [list]="!viewCurrent"
                             [conversationUid]="conversationUid"
                             (unloadConversationDetailsEvent)="unsetConversationId()"
                             (getNextEvent)="getNextReview($event)">
        </app-session-details>
    </div>
</section>

<section class="row" *ngIf="conversationUid && reviewCurrent && hasReview">
    <app-review-details style="width: 100%;"
                        *ngIf="conversationUid"
                        [conversationUid]="conversationUid"
                        [canApply]="canApply"
                        [list]="!viewCurrent"
                        (getNextEvent)="getNextReview($event)"
                        (unloadSessionDetailsEvent)="unsetConversationId()"
                        [review]="getReview()"
                        [sessionInfo]="selectedSessionInfo"
    >
    </app-review-details>
</section>

<div *ngIf="conversationUid && feedbackCurrent">
    <section class="row" *ngIf="conversationUid">
        <div class="two-third-card">
            <app-session-details [list]="!viewCurrent"
                                 [conversationUid]="conversationUid"
                                 (unloadConversationDetailsEvent)="unsetConversationId()"
                                 (getNextEvent)="getNextReview($event)"
                                 (loadedSession)="setLoadedSession($event)"
            >
            </app-session-details>
        </div>
        <mat-card id="review-card" class="third-card" style="padding-top: 15px !important;" *ngIf="conversationUid === reviewService.review?.conversationUid">
            <app-feedback-details [conversationUid]="conversationUid"
                                  [review]="getReview()"
                                  (bconnectRatingEvent)="selectedSessionInfo.bconnectRating = $event"
            >
            </app-feedback-details>
<!--            <app-close-review *ngIf="sessionChatInfo && getReview()"-->
<!--                              [review]="getReview()">-->
<!--            </app-close-review>-->
        </mat-card>
    </section>
</div>

