<section class="row" [hidden]="errorMessage">
    <mat-card [hidden]="!dataSource" class="full-card-finance">
        <mat-card-header>
            <mat-card-title>Account Financial<span class="subtitle" *ngIf="subtitle">{{subtitle}}</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear="true">
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of classificationCountColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ getClassificationCountData(i, element)}}</mat-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of pricePerClassificationColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{(getPricePerClassificationData(i, element) | currency:'EUR')}}
                    </mat-cell>
                </ng-container>
                <ng-container [matColumnDef]="column" *ngFor="let column of revenuePerClassificationColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{(getRevenuePerClassificationData(i, element) | currency:'EUR')}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalRevenue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Σ Revenue</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.totalRevenue | currency:'EUR')}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="revenueTrendPercentage">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                   [title]="'last ' + trendGrouping + ' compared to previous ' + trendGrouping">Revenue Trend
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span [ngStyle]="{'color':getTrendColor(element.revenueTrendPercentage)}">
                            {{(element.revenueTrendPercentage | number:'1.0-2' | percentConcat) || 'n/a' }}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="download">  <mat-header-cell  *matHeaderCellDef>
                    <i (click)="exportAsCSV()" style="cursor: pointer;" class="fas fa-file-download fa-2x"></i>
                </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
                         [ngClass]="{'totals-row':isTotalsRow(i)}"></mat-row>
            </mat-table>
        </mat-card-content>
        <!--<div (click)="exportAsCSV()"><i class="fas fa-file-download fa-3x"></i> </div>-->
    </mat-card>
</section>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="third-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
