<div class="flex-center p1em">
    <app-small-button *ngIf="!review?.corrections && (isTeamLead || isClient || isTCOrBetter)"
                      [label]="'Create new correction'"
                      [color]="'white'"
                      (onClick)="createCorrection()">
    </app-small-button>
</div>

<div *ngIf="review?.corrections">
    <div *ngIf="correction.processStage !== 'ACCEPTED'">
        <div class="buttons" *ngIf="!closed">

            <app-small-button *ngIf="isTeamLead || isClient"
                              (onClick)="suggestCorrection()"
                              [color]="'white'"
                              [disabled]="!hasChanges || (correction.processStage === 'TO_SUPERVISOR')"
                              [bold]="true"
                              [label]="'Suggest'"></app-small-button>

            <app-small-button *ngIf="isTCOrBetter"
                              (onClick)="returnCorrectionSuggestion()"
                              [color]="'white'"
                              [disabled]="!hasChanges || (correction.processStage !== 'TO_SUPERVISOR')"
                              [bold]="true"
                              [title]="'Return the correction for further comment'"
                              [label]="'Return'"></app-small-button>

            <app-small-button *ngIf="isTCOrBetter"
                              #approveButton
                              (onClick)="approveCorrection()"
                              [color]="'white'"
                              [disabled]="!hasChanges"
                              [bold]="true"
                              [title]="'Approve the correction'"
                              [label]="'Approve'"></app-small-button>

            <app-small-button *ngIf="isTCOrBetter"
                              (onClick)="closeCorrection()"
                              [color]="'white'"
                              [disabled]="(correction.processStage !== 'TO_SUPERVISOR')"
                              [bold]="true"
                              [title]="'Decline the suggested correction. The correction will be closed'"
                              [label]="'Decline'"></app-small-button>
        </div>
        <br>
        <div class="row">
            <mat-form-field class="w50" *ngIf="classifications">
                <mat-select placeholder="New category" [(ngModel)]="correction.newClassification"
                            (ngModelChange)="onClassificationChanged()" [disabled]="closed || !userCanEdit">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search category" noEntriesFoundLabel="Nothing found" [formControl]="categorySearchControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let classification of classifications | genericFunctionFilter: {filterFns: [categoryFilterFn], pipeForcedAt: categoryPipeForcedAt}" [value]="classification.classification">
                        {{classification.classification}}
                        <span style="font-size: 9px" *ngIf="classification.classification === correction.classification"> (current)</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w50" *ngIf="departmentList">
                <mat-select placeholder="New department" [(ngModel)]="correction.newDepartment"
                            [disabled]="closed || !userCanEdit">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search department" noEntriesFoundLabel="Nothing found" [formControl]="departmentSearchControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="null">No department</mat-option>
                    <mat-option *ngFor="let d of departmentList | genericFunctionFilter: {filterFns: [departmentFilterFn], pipeForcedAt: departmentPipeForcedAt}" [value]=d>
                        {{d}}
                        <span style="font-size: 9px" *ngIf="d === correction.department"> (current)</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div id="correction-required-fields">
            <div *ngIf="showRequiredFields">
                <app-classification-fields
                    [correction]="correction"
                    (validEvent)="setValidity($event)"
                    (saveFieldValues)="saveFieldValues()">
                </app-classification-fields>
            </div>

        </div>
    </div>
</div>

<app-simple-messaging
    [placeholder]="commentText"
    [messages]="comments"
    (addMessage)="addComment($event)"
    [disabled]="!correction || closed || !userCanEdit || isOperator">
</app-simple-messaging>
