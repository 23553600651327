<div>
    <div *ngIf="data.text">
        <p>
            {{data.text}}
        </p>
    </div>
    <mat-form-field class="w100 mat-form-no-padding-bottom" appearance="outline">
        <mat-select placeholder="{{data.placeholder || 'Select option'}}" (selectionChange)="select($event.value)">
            <mat-option *ngFor="let o of data.options | async" [value]="o">{{o[data.labelKey]}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
