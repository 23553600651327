import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import moment from 'moment';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Account} from '../../model/account.model';
import {CoreParameters} from '../../model/core-parameters.model';
import {AlertifyDto} from '../../model/support/alertify-dto';
import {CorrectionHistoryDto} from '../../model/support/correction-history-dto';
import {Overview} from '../../model/support/overview.model';
import {FeedbackTableData} from '../../model/support/review/feedback-table.model';
import {SessionInfo} from '../../model/support/session-info.model';
import {SimpleUser} from '../../model/support/simple-user.model';
import {UpdateRow} from '../../model/support/update-row.model';
import {TeamMembership} from '../../model/team-member.model';
import {UserShort} from '../../model/user-short.model';
import {SessionChatInfoService} from './session-chat-info.service';
import {ReviewActionType} from "../../enums/support/review-action-type.enum";
import {FeedBackRow} from "../../model/support/feedback-row.model";

@Injectable()
export class SupportService {
  constructor(private http: HttpClient) {

  }

  getParams(coreParameters: CoreParameters): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (coreParameters.accounts) {
      httpParams = httpParams.append('accounts', `${coreParameters.accounts.map((a: Account) => a.id)}`);
    }
    httpParams = httpParams.append('start', `${moment(coreParameters.startDate).format('YYYYMMDD')}`);
    httpParams = httpParams.append('end', `${moment(coreParameters.endDate).format('YYYYMMDD')}`);
    httpParams = httpParams.append('summarizeBy', `${coreParameters.summarizeBy}`);
    if (coreParameters.domain) {
      httpParams = httpParams.append('domain', `${coreParameters.domain.id}`);
    }
    if (coreParameters.team) {
      httpParams = httpParams.append('team', `${coreParameters.team.id}`);
    }
    if (coreParameters.operators) {
      httpParams = httpParams.append('operators', `${coreParameters.operators.map((t: TeamMembership) => t.userId)}`);
    }
    if (coreParameters.channels) {
      httpParams = httpParams.append('channels', `${coreParameters.channels}`);
    }
    return httpParams;
  }

  getChatArchive(coreParameters: CoreParameters): Observable<SessionInfo[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<SessionInfo[]>('/api/chatsession/chatarchive', {params: httpParams});
  }

  getHybridChatArchive(coreParameters: CoreParameters): Observable<SessionInfo[]> {
    const httpParams = this.getParams(coreParameters);
    return this.http.get<SessionInfo[]>('/api/chatsession/hybridchatarchive', {params: httpParams});
  }

  getAllCategories(): Observable<string[]> {
    return this.http.get<string[]>('/api/chatsession/classificationCategories');
  }

  getCategoriesFiltered(account: number): Observable<string[]> {
    const httpParams = new HttpParams().append('account', account.toString());
    return this.http.get<string[]>('/api/chatsession/classificationsFiltered', {params: httpParams});
  }

  getCategories(account: number): Observable<string[]> {
    const httpParams = new HttpParams().append('account', account.toString());
    return this.http.get<string[]>('/api/chatsession/classifications', {params: httpParams});
  }

  getClientsForAccount(account: number): Observable<UserShort[]> {
    const httpParams = new HttpParams().append('account', account.toString());
    return this.http.get<UserShort[]>('/api/chatsession/clients', {params: httpParams});
  }

  getDepartmentsForAccount(account: number): Observable<string[]> {
    const httpParams = new HttpParams().append('account', account.toString());
    return this.http.get<string[]>('/api/chatsession/department', {params: httpParams});
  }

  getActiveDepartmentsForAccount(accountId: number): Observable<string[]> {
    return this.http.get<string[]>(`/api/accounts/${accountId}/departments`);
  }

  getClassificationsForAccount(account: number): Observable<string[]> {
    const httpParams = new HttpParams().append('account', account.toString());
    return this.http.get<string[]>('/api/chatsession/classifications', {params: httpParams});
  }

  getOperatorErrorOptions(): Observable<string[]> {
    return this.http.get<string[]>('/api/chatsession/operatorerrors').pipe(
      share(),
    );
  }

  getImgSource(value: number): string {
    let imgSource: string = '';
    switch (value) {
      case 1: {
        imgSource = 'assets/img/bad.png';
        break;
      }
      case 2: {
        imgSource = 'assets/img/bad.png';
        break;
      }
      case 3: {
        imgSource = 'assets/img/neutral.png';
        break;
      }
      case 6: {
        imgSource = 'assets/img/neutral.png';
        break;
      }
      case 5: {
        imgSource = 'assets/img/good.png';
        break;
      }
      case 10: {
        imgSource = 'assets/img/good.png';
        break;
      }
    }
    return imgSource;
  }

  getReviewQueue(): Observable<SessionInfo[]> {
    return this.http.get<SessionInfo[]>(`/api/support/review/sessions`);
  }

  getSessionInfoWithFeedback(isHQ: boolean): Observable<SessionInfo[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('hqReview', `${isHQ.toString()}`);
    return this.http.get<SessionInfo[]>(`/api/support/review/sessionswithfeedback`, {params: httpParams});
  }

  getTicketsByActionType(reviewActionType: ReviewActionType): Observable<FeedBackRow[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('actionType', reviewActionType);
    return this.http.get<FeedBackRow[]>('/api/support/review/tickets', {params: httpParams});
  }

  getTicketsByReviewAndActionType(reviewId: number, reviewActionType: ReviewActionType): Observable<FeedBackRow[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('actionType', reviewActionType);
    return this.http.get<FeedBackRow[]>(`/api/support/review/${reviewId}/tickets`, {params: httpParams});
  }

  getUpdates(): Observable<UpdateRow[]> {
    return this.http.get<UpdateRow[]>
    (`api/support/updates/updateRowData`);
  }

  getProductsWithAccount(conversationUid: string): Observable<string[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('conversationUid', `${conversationUid.toString()}`);
    return this.http.get<string[]>
    (`/api/chatsession/getproducts`, {params: httpParams});
  }

  markUpdateSeen(actionType: string, conversationUid: string): Observable<AlertifyDto> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('actionType', `${actionType.toString()}`);
    httpParams = httpParams.append('conversationUid', `${conversationUid.toString()}`);
    return this.http.get<AlertifyDto>(`/api/support/updates/seen`, {params: httpParams});
  }

  clearActionsByType(actionType: string, reviewId: number): Observable<AlertifyDto> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('reviewActionType', `${actionType.toString()}`);
    return this.http.get<AlertifyDto>(`/api/support/review/${reviewId}/actions/close`, {params: httpParams});
  }

  getOverview(): Observable<Overview[]> {
    const httpParams = new HttpParams();
    return this.http.get<Overview[]>(`/api/support/overview`, {params: httpParams});
  }

  getQualitychecks(): Observable<string[]> {
    return this.http.get<string[]>('/api/chatsession/qualitychecks');
  }

  getSupportAgents(account: string, permission: string, view: string): Observable<SimpleUser[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('account', account);
    httpParams = httpParams.append('permission', permission);
    httpParams = httpParams.append('view', view);
    return this.http.get<SimpleUser[]>('/api/support/support-process/agents', {params: httpParams});
  }

  getActionCounts(): Observable<any[]> {
    return this.http.get<any[]>('/api/support/support-process/counts');
  }
}
