<section class="row" [hidden]="errorMessage">
    <mat-card class='full-card limited-larger ' *ngIf="initialized && multipleAccounts && dataBarChartTop" >
        <mat-card-header>
            <div class="toggles">
                <mat-button-toggle-group #group="matButtonToggleGroup" value=selectedGraph>
                    <mat-button-toggle value="distribution" [matMenuTriggerFor]="menu">
                        {{'Chat Distribution: '+selectedDistributionGraph +'▼'}}
                    </mat-button-toggle>
                    <mat-button-toggle value="chats" (click)="setBarGraph('chats',null)">
                        Number of Chats
                    </mat-button-toggle>
                    <mat-button-toggle value="visitorScore" (click)="setBarGraph('visitorScore',null)">
                        Visitor Score
                    </mat-button-toggle>
                    <mat-button-toggle value="clientScore" (click)="setBarGraph('clientScore',null)">
                        Client Score
                    </mat-button-toggle>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="setBarGraph('chatDistribution','Lead')">Leads</button>
                        <button mat-menu-item (click)="setBarGraph('chatDistribution','Service')">Service</button>
                        <button mat-menu-item (click)="setBarGraph('chatDistribution','Missed')">Missed</button>
                        <button mat-menu-item (click)="setBarGraph('chatDistribution','Wasted')">Wasted</button>
                        <button mat-menu-item (click)="setBarGraph('chatDistribution','Geen Dialoog')">Geen Dialoog</button>
                    </mat-menu>
                </mat-button-toggle-group>
            </div>
            <mat-card-title>
                Performance<span class="subtitle">{{subtitle}}</span>
            </mat-card-title>
        </mat-card-header>


        <mat-card-content>
            <p-chart *ngIf="dataBarChartTop" type="bar"  [data]="dataBarChartTop" [options]="barOptions"
                     height="86%"   [ngClass]="{'sub-card-left': dataBarChartBottom}"></p-chart>
            <p-chart *ngIf="dataBarChartBottom" type="bar"  [data]="dataBarChartBottom" [options]="barOptions"
                      height="86%" class ="sub-card-right"></p-chart>
        </mat-card-content>

    </mat-card>
    <mat-card class="full-card-account-leaderboard" *ngIf="initialized">
        <mat-card-header>
            <mat-card-title>Account
                leaderboard<span class="subtitle" *ngIf="subtitle">for {{subtitle}}</span></mat-card-title>
            <div class="toggles">
                <mat-button-toggle-group class="filter-button" value = "percentage">
                    <mat-button-toggle value="percentage" (click)="setNumberType('percentage')"> % </mat-button-toggle>
                    <mat-button-toggle value="number" (click)="setNumberType('number')">#</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-card-header>
        <mat-card-content>
            <mat-table [dataSource]="dataTable$ | async" matSort matSortActive="numberOfChats"
                       matSortDirection="desc" matSortDisableClear="true">
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="visits">
                    <mat-header-cell *matHeaderCellDef>Visits</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.visits || 'n/a' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="chats">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.chats}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalScore">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Total Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span>{{(element.totalScore.first | number:'1.2-2') || 'n/a'}}</span>
                        <span *ngIf="element.totalScore['first'] > 0"  class="smaller">
                            <i *ngIf="element.totalScore['second'] > 0"  [ngClass]="getPercentageIconClass(element.totalScore['first'] * 10, element.totalScore['second'] * 10)"></i>
                            ({{(element.totalScore.second | number:'1.2-2') || ''}})
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="percentageOfVisits">
                    <mat-header-cell *matHeaderCellDef>CR X/Visits</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.visits | number:'1.2-2' | percentConcat }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="percentageOfInvites">
                    <mat-header-cell *matHeaderCellDef>CR X/Invites</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.invites | number:'1.2-2' | percentConcat }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="percentageOfChats">
                    <mat-header-cell *matHeaderCellDef>CR X/Chats</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.crChats | number:'1.2-2' | percentConcat }}
                    </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span>{{ getDynamicData(i, element) }}</span>
                        <span class="smaller" *ngIf="showTotalPercentage(i, element)">
                            <i [ngClass]="getTotalPercentageIconClass(i, element)"></i>
                            ({{getTotalPercentage(i, element) | number:'1.2-2'}}%)
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="chatScore">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{(element.chatScore | number:'1.2-2') || 'n/a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorScore">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.visitorScore | number:'1.2-2'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientScore">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client Score</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.clientScore | number:'1.2-2'}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
