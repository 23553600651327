<mat-card class="full-card" *ngIf="clickPath.length > 0">
    <mat-card-title>
        <span>Clickpath</span>
    </mat-card-title>
    <mat-accordion *ngFor="let pageVisit of clickPath">
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-description style="display: flex; width: 100%">
                    <div [style.width.%]="10">
                        <span><em>{{pageVisit.visitStart | date:"HH:mm:ss"}}</em></span>
                    </div>
                    <div [style.width.%]="75">
                        <a (click)="$event.stopPropagation()" href="http://{{pageVisit.domain + pageVisit.path}}" target="_blank">{{pageVisit.path}}</a>
                    </div>
                    <div [style.width.%]="15" [style.color]="'#ffaf49'">
                        <span *ngIf="chatStartedOnPath(pageVisit)"><em class="far fa-comments"></em> - Chat started</span>
                        <span *ngIf="chatStartedOnPath(pageVisit) && inviteOnPath(pageVisit)"> | </span>
                        <span *ngIf="inviteOnPath(pageVisit)"><em class="far fa-bell"></em> - Invite sent</span>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item>Domain: {{pageVisit.domain}}</mat-list-item>
                <mat-list-item *ngIf="pageVisit.inviteTimestamp">Time of invite: {{pageVisit.inviteTimestamp | date:"HH:mm:ss"}}</mat-list-item>
                <mat-list-item>Time spent on path (hh:mm:ss): {{pageVisit.duration}}</mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>
