<div class="added-faq-dialog">
    <mat-list>
        <ng-container>
            <h4>Correction history for chat {{conversationUid}}:</h4>
            <mat-list-item *ngFor="let c of comments | async">
                <div>{{c.createdAt | date}}: {{c.comment}}</div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </ng-container>
    </mat-list>
    <div class="buttons">
        <app-small-button (onClick)="markAsSeen()"
                          [color]="'white'"
                          [bold]="true"
                          [label]="'Mark as seen'"></app-small-button>
    </div>
</div>
