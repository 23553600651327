<section>
    <div class="review-messages" [ngClass]="scrollable ? 'scrollable' : ''">
        <ul *ngFor="let message of messages | genericFunctionFilter: {sortFn: sortFn}">
            <li [className]="getMessageClass(message.commentBy?.userName)">
                <span>{{message.commentBy?.screenName}}</span>
                <span [style.font-size.px]="12"> ({{message.createdAt | date : 'short'}}): </span>
                <span *ngFor="let line of getMessageLines(message)">
                    {{line}}
                    <br/>
                </span>
            </li>
        </ul>
    </div>
</section>
