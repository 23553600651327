<div class="faq-dialog" matDialogContent>

    <div class="flex-between">
        <h3 mat-dialog-title>{{title}}</h3>
        <app-small-button [color]="'white'"
                          [label]="'Show related conversation'"
                          (onClick)="loadRelatedChat()">
        </app-small-button>
    </div>

    <div class="flex-column">
        <div>
            <div>
                <mat-tab-group dynamicHeight>
                    <mat-tab *ngFor="let qna of faqAddition.qnas | genericFunctionFilter: {sortFn: feedbackService.qnaSortFn}; let i = index" [label]="qna.languageCode">
                        <ng-template mat-tab-label>
                            {{qna.languageCode}}
                            <i class="fas fa-flag fa-xs"
                               [style.color]="'#ffaf49'"
                               [style.margin-left]="'0.45em'" *ngIf="qna.answer?.length > 0 || qna.question?.length > 0"></i>
                        </ng-template>
                        <div class="flex-center qna-label">
                            Question
                        </div>
                        <mat-form-field class="mat-form-no-padding-bottom" style="width: 100%; height: 6em" appearance="outline">
                     <textarea matInput placeholder="Question"
                               [(ngModel)]="qna.question"
                               [disabled]="!questionsEditable">
                     </textarea>
                        </mat-form-field>
                        <i (click)="editQuestions()" title="Edit questions" class="fas fa-edit i-edit"></i>

                        <div class="flex-center qna-label">
                            <i class="fas fa-long-arrow-alt-down"></i>
                            <span style="padding: 0 1em">Answer here</span>
                            <i class="fas fa-long-arrow-alt-down"></i></div>
                        <div class="form-border-highlight">
                            <mat-form-field class="mat-form-no-padding-bottom" style="width: 100%; height: 6em;" appearance="outline">
                    <textarea title="answer" matInput placeholder="Answer"
                              [(ngModel)]="qna.answer">
                    </textarea>
                            </mat-form-field>
                        </div>


                    </mat-tab>
                </mat-tab-group>
            </div>

            <div class="pt1em" style="position: relative">
                <app-simple-messaging
                    [id]="messagingComponentId"
                    [messages]="faqAddition.comments"
                    [scrollable]="false"
                    [disabled]="!commentsActive"
                    (addMessage)="addComment($event);">
                </app-simple-messaging>
                <i (click)="enableCommenting()" title="Write comment" class="fas fa-edit write-comment"></i>
            </div>


            <div [style.background-color]="message ? 'lightgrey' : 'transparent'"
                 style="margin-top: 4px; border-radius: 3px; height: 2.5em" class="flex-center p1">
                <span *ngIf="showMessage">{{message}}</span>
            </div>


            <div style="position: relative">
                <div class="buttons">
                    <button mat-stroked-button color="primary" style="width: 30%;"
                            (click)="submit()"
                            [disabled]="!maySubmit">
                        Submit to Bconnect
                    </button>
                    <button mat-stroked-button color="primary" style="width: 30%;"
                            [disabled]="!maySaveAsConcept"
                            (click)="concept()">
                        Save as concept
                    </button>
                    <button mat-stroked-button color="primary" style="width: 30%;"
                            *ngIf="mayCancel"
                            (click)="cancelFaqAddition()">
                        Cancel
                    </button>
                    <button *ngIf="faqAddition.faqAdditionProcessStage === 'TO_CLIENT'" mat-stroked-button color="primary" style="width: 30%;"
                            (click)="reject()" [title]="'Please leave a comment before rejecting'">
                        Reject
                    </button>
                </div>
                <div class="delete-button" *ngIf="mayDelete">
                    <app-delete-button (onConfirm)="delete()"></app-delete-button>
                </div>
            </div>

        </div>

    </div>

</div>


