import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable ,  Subscription } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { ReviewActionType } from '../../../enums/support/review-action-type.enum';
import { ColorService } from '../../../graph/graph-services/color.service';
import { PendingActions } from '../../../model/support/pending-actions.model';
import { QualityCheck } from '../../../model/support/quality-check.model';
import { ReviewMessage } from '../../../model/support/review-message.model';
import { Review } from '../../../model/support/review/review.model';
import { SessionInfo } from '../../../model/support/session-info.model';
import { SessionWithActionType } from '../../../model/support/session-with-update-type.model';
import { SimpleUser } from '../../../model/support/simple-user.model';
import { SimplerComment } from '../../../model/support/simpler-comment.model';
import {
  GenericInputPromptDialogComponent
} from '../../../shared/components/generic-input-prompt-dialog/generic-input-prompt-dialog.component';
import { AlertifyService } from '../../service/alertify.service';
import { MessageService } from '../../service/message.service';
import { ReviewService } from '../../service/review.service';
import { SessionChatInfoService } from '../../service/session-chat-info.service';
import { SupportService } from '../../service/support.service';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tabGroup', {static: false})
  tabGroup: MatTabGroup;

  @Output()
  markAsSeenEvent = new EventEmitter<SessionWithActionType>();
  @Output()
  reviewMessageEvent = new EventEmitter<ReviewMessage>();
  @Input()
  conversationUid: string;
  @Input()
  disabled: boolean;
  @Input()
  openOnTab: ReviewActionType;
  @Input()
  tabsWithUpdate: string[] = [];
  @Input()
  review: Review;

  @Output()
  updateActionsEvent = new EventEmitter<number>();
  tabIndex: number = 0;
  pendingActions: PendingActions[];

  subscriptions: Subscription[] = [];
  qualityChecks: QualityCheck[] = [];
  operatorErrors: Observable<string[]>;

  clientMessages: ReviewMessage[];
  staticMessages: SimplerComment[];
  escalationMessages: ReviewMessage[];
  optimizationMessages: ReviewMessage[];
  optimizationAgents: SimpleUser[] = [];

  sessionInfo: SessionInfo;
  @Output()
  bconnectRatingEvent = new EventEmitter<number>();

  agentSortFn = (a: SimpleUser, b: SimpleUser) => {
    return (!!a.screenName ? a.screenName : a.userName).toLowerCase().charCodeAt(0) -
      (!!b.screenName ? b.screenName : b.userName).toLowerCase().charCodeAt(0);
  }

  agentFilterFn = (user: SimpleUser) => {
    return user.id !== this.authorizationService.currentUser.id;
  }

  constructor(public reviewService: ReviewService,
              private supportService: SupportService,
              public authorizationService: AuthorizationService,
              private messageService: MessageService,
              private sessionChatInfoService: SessionChatInfoService,
              private colorService: ColorService,
              private dialog: MatDialog,
              private alertifyService: AlertifyService
  ) {}

  get hasClientFeedback(): boolean {
    return this.sessionInfo?.clientFeedback ||
      this.sessionInfo?.clientRating > 0 ||
      (this.sessionInfo?.clientComments && this.sessionInfo?.clientComments.length > 0);
  }

  ngOnInit() {
    this.sessionChatInfoService.listen().subscribe(s => {
      this.sessionInfo = s?.sessionInfo;

      if (this.sessionInfo && this.hasClientFeedback) {
        this.messageService.getMessagesByConversationUid(this.conversationUid, 'clientcomment').subscribe((messages) => {
          this.clientMessages = messages;
        });
        if (this.sessionInfo.clientComments && this.sessionInfo.clientComments.length > 0) {
          this.staticMessages = [{message: this.sessionInfo.clientComments, sentBy: 'Rating comment'}];
        }
      }
    });

    this.operatorErrors = this.supportService.getOperatorErrorOptions();
    this.messageService.getMessagesByConversationUid(this.conversationUid, 'Escalate').subscribe((messages) => {
      this.escalationMessages = messages;
    });
    this.messageService.getMessagesByConversationUid(this.conversationUid, 'optimization').subscribe((messages) => {
      this.optimizationMessages = messages;
      if (messages.length > 0) {
        this.supportService.getSupportAgents(this.review.account, 'OPTIMIZATION', 'Optimization').subscribe((agents) => {
          this.optimizationAgents = agents;
        });
      }
    });

    this.reviewService.getPendingActionsByUser(this.review.id).subscribe(pacts => this.pendingActions = pacts);

  }

  ngAfterViewInit() {
    // find tab index of optional openOnTab value

    // special case, if action type is TOPCHAT, hop to FEEDBACK first, then use TOPCHAT in subcomponent
    const tab = this.openOnTab === 'TOPCHAT' ? 'FEEDBACK' : this.openOnTab;

    const position = this.tabGroup._tabs.find(t => t.textLabel === tab)?.position;
    if (position) {
      this.tabGroup.selectedIndex = position;
    }
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  emitReviewMessageEvent(reviewMessage: ReviewMessage) {
    this.reviewMessageEvent.emit(reviewMessage);
  }

  hasPendingActions(actionType: string): boolean {
    return this.pendingActions?.some(pa => pa.pendingActions.some(a => a === actionType));
  }

  sendEscalateMessage(reviewMessage: ReviewMessage) {
    this.messageService.sendEscalateReviewMessage(reviewMessage).subscribe((esc) => {
      this.reviewService.review.escalationProcess = esc.escalationProcess;
      this.escalationMessages = this.escalationMessages.concat(esc.reviewMessage);
    });
  }

  sendOptimizationMessage(reviewMessage: ReviewMessage) {
    this.messageService.sendOptimizationMessage(reviewMessage).subscribe(
      (opt) => {
        this.optimizationMessages = this.optimizationMessages.concat(opt);
      }
    );
  }

  assignActionTo(
    actionType: 'OPTIMIZATION', // can add more types in future if needed
    userId: number) {

    if (userId === this.authorizationService.currentUser.id) {
      this.reviewService.assignActionForType(this.review.id, actionType, userId).subscribe((claim) => {
        this.review.reviewProcessClaims = this.review.reviewProcessClaims.concat(claim);
      });
    } else {
      // assigning action to someone else, comment is required
      this.dialog.open(GenericInputPromptDialogComponent, {
        hasBackdrop: false,
        data: {
          text: 'Please leave a comment before assigning',
          inputPlaceholder: 'Leave a comment',
          overlayOnElement: document.getElementById('review-card')
        }
      }).afterClosed().subscribe((result) => {
        if (!result.message) {
          return;
        }

        this.messageService.sendMessageNoAction(this.review.id, actionType, result.message).subscribe((rm) => {
          this.optimizationMessages.push(rm);
        });

        this.reviewService.assignActionForType(this.review.id, actionType, userId).subscribe((claim) => {
          this.review.reviewProcessClaims = this.review.reviewProcessClaims.concat(claim);
        });
      });
    }

  }

  sendMessageToClient(reviewMessage: ReviewMessage) {
    this.messageService.sendReviewMessage(reviewMessage).subscribe((message) => {
      this.clientMessages = this.clientMessages.concat(message);
    });
  }

  closeEscalation() {
    this.reviewService.closeEscalation(this.reviewService.review.id).subscribe((response) => {
      this.reviewService.review.escalationProcess = response;
    });
  }

  isEscalationInitByCurrentUser(): boolean {
    return this.review.escalationProcess.initiatedBy.userName === this.authorizationService.currentUser.name;
  }

  lcdString(): string {
    const ss = this.review.supervisorSet;
    let text;
    if (ss != null && ss.lcds.length > 0) {
      text = ss.lcds.map((l) => l.screenName).reduce((a, b) => a + ', ' + b);
    }
    return text;
  }

  participantString(): string {
    const p = this.review.participants;
    let text;
    if (p != null && p.length > 0) {
      text = p.map((l) => l.screenName).reduce((a, b) => a + ', ' + b);
    }
    return text;
  }

  public happinessColor(n: number): string {
    if (this.sessionInfo.clientRating === n) {
      return this.colorService.getColorByGrade(n);
    } else {
      return 'gray';
    }
  }

  markAsSeen(conversationUid: string, actionType: string) {
    this.markAsSeenEvent.emit({conversationUid, actionType});
  }

  hasUpdate(section: string): boolean {
    return this.tabsWithUpdate.some((t) => t === section);
  }

  get isTeamLeadOrBetter(): boolean {
    return this.authorizationService.isTeamLead || this.authorizationService.isInAdminGroup;
  }

  get isAdmin(): boolean {
    return this.authorizationService.isAdmin;
  }

  get isLCDOrBetter(): boolean {
    return this.authorizationService.isLcd || this.authorizationService.isInAdminGroup;
  }

  resolve(actionType: 'OPTIMIZATION') {
    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before resolving.',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed().subscribe((result) => {
      if (!result.message) {
        return;
      }

      if (!this.processIsClaimed(actionType)) {
        this.assignActionTo(actionType, this.authorizationService.currentUser.id);
      }

      this.messageService.sendMessageNoAction(this.review.id, actionType, result.message).subscribe((rm) => {
        this.optimizationMessages.unshift(rm);
      });

      this.supportService.clearActionsByType(actionType, this.review.id).subscribe((alert) => {
        this.alertifyService[alert.type](alert.message);
        this.pendingActions.forEach(pac => pac.pendingActions = pac.pendingActions.filter(p => p !== actionType));
        this.updateActionsEvent.emit(this.review.id);
      });
    });
  }

  hasExclusiveClaim(processType: string): boolean {
    const currentUserId = this.authorizationService.currentUser.id;
    return this.review.reviewProcessClaims.some((c) =>
      c.processType === processType && c.user.userId === currentUserId && c.exclusive && c.cancelledAt === null);
  }

  processIsClaimed(processType: string): boolean {
    return this.review.reviewProcessClaims.some(p => p.processType === processType && p.cancelledAt === null);
  }

  otherHasExclusiveClaim(processType: string): boolean {
    const currentUserId = this.authorizationService.currentUser.id;
    return this.review.reviewProcessClaims.some((c) =>
      c.processType === processType && c.user.userId !== currentUserId && c.exclusive && c.cancelledAt === null);
  }

  exclusiveClaimant(processType: string): string {
    return this.review.reviewProcessClaims
      .sort((a, b) =>
        new Date(b.claimedAt).getTime() - new Date(a.claimedAt).getTime()) // find most recent first
      .find(
      c => c.processType === processType && c.exclusive && c.cancelledAt === null)?.user.screenName;
  }

  hasOptimizePermission(): boolean {
    return this.optimizationAgents.some((a) => a.userName === this.authorizationService.currentUser.name);
  }

  resolveClientFeedback() {
    this.dialog.open(GenericInputPromptDialogComponent, {
      hasBackdrop: false,
      data: {
        text: 'Please leave a comment before resolving',
        inputPlaceholder: 'Leave a comment',
        overlayOnElement: document.getElementById('review-card')
      }
    }).afterClosed()
      .subscribe((result) => {

        if (result?.message) {
          this.reviewService.resolveMessageFlow(result.message, this.review.id, 'CLIENTCOMMENT').subscribe((response) => {
            this.alertifyService[response.alert.type](response.alert.message);
            this.clientMessages.unshift(response.body);
            this.updateActionsEvent.emit(this.review.id);
            const pa = this.pendingActions.find(p => p.userId === this.authorizationService.currentUser.id);
            pa.pendingActions = pa.pendingActions.filter(p => p !== 'CLIENTCOMMENT');
          });
        }

      });
  }
}
