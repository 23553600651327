<section class="row">
    <mat-card class="full-card-performance limited-larger">
        <mat-card-header >
            <div>
                <mat-card-title>
                    Performance<span class="subtitle">{{subtitle}}</span>
                </mat-card-title>
            </div>
            <div class="toggles">
                <mat-button-toggle-group #group="matButtonToggleGroup" value="duration">
                    <mat-button-toggle value="duration" (click)="setGraphType('duration')">
                        Duration
                    </mat-button-toggle>
                    <mat-button-toggle value="response time" (click)="setGraphType('response time')">
                        Response Time
                    </mat-button-toggle>
                    <mat-button-toggle value="pickup time" (click)="setGraphType('pickup time')">
                        Pickup Time
                    </mat-button-toggle>
                    <mat-button-toggle *ngIf="!this.authorizationService.isClient" value="missed chats"
                                       (click)="setGraphType('missed chats')">
                        Missed Chats
                    </mat-button-toggle>
                    <mat-button-toggle value="average chats per hour" (click)="setGraphType('average chats per hour')">
                        Chats per hour
                    </mat-button-toggle>
                    <mat-button-toggle *ngIf="!this.authorizationService.isClient" value="average euro per hour"
                                       (click)="setGraphType('euro per hour')">
                        € per hour
                    </mat-button-toggle>
                    <mat-button-toggle *ngIf="this.authorizationService.isInAdminGroup" value="average euro per chat"
                                       (click)="setGraphType('euro per chat')">
                        € per chat
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-card-header>
        <mat-card class ="errorMessage" [hidden] = "!errorMessage">
        <mat-card-content>
                    <span>{{errorMessage}}</span>
        </mat-card-content>
        </mat-card>
        <mat-card-content [hidden]= " errorMessage" >
            <p-chart *ngIf="dataSource" type="line" [data]="dataSource" [options]="options" height="85%" ></p-chart>
        </mat-card-content>
    </mat-card>
</section>
