<div style="display: flex; flex-direction: column; width: 100%" *ngIf="sessionChatInfo">
    <mat-card class ="full-card" >
        <mat-card-header>
            <div style="display: flex; justify-content: space-between; width: 100%">
                <mat-card-title>
                    <span>Session details</span>
                </mat-card-title>
                <div>
                    <button *ngIf = "list" class="transparent-button" style="font-size: 24px;"
                            (click)="getNextReview(-1)">
                        <i class="fas fa-step-backward"></i>
                    </button>
                    <button *ngIf = "list" class="transparent-button" style="font-size: 24px;"
                            (click)="getNextReview(1)">
                        <i class="fas fa-step-forward"></i>
                    </button>
                    <button class="transparent-button pointer" style="font-size: 24px; margin-left: 3vw"
                            (click)="goBackToOverview()" title="Back to overview">
                        <i class="fas fa-arrow-alt-circle-left"></i>
                    </button>
                </div>
            </div>
        </mat-card-header>
        <mat-list class="ml-3 mr-3" style="font-size: 12px" >
            <mat-list-item>
                <p>Chat date</p>
                <p>{{datePiper.transform(sessionChatInfo.sessionInfo.startDate, 'dd-MM-yyyy')}}</p>
                <p>Chat id</p>
                <p>{{sessionChatInfo.sessionInfo.conversationUid}}</p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p>Operator</p>
                <p>{{sessionChatInfo.sessionInfo.servedBy}}</p>
                <p>Account</p>
                <p>{{sessionChatInfo.sessionInfo.account}}</p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p>Category</p>
                <p>{{sessionChatInfo.sessionInfo.classification}}
                    ({{sessionChatInfo.sessionInfo.classificationCategory}})</p>
                <p>Department</p>
                <p>{{sessionChatInfo.sessionInfo.department}}</p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p>Chat start</p>
                <p>{{datePiper.transform(sessionChatInfo.sessionInfo.startDate, 'HH:mm:ss')}}</p>
                <p>Chat duration</p>
                <p>{{sessionChatInfo.sessionInfo.duration | amDuration:'seconds'}}</p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p>Pickup time</p>
                <p>{{sessionChatInfo.sessionInfo.pickupTime}} seconds</p>
                <p>Average reaction time</p>
                <p>{{sessionChatInfo.sessionInfo.avgResponseTime}} seconds</p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p>Visitor feedback</p>
                <p (click)="openVisitorCommentDialog()">
                    {{sessionChatInfo.sessionInfo.visitorComments ? 'yes' : 'no'}}</p>
                <p>Visitor rating</p>
                <p><img *ngIf="sessionChatInfo.sessionInfo.visitorRating != null" alt="good" style="display:block;" width="22px"
                        height="22px"  src={{getImgSource(sessionChatInfo.sessionInfo.visitorRating)}}/>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <p title="Comments added by the client while responding to the transcript export">Client feedback</p>
                <p>{{sessionChatInfo.sessionInfo.clientComments || sessionChatInfo.sessionInfo.clientFeedback ? 'yes' : 'no'}}</p>
                <p title="The rating given by the client">Client rating</p>
                <p><img *ngIf="sessionChatInfo.sessionInfo.clientRating != null" alt="good" style="display:block;" width="22px"
                        height="22px"  src={{getImgSource(sessionChatInfo.sessionInfo.clientRating)}}/>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div *ngIf="!isClient">
                <mat-list-item>
                    <p title="Feedback given by the Bconnect supervisor">Bconnect feedback</p>
                    <p>{{sessionChatInfo.sessionInfo.bconnectFeedback? 'yes': 'no'}}</p>
                    <p title="The rating given by the Bconnect supervisor">Bconnect rating</p>
                    <p><img *ngIf="sessionChatInfo.sessionInfo.bconnectRating != null" alt="good" style="display:block;" width="22px"
                            height="22px"  src={{getImgSource(sessionChatInfo.sessionInfo.bconnectRating)}}/>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <p title="Feedback added by the operator while classifying the chat">Operator feedback</p>
                    <p> {{sessionChatInfo.sessionInfo.operatorFeedback || sessionChatInfo.sessionInfo.operatorComments ? 'yes' : 'no'}}</p>
                    <p title="The operator suggested an FAQ">CO Added FAQ</p>
                    <p>{{sessionChatInfo.sessionInfo.operatorAddedFaq ? 'yes' : 'no'}}</p>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <p title="The chat passed through language review">Language review</p>
                    <p>{{sessionChatInfo.sessionInfo.languageReview ? 'yes': 'no'}}</p>
                    <p title="The chat was reviewed by HQ">HQ Review</p>
                    <p>{{sessionChatInfo.sessionInfo.hqReview? 'yes': 'no'}}</p>
                </mat-list-item>
            </div>
        </mat-list>
    </mat-card>
    <mat-card class ="full-card" *ngIf="classificationFields?.length > 0">
        <mat-card-header>
            <div style="display: flex; justify-content: space-between; width: 100%">
                <mat-card-title>
                    <span>Classification details</span>
                </mat-card-title>
            </div>
        </mat-card-header>
        <mat-list class="ml-3 mr-3" style="font-size: 12px" >
            <ng-container *ngFor="let field of classificationFields; let i = index">
                <mat-list-item>
                    <p>{{ getFieldNameBasedOffUserLanguage(field) }}</p>
                    <p>{{ field.value }}</p>
                </mat-list-item>
                <mat-divider *ngIf="i < classificationFields?.length - 1"></mat-divider>
            </ng-container>
        </mat-list>
    </mat-card>
    <app-transcript [messages]="sessionChatInfo.messages"
                    [servedBy]="sessionChatInfo.sessionInfo.servedBy">
    </app-transcript>
    <app-session-clickpath *ngIf="sessionChatInfo.clickPath"
                           [clickPath]="sessionChatInfo.clickPath"
                           [sessionInfo]="sessionChatInfo.sessionInfo"
    ></app-session-clickpath>
</div>
