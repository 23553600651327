<div class="row">
    <div class="two-third-card left">
        <app-session-details [list]="list"
                             [conversationUid]="conversationUid"
                             (unloadConversationDetailsEvent)="unsetConversationUid($event)"
                             (getNextEvent)="getNextReview($event)">
        </app-session-details>
    </div>
    <mat-card id="review-card" *ngIf="conversationUid === reviewService.review?.conversationUid" class="third-card right">
        <app-feedback-details [conversationUid]="conversationUid"
                              [review]="review"
                              (reviewMessageEvent)="emitReviewMessageEvent($event)"
                              (bconnectRatingEvent)="sessionInfo.bconnectRating = $event"
        >
        </app-feedback-details>
    </mat-card>
</div>
