<aside>
    <app-menu [buttonsDisabled]="buttonsDisabled"></app-menu>
</aside>
<main style="min-width: 1200px">
    <div class="graph-core-style">
        <app-graph-core-filter></app-graph-core-filter>
    </div>
    <div class="content">
        <div class="scrollbox">
            <router-outlet></router-outlet>
        </div>
    </div>
</main>
