<p *ngIf="data.html" [innerHTML]="data.html"></p>
<p *ngIf="!data.html">{{data.text}}</p>

<div class="flex-between w100" *ngIf="data.prompt">
    <app-small-button (onClick)="decline()"
                      [backgroundColor]="'grey'"
                      [color]="'white'"
                      [label]="data.prompt.decline"></app-small-button>

    <app-small-button (onClick)="accept()"
                      [backgroundColor]="'#000066'"
                      [color]="'white'"
                      [label]="data.prompt.accept"></app-small-button>
</div>
