<div style="font-size: 13px">
    <form [formGroup]="coreFilterForm" (ngSubmit)="onSubmit()">
        <mat-form-field [hidden]="isOperator || isClient">
            <mat-select formControlName="team"
                        placeholder="Team">
                <mat-option *ngIf="!isClient" (click)="resetTeam()">
                    <span class="italic"> No filter</span>
                </mat-option>
                <mat-option *ngFor="let team of teams" [value]="team">
                    {{ team.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [hidden]="isOperator || isClient">
            <mat-select formControlName="operators"
                        placeholder="Operator"
                        multiple
                        matTooltip="{{operatorToolTip()}}"
                        matTooltipPosition="after"
                        matTooltipClass="tooltip-multiline"
                        #operatorSelect>
                <mat-select-trigger>
                    {{ selectedOperators?.length > 0 ? selectedOperators[0].userName.substr(0, 17) : '' }}
                    <span class="limit-width"
                          *ngIf="selectedOperators?.length > 1"> (+{{ selectedOperators.length - 1 }} )
                </span>
                    <button mat-icon-button class="clear" (click)="clearOperator($event);" type="button">
                        <i class="fa fa-times align-top"></i>
                    </button>
                </mat-select-trigger>
                <div class="select-all" *ngIf="operators.length > 3">
                    <mat-checkbox #allOperatorsCheckbox
                                  (change)="toggleAllElements(allOperatorsCheckbox, operatorSelect)">All
                    </mat-checkbox>
                </div>
                <mat-option *ngFor="let operator of operators" [value]="operator"
                            [matTooltip]="operatorDatesToolTip(operator)"
                            matTooltipPosition="after"
                            [matTooltipDisabled]="!selectedTeam"
                            [disabled]="!operatorSelectable(operator)">
                    <span>{{ operator.userName }} <small>({{ operator.role?.title }})</small></span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [hidden]=!isLCDOrBetter>
            <mat-select formControlName="accountType"
                        placeholder="{{accountTypePlaceholder}}"
                        multiple>
                <mat-option *ngFor="let accountType of accountTypes"
                            [value]="accountType">
                    {{ accountType }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [hidden]="isClient && accounts?.length == 1">
            <mat-select formControlName="accounts"
                        placeholder="{{accountPlaceholder}}"
                        multiple
                        matTooltip="{{accountToolTip()}}"
                        matTooltipPosition="after"
                        matTooltipClass="tooltip-multiline"
                        #accountSelect>
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search account" noEntriesFoundLabel="Nothing found"
                                           [formControl]="accountSearchControl"></ngx-mat-select-search>
                </mat-option>
                <mat-select-trigger>
                    {{ selectedAccounts && selectedAccounts.length > 0 ? selectedAccounts[0].name.substr(0, 17) : '' }}
                    <span *ngIf="selectedAccounts && selectedAccounts.length > 1">
                    (+{{ selectedAccounts.length - 1 }})
                </span>
                    <button mat-icon-button class="clear" (click)="clearAccount($event);" type="button">
                        <i class="fa fa-times align-top"></i>
                    </button>
                </mat-select-trigger>

                <div *ngIf="accounts | genericFunctionFilter:
                {filterFns: [accountFilterFn], sortFn: accountSortFn, pipeForcedAt: accountFilterPipeForcedAt} as filteredAccounts">
                    <div class="select-all" *ngIf="filteredAccounts?.length > 3">
                        <mat-checkbox #allAccountsCheckbox
                                      [checked]="filteredAccounts?.length === selectedAccounts.length"
                                      (change)="toggleAllElements(allAccountsCheckbox, accountSelect)">
                            All <span [style.font-size]="'11px'" [style.color]="'grey'">({{ filteredAccounts.length }})</span>
                        </mat-checkbox>
                    </div>
                    <mat-option *ngFor="let account of filteredAccounts"
                                [value]="account">
                        {{ account.name }}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <div class="filter-showHybridsToggle" [hidden]="!showHybridsToggle || isOperator">
            <mat-slide-toggle color="red" formControlName="showHybrids" #showHybrids>
                <span style="color: #757575; font-size: 13px; font-family: inherit;">
                    {{ showHybrids.checked ? 'Client data' : 'Bconnect data' }}
                </span>
            </mat-slide-toggle>
        </div>
        <mat-form-field>
            <mat-select formControlName="domain"
                        placeholder="{{domainPlaceholder}}">
                <mat-option (click)="resetDomain()">
                    <span class="italic">No filter</span>
                </mat-option>
                <mat-option *ngFor="let domain of domains"
                            [value]="domain">
                    {{ domain.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="channels" multiple
                        placeholder="{{channelPlaceholder}}"
                        #channelSelect>
                <div class="select-all">
                    <mat-checkbox #allChannelsCheckbox
                                  (change)="toggleAllElements(allChannelsCheckbox, channelSelect)">All
                    </mat-checkbox>
                </div>
                <mat-option *ngFor="let channel of channels"
                            [value]="channel.toUpperCase()">
                    {{ channel }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="datePreset"
                        placeholder="{{datePresetPlaceholder}}">
                <mat-option *ngFor="let datePreset of datePresets"
                            [value]="datePreset.id">
                    {{ datePreset.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div formGroupName="dateRange">
            <mat-form-field>
                <input formControlName="start" matInput
                       [matDatepicker]="startDatePicker"
                       placeholder="{{startDatePlaceholder}}">
                <mat-datepicker-toggle matSuffix
                                       [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input formControlName="end" matInput
                       [matDatepicker]="endDatePicker"
                       placeholder="{{endDatePlaceholder}}">
                <mat-datepicker-toggle matSuffix
                                       [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="summarizeBy" class="filter-button"
                                 style="border: 1px solid #ccc;">
            <mat-button-toggle value="day" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;">Day
            </mat-button-toggle>
            <mat-button-toggle value="week">Week</mat-button-toggle>
            <mat-button-toggle value="month" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;">
                Month
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="filter-button">
            <button mat-raised-button type="submit"
                    class="generate"
                    [disabled]="coreFilterForm.invalid">
                Generate
            </button>
        </div>
    </form>
</div>

