<script src="../model/period-of-day.model.ts"></script>
<div style="font-size: 13px">
    <a *ngIf="!authService.isInAdminGroup && !authService.isTeamLead && !authService.isLcd" routerLink="default" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        {{authService.isOperator ? 'CO' :  ' '}} Home
    </a>
    <a *ngIf= authService.isAdmin routerLink="ceo-home" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        CEO Home
    </a>
    <a *ngIf="authService.isTeamLead || authService.currentUser.name === 'Enshore'" routerLink="teamlead-home" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        TL Home
    </a>
    <a *ngIf="authService.isLcd" routerLink="lcd-home" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        LCD Home
    </a>
    <a *ngIf="authService.isInAdminGroup || authService.isClient" routerLink="funnel" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Chat funnel
    </a>
    <a *ngIf="!authService.isClient" routerLink="funnel-operator" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Operator funnel
    </a>
    <a routerLink="chats-byclassification" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Chats by category
    </a>
    <a routerLink="performance" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Performance
    </a>
    <a *ngIf="authService.isInAdminGroup || authService.isClient" routerLink="word-cloud" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Word cloud
    </a>
    <a *ngIf="authService.isInAdminGroup || authService.isClient" routerLink="path-statistics" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Start page statistics
    </a>
<!--    <a routerLink="trigger-analytics" routerLinkActive="active"-->
<!--       [class.disabled]="buttonsDisabled">-->
<!--        Trigger analytics-->
<!--    </a>-->
    <a *ngIf="!authService.isOperatorGroup" routerLink="chats-period-distribution" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Chat distribution
    </a>
    <a *ngIf="authService.isInAdminGroup" routerLink="automated-responses" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Chat bot
    </a>
    <a *ngIf="authService.isInAdminGroup" routerLink="online-logging" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Online logging
    </a>
    <a routerLink="visitor-happiness" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Visitor happiness
    </a>
    <a routerLink="client-happiness" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Client happiness
    </a>
    <a *ngIf="!authService.isClient" routerLink="account-leaderboard" routerLinkActive="active" [class.disabled]="buttonsDisabled">
        Account leaderboard
    </a>
    <a *ngIf="!authService.isClient" routerLink="operator-leaderboard" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Operator Leaderboard
    </a>
    <a *ngIf="authService.isAdmin" routerLink="finance" routerLinkActive="active"
       [class.disabled]="buttonsDisabled">
        Finance
    </a>
</div>
