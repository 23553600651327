import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { LabeledLabelNumericData } from '../../model/labeled-label-numeric-data';
import { SingleLabeledNumericData } from '../../model/single-labeled-numeric-data.model';
import { LineChartData } from '../ceo-home/ceo-home.component';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
})
export class PerformanceComponent extends CoreParametersConsumer {
  subtitle: string;

  dataPickup$: Observable<any>;
  dataResponse$: Observable<any>;
  dataDuration$: Observable<any>;
  dataChatsPerHour$: Observable<any>;
  dataMissedChats$: Observable<any>;
  dataEuroPer$: Observable<any>;

  dataPickup;
  dataResponse;
  dataDuration;
  dataChatsPerHour;
  dataMissedChats;
  dataEuroPerHour;
  dataEuroPerChat;
  isOperator = true;

  dataSource;
  currentGraph: string = 'duration';
  errorMessage: string;
  options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      position: 'top',
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      },
    },
    tooltips: {
      intersect: false,
      mode: 'nearest',
    },
  };

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private colorService: ColorService,
              public authorizationService: AuthorizationService) {
    super(graphFilterService);
  }

  loadData(coreParameters: CoreParameters): void {
    this.subtitle = this.graphService.getLabel(coreParameters);
    this.isOperator = this.authorizationService.isOperator;
    this.dataPickup = undefined;
    this.dataDuration = undefined;
    this.dataResponse = undefined;
    this.dataChatsPerHour = undefined;
    this.dataMissedChats = undefined;
    this.dataEuroPerHour = undefined;
    this.dataEuroPerChat = undefined;

    this.dataEuroPer$ = this.graphService.getEuroPerChatAndHour(coreParameters);

    this.dataPickup$ = this.graphService.getAvgPickupTime(
      coreParameters).pipe(
      map((result: LabeledLabelNumericData) => {
        return {
          datasets: this.generateGraphData(this.colorService.getColor(0), 'Average pickup time', result),
          labels: result.labels,
        };
      }),
    );
    this.dataResponse$ = this.graphService.getAvgResponseTime(
      coreParameters).pipe(
      map((result: LabeledLabelNumericData) => {
          return {
            datasets: this.generateGraphData(this.colorService.getColor(0), 'Average response time', result),
            labels: result.labels,
          };
        },
      ),
    );
    this.dataDuration$ = this.graphService.getAvgDuration(
      coreParameters).pipe(
      map((result: LabeledLabelNumericData) => {
        return {
          datasets: this.generateGraphData(this.colorService.getColor(0), 'Average duration', result),
          labels: result.labels,
        };
      }),
    );
    this.dataChatsPerHour$ = this.graphService.getChatsPerOnlineHour(
      coreParameters).pipe(
      map((result: LabeledLabelNumericData) => {
        return {
          datasets: this.generateGraphData(this.colorService.getColor(0), 'Average chats per hour', result),
          labels: result.labels,
        };
      }),
    );
    this.dataMissedChats$ = this.graphService.getMissedChats(
      coreParameters).pipe(
      map((result: LabeledLabelNumericData) => {
        return {
          datasets: this.generateGraphData(this.colorService.getColor(0), '', result, false),
          labels: result.labels,
        };
      }),
    );
    this.setGraphType(this.currentGraph);
  }

  setGraphType(graph: string): void {
    this.currentGraph = graph;
    switch (graph) {
      case 'duration' : {
        if (!this.dataDuration) {
          this.dataDuration$.subscribe((data) => {
            this.dataDuration = data;
            this.dataSource = this.dataDuration;
          });
        } else {
          this.dataSource = this.dataDuration;
        }
        // this.subtitle = 'average duration in seconds';
        break;
      }
      case 'pickup time' : {
        if (!this.dataPickup) {
          this.dataPickup$.subscribe((data) => {
            this.dataPickup = data;
            this.dataSource = this.dataPickup;
          });
        } else {
          this.dataSource = this.dataPickup;
        }
        // this.subtitle = 'average pickup time in seconds';
        break;
      }
      case 'response time' : {
        if (!this.dataResponse) {
          this.dataResponse$.subscribe((data) => {
            this.dataResponse = data;
            this.dataSource = this.dataResponse;
          });
        } else {
          this.dataSource = this.dataResponse;
        }
        // this.subtitle = 'average response time in seconds';
        break;
      }

      case 'average chats per hour' : {
          if (!this.dataChatsPerHour) {
            this.dataChatsPerHour$.subscribe((data) => {
              this.dataChatsPerHour = data;
              this.dataSource = this.dataChatsPerHour;
            });
          } else {
            this.dataSource = this.dataChatsPerHour;
          }
          // this.subtitle = 'average chats per hour';
          break;
      }
      case 'missed chats' : {
        if (!this.dataMissedChats) {
          this.dataMissedChats$.subscribe((data) => {
            this.dataMissedChats = data;
            this.dataSource = this.dataMissedChats;
          });
        } else {
          this.dataSource = this.dataMissedChats;
        }
        // this.subtitle = 'missed chats';
        break;
      }
      case 'euro per chat' : {
        if (!this.dataEuroPerChat) {
          this.generatePerEuro('dataEuroPerChat');
        } else {
          this.dataSource = this.dataEuroPerChat;
        }
        // this.subtitle = '€ per chat';
        break;
      }
      case 'euro per hour' : {
        if (!this.dataEuroPerHour) {
          this.generatePerEuro('dataEuroPerHour');
        } else {
          this.dataSource = this.dataEuroPerHour;
        }
        // this.subtitle = '€ per hour';
        break;
      }
    }
    this.currentGraph = graph;
  }

  generateCategory(label: string, dataLabel: string, data: LabeledLabelNumericData): LineChartData {
    return {
      datasets: [{
        borderColor: this.colorService.getColor(0),
        data: data.data.find((x) => x.label === dataLabel).data,
        fill: false,
        label,
      },
        this.generateMeanGraphData(data.data.find((x) => x.label === dataLabel), '#9e9e9e', false),
      ],
      labels: data.labels,
    };
  }

  generatePerEuro(category: string) {
    if (this.dataEuroPer$ !== undefined) {
      this.dataEuroPer$.subscribe((data) => {
        this.dataEuroPerHour = this.generateCategory('€ per Hour', 'Euro per Hour', data);
        this.dataEuroPerChat = this.generateCategory('€ per chat', 'Euro per Chat', data);
        if (category !== null) {
          this.dataSource = this[category];
        }
      });
    }
  }

  generateGraphData(color: string, dataName: string, result: LabeledLabelNumericData, generateMeans = true): any[] {
    const datasets = [];
    let hide = false;
    let meanColor = '#B0B0B0';
    result.data.forEach((x: SingleLabeledNumericData) => {
      if (x.data.length > 0) {
        this.errorMessage = null;
        datasets.push(
          {
            borderColor: color,
            data: x.data,
            fill: false,
            hidden: hide,
            label: dataName + ' ' + x.label,
          },
        );
        if (generateMeans) {
          datasets.push(this.generateMeanGraphData(x, meanColor, hide));
        }
      } else {
        this.errorMessage = 'There is no data available';
      }
      hide = true;
      color = meanColor = '#9e9e9e';
    });
    return datasets;
  }

  generateMeanGraphData(result: SingleLabeledNumericData, color: string, hide: boolean): any {
    return {
      borderColor: color,
      borderDash: [10, 15],
      data: this.fillArrayWithMeanData(result),
      fill: false,
      hidden: hide,
      label: 'Average line' + ' ' + result.label,
      pointBorderWidth: 0,
      radius: 0,
    };
  }

  fillArrayWithMeanData(result: SingleLabeledNumericData): number[] {
    const meanArray: number[] = Array(result.data.length);
    if (!this.errorMessage) {
      meanArray.fill(result.dataAverage);
    }
    return meanArray;
  }
}
