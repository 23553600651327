<div *ngIf="!faqInEdit">
    <div class="flex-center pt1em">
        <app-small-button (onClick)="addFaq()"
                          [color]="'white'"
                          [bold]="true"
                          [label]="'Add FAQ'"></app-small-button>
    </div>

    <div class="p1em" *ngIf="showClientInfoMessage">
        <i class="fas fa-info-circle bconnect-blue pr1em"></i>
        <span style="font-size: 14px">Please select an faq addition below, edit it if required, and send it back with an optional comment.</span>
    </div>
    <div *ngIf="(review.faqAddition | genericFunctionFilter: {filterFns: [notAddedByClientFn], sortFn: faqSortFn, pipeForcedAt: pipeForcedAt}) as filtered">
        <div *ngIf="filtered.length > 0">
            <mat-list>
                <mat-list-item class="faq-list-item"
                               *ngFor="let f of filtered; let i = index"
                               [style.cursor]="'pointer'">
                    <div (click)="editFaq(f)"

                         class="row" style="width: 89%; height: 90%">
                        <div>
                            <span [style.font-size.px]="10"> {{processStageText(f)}}</span>
                        </div>
                        <div>
                            <span [style.font-size.px]="10">Initiated by</span><span [style.font-size.px]="12">
                        {{f.createdBy.userName === authorizationService.currentUser.name ? 'YOU' : f.createdBy.screenName}}</span>
                        </div>
                        <div style="width:89%;">
                            <span>{{faqAdditionLabel(f)}}</span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div *ngIf="(review.faqAddition | genericFunctionFilter: {filterFns: [addedByClientFn], sortFn: faqSortFn, pipeForcedAt: pipeForcedAt}) as filtered">
        <div *ngIf="filtered.length > 0">
            <h5 style="text-align: center">Client FAQ additions</h5>
            <mat-list>
                <mat-list-item class="faq-list-item"
                               *ngFor="let f of filtered; let i = index"
                               [style.cursor]="'pointer'">
                    <div (click)="editFaq(f)"

                         class="row" style="width: 89%; height: 90%">
                        <div>
                            <span [style.font-size.px]="10"> {{processStageText(f)}}</span>
                        </div>
                        <div>
                            <span [style.font-size.px]="10">Initiated by</span><span [style.font-size.px]="12">
                        {{f.createdBy.userName === authorizationService.currentUser.name ? 'YOU' : f.createdBy.screenName}}</span>
                        </div>
                        <div style="width:89%;">
                            <span>{{faqAdditionLabel(f)}}</span>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>

<app-faq-dialog *ngIf="faqInEdit"
                [review]="review"
                [faqAddition]="faqInEdit"
                [products]="products"
                [canEdit]="canEdit(faqInEdit)"
                (closedEvent)="onClose($event)"></app-faq-dialog>
