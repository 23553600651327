import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { ColorService } from '../graph/graph-services/color.service';
import { SharedModule } from '../shared/shared.module';
import { ChatArchiveDetailsComponent } from './chat-archive/chat-archive-details/chat-archive-details.component';
import { ChatArchiveComponent } from './chat-archive/chat-archive.component';
import { ClientRemarkViewComponent } from './chat-archive/client-remark-view/client-remark-view.component';
import { OperatorRemarkViewComponent } from './chat-archive/operator-remark-view/operator-remark-view.component';
import { SessionClickPathComponent } from './chat-archive/session-clickpath/session-click-path.component';
import { SessionDetailsComponent } from './chat-archive/session-details/session-details.component';
import { VisitorRemarkViewComponent } from './chat-archive/visitor-remark-view/visitor-remark-view.component';
import { ClientFaqDialogComponent } from './tickets/feedback-client-faqs/client-faq-dialog/client-faq-dialog.component';
import { FeedbackClientFaqsComponent } from './tickets/feedback-client-faqs/feedback-client-faqs.component';
import { SessionDetailsDialogComponent } from './tickets/feedback-client-faqs/session-details-dialog/session-details-dialog.component';
import { FeedbackClientFeedbackComponent } from './tickets/feedback-client-feedback/feedback-client-feedback.component';
import { FeedbackBconnectRatingComponent } from './tickets/feedback-details/feedback-bconnect-rating/feedback-bconnect-rating.component';
import { ClassificationFieldsComponent } from './tickets/feedback-details/feedback-correction/classification-fields/classification-fields.component';
import { FeedbackCorrectionComponent } from './tickets/feedback-details/feedback-correction/feedback-correction.component';
import { FeedbackDetailsComponent } from './tickets/feedback-details/feedback-details.component';
import { FaqDialogComponent } from './tickets/feedback-details/feedback-faqs/faq-dialog/faq-dialog.component';
import { FeedbackFaqsComponent } from './tickets/feedback-details/feedback-faqs/feedback-faqs.component';
import { ProcessFaqComponent } from './tickets/feedback-details/feedback-faqs/process-faq/process-faq.component';
import { FeedbackOperatorErrorsComponent } from './tickets/feedback-details/feedback-operator-errors/feedback-operator-errors.component';
import { FeedbackQualityComponent } from './tickets/feedback-details/feedback-quality/feedback-quality.component';
import { FeedbackTopChatComponent } from './tickets/feedback-details/feedback-top-chat/feedback-top-chat.component';
import { FeedbackRecievedComponent } from './tickets/feedback-recieved/feedback-recieved.component';
import { TicketsComponent } from './tickets/tickets.component';
import { StandardFeedbackTableComponent } from './tickets/standard-feedback-table/standard-feedback-table.component';
import { HqReviewComponent } from './hq-review/hq-review.component';
import { LangReviewComponent } from './lang-review/lang-review.component';
import { MenuComponent } from './menu/menu.component';
import { ClassifyComponent } from './open-review/classify/classify.component';
import { OpenReviewMessagingComponent } from './open-review/open-review-messaging/open-review-messaging.component';
import { OpenReviewComponent } from './open-review/open-review.component';
import { OverviewComponent } from './overview/overview.component';
import { QueueErrorComponent } from './queue-error/queue-error.component';
import { ReviewDetailsComponent } from './review/review-details/review-details.component';
import { ReviewDialogComponent } from './review/review-dialog/review-dialog.component';
import { ReviewComponent } from './review/review.component';
import { AlertifyService } from './service/alertify.service';
import { FeedbackService } from './service/feedback.service';
import { MessageService } from './service/message.service';
import { OpenReviewService } from './service/open-review.service';
import { ReviewService } from './service/review.service';
import { SessionChatInfoService } from './service/session-chat-info.service';
import { SupportService } from './service/support.service';
import { CloseReviewComponent } from './shared/close-review/close-review.component';
import { MessageHistoryComponent } from './shared/messaging/message-history/message-history.component';
import { MessageSendComponent } from './shared/messaging/message-send/message-send.component';
import { MessagingComponent } from './shared/messaging/messaging.component';
import { NoDataComponent } from './shared/no-data/no-data.component';
import { SimpleMessagingHistoryComponent } from './shared/simple-messaging/simple-messaging-history/simple-messaging-history.component';
import { SimpleMessagingComponent } from './shared/simple-messaging/simple-messaging.component';
import { SubscriptionComponent } from './shared/subscription/subscription.component';
import { SupportCoreFilterComponent } from './support-core-filter/support-core-filter.component';
import { SupportCoreFrontFilterComponent } from './support-core-filter/support-core-front-filter/support-core-front-filter.component';
import { SupportFilterService } from './support-core-filter/support-filter.service';
import { SupportRoutingModule } from './support-routing.module';
import { SupportComponent } from './support.component';
import { TranscriptComponent } from './transcript/transcript.component';
import { AcceptedCorrectionsDialogComponent } from './updates/accepted-corrections-dialog/accepted-corrections-dialog.component';
import { AcceptedFaqsDialogComponent } from './updates/accepted-faqs-dialog/accepted-faqs-dialog.component';
import { StandardUpdatesTableComponent } from './updates/standard-updates-table/standard-updates-table.component';
import { UpdatesComponent } from './updates/updates.component';
import { ChatMessagesComponent } from './transcript/chat-messages/chat-messages.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
    declarations: [
        SessionDetailsComponent,
        SupportComponent,
        MenuComponent,
        ChatArchiveComponent,
        SupportCoreFilterComponent,
        SupportCoreFrontFilterComponent,
        ChatArchiveDetailsComponent,
        ReviewComponent,
        ReviewDetailsComponent,
        MessageSendComponent,
        TicketsComponent,
        FeedbackQualityComponent,
        FeedbackOperatorErrorsComponent,
        MessageHistoryComponent,
        FeedbackTopChatComponent,
        FeedbackCorrectionComponent,
        TicketsComponent,
        FeedbackRecievedComponent,
        ReviewDialogComponent,
        FeedbackDetailsComponent,
        MessagingComponent,
        StandardFeedbackTableComponent,
        FeedbackBconnectRatingComponent,
        UpdatesComponent,
        StandardUpdatesTableComponent,
        NoDataComponent,
        OverviewComponent,
        FeedbackFaqsComponent,
        CloseReviewComponent,
        SubscriptionComponent,
        FeedbackClientFeedbackComponent,
        FaqDialogComponent,
        ProcessFaqComponent,
        SimpleMessagingComponent,
        SimpleMessagingHistoryComponent,
        ClientRemarkViewComponent,
        OperatorRemarkViewComponent,
        VisitorRemarkViewComponent,
        AcceptedFaqsDialogComponent,
        SessionClickPathComponent,
        AcceptedCorrectionsDialogComponent,
        ClassificationFieldsComponent,
        FeedbackClientFaqsComponent,
        ClientFaqDialogComponent,
        SessionDetailsDialogComponent,
        OpenReviewComponent,
        LangReviewComponent,
        TranscriptComponent,
        ClassifyComponent,
        OpenReviewMessagingComponent,
        HqReviewComponent,
        OpenReviewComponent,
        QueueErrorComponent,
        ChatMessagesComponent
    ],
    exports: [
        ReviewComponent,
        SupportComponent,
    ],
    imports: [
        SupportRoutingModule,
        SharedModule,
        MatListModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule,
    ],
    providers: [
        SupportFilterService,
        SupportService,
        ColorService,
        ReviewService,
        AlertifyService,
        MessageService,
        SessionChatInfoService,
        FeedbackService,
        OpenReviewService,
    ]
})
export class SupportModule { }
