<div align="center">
    <div *ngIf="hideFullReview"
         [style.margin.px]="20"
         [style.color]="'grey'">
        <span>This chat has not yet been reviewed.</span>
    </div>
    <div *ngIf="!hideFullReview">
        <div align="center"><p></p><label>Bconnect Rating</label></div>
        <div align="center">
            <button mat-button (click)="setBconnectRating(10)" [disabled]="disabled">
                <i class="far fa-laugh-beam" [ngStyle]="{'color': happinessColor(10)}"
                   style="font-size: 28px;"></i>
            </button>
            <button mat-button (click)="setBconnectRating(6)" [disabled]="disabled">
                <i class="far fa-meh" [ngStyle]="{'color': happinessColor(6)}" style="font-size: 28px;"></i>
            </button>
            <button mat-button (click)="setBconnectRating(2)" [disabled]="disabled">
                <i class="far fa-frown" [ngStyle]="{'color': happinessColor(2)}" style="font-size: 28px;"></i>
            </button>
        </div>
    </div>
    <mat-tab-group #tabGroup [dynamicHeight]="true"
                   [disableRipple]="true"
                   [animationDuration]="'0'"
                   style="margin-top: 7px">
        <mat-tab label="10-STEPS" *ngIf="!hideFullReview">
            <ng-template mat-tab-label>
                10 Steps
            </ng-template>
            <app-feedback-quality *ngIf="reviewService.review"
                                  [review]="reviewService.review">
            </app-feedback-quality>
        </mat-tab>
        <mat-tab label="ERRORS" *ngIf="!hideFullReview">
            <ng-template mat-tab-label>
                Errors
            </ng-template>
            <app-feedback-operator-errors *ngIf="reviewService.review.conversationUid === conversationUid"
                                          [review]="reviewService.review"
                                          [operatorErrorOptions]="operatorErrorOptions">
            </app-feedback-operator-errors>
        </mat-tab>
        <mat-tab label="FEEDBACK">
            <ng-template mat-tab-label>
                Discuss
            </ng-template>

            <div class="p1em" [style.font-size.px]="15" *ngIf="!!review.feedbackFlowResolvedAt">
                Resolved on {{review.feedbackFlowResolvedAt | date}}
            </div>

            <div class="pt1em">
                <app-small-button (onClick)="resolveFeedback()"
                                  *ngIf="isSupervisorForReview()"
                                  [disabled]="!!review.feedbackFlowResolvedAt"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Resolve'"></app-small-button>
                <app-small-button (onClick)="markAsSeen('FEEDBACK')"
                                  *ngIf="hasAction('FEEDBACK') && !!review.feedbackFlowResolvedAt"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Mark as seen'"></app-small-button>
                <app-small-button (onClick)="markAsSeen('REVIEW')"
                                  *ngIf="hasAction('REVIEW') && !!review.feedbackFlowResolvedAt"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Mark as seen'"></app-small-button>

                <app-messaging (emitMessage)="sendReviewMessage($event)"
                               [conversationUid]="review.conversationUid"
                               [reviewMessages]="reviewMessages"
                               [disabled]="isOperator && !!review.feedbackFlowResolvedAt"
                               [type]="'FEEDBACK'">
                </app-messaging>


            </div>
        </mat-tab>
        <mat-tab label="TOPCHAT" *ngIf="topChatAvailable">
            <ng-template mat-tab-label>
                Top Chat
            </ng-template>
            <app-feedback-top-chat class="center-button" *ngIf="reviewService.review"
                                   [review]="reviewService.review">

            </app-feedback-top-chat>

            <app-small-button (onClick)="markAsSeenEvent.emit('TOPCHAT')"
                              *ngIf="hasUpdate('TOPCHAT')"
                              [color]="'white'"
                              [bold]="true"
                              [label]="'Mark as seen'"></app-small-button>
        </mat-tab>
    </mat-tab-group>
</div>
