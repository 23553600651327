<section class="row">
    <mat-card class="full-card" *ngIf="dataSourceOperator">
        <mat-card-header >
            <mat-card-title>
                Operators
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-table #table [dataSource]="dataSourceOperator">
                <ng-container *ngFor="let c of columnsOperator" [matColumnDef]="c">
                    <mat-header-cell *matHeaderCellDef>{{getColumnHeader(c)}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element[c]}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsOperator"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsOperator;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
    <mat-card class="full-card" *ngIf="dataSourceTL">
        <mat-card-header >
            <mat-card-title>
                Team Leads
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-table #table [dataSource]="dataSourceTL">
                <ng-container *ngFor="let c of columnsTL" [matColumnDef]="c">
                    <mat-header-cell *matHeaderCellDef>{{getColumnHeader(c)}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element[c]}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsTL"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsTL;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
    <mat-card class="full-card" *ngIf="dataSourceLCD">
        <mat-card-header >
            <mat-card-title>
                LCD's
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-table #table [dataSource]="dataSourceLCD">
                <ng-container *ngFor="let c of columnsLCD" [matColumnDef]="c">
                    <mat-header-cell *matHeaderCellDef>{{getColumnHeader(c)}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element[c]}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsLCD"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsLCD;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</section>
