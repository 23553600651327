<section class="row"  *ngIf = "!conversationUid && !hasData" >
    <app-no-data [title]="'New ' + title"></app-no-data>
</section>
<section class="row"  *ngIf="!conversationUid && hasData" >
    <mat-card class="full-card">
        <mat-card-header >
            <mat-card-title>
                {{title}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content >
            <mat-table class="archive-table" [dataSource]="dataSource" matSort matSortActive="conversationUid"
                       matSortDirection="desc" matSortDisableClear="true">
                <ng-container matColumnDef="reviewDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Review date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.reviewDate | date :'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="conversationDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Conversation date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.conversationDate | date :'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="uid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chat id</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="transparent-button pointer"  style="font-weight: bold;"
                                [style.color]="waitForLanguageReview(element) ? 'grey' : '#141464'"
                                [disabled]="waitForLanguageReview(element)"
                                (click)="selectUid(element)">
                            {{element.uid}}
                        </button>
                        <i class="fas fa-language"
                           [style.color]="'red'"
                           *ngIf="waitForLanguageReview(element)" title="Has pending language review"></i>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="assignedTo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned to</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.assignedTo?.screenName || '-'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Account</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.account}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.category}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="classificationCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Classification category</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.classificationCategory}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="department">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Department</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.department}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="servedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Served by</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.servedBy}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visitorRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visitor rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.visitorRating != null && element.visitorRating > 0" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.visitorRating)}}/>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="clientRating">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Client rating</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img *ngIf="element.clientRating != null && element.clientRating > 0" alt="good" style="display:block;" width="35%"
                             height="35%" class="center-image" src={{getImgSource(element.clientRating)}}/>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [length]=tableDataLength
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" *ngIf="conversationUid">
    <div class="two-third-card">
        <mat-card class="full-card">
            <div class="flex-center">

                <div style="line-height: 2">
                    <span style="font-weight: bold">{{selectedOpenReview.uid}}</span>
                     - {{selectedOpenReview.account}}
                </div>

            </div>
        </mat-card>
        <app-transcript [servedBy]="selectedOpenReview.servedBy"
                        [messages]="messages | async"
                        [messageUpdateFn]="updateMessageFn"
                        [maxHeight]="'90vh'"
                        [editable]="!resolved"></app-transcript>
    </div>
    <mat-card id="open-review-card" class="third-card" style="padding-top: 15px !important;">
        <div class="flex-between">
            <div class="w50 flex">
                <div>
                    <app-small-button (onClick)="resolve()"
                                      [color]="'white'"
                                      [disabled]="resolved && reviewType !== 'QUEUE_ERROR'"
                                      [bold]="true"
                                      [label]="'Resolve'"></app-small-button>
                    <i class="fas fa-exclamation-circle" style="color: red; padding-left: 10px" *ngIf="error"></i>
                    <i class="fas fa-check" style="color: green; padding-left: 10px" *ngIf="resolved"></i>
                </div>
                <app-small-button style="margin-left: 0.5em;" id="assign-button" (onClick)="assign()"
                                  [color]="'white'"
                                  [bold]="true"
                                  [label]="'Assign'"></app-small-button>
            </div>

            <div class="w50 flex-end">
                <button *ngIf="canNavigate()" class="transparent-button pointer" style="font-size: 24px;"
                        (click)="selectPrevious()">
                    <i class="fas fa-step-backward"></i>
                </button>
                <button *ngIf="canNavigate()" class="transparent-button pointer" style="font-size: 24px; margin-left: 0.5em;"
                        (click)="selectNext()">
                    <i class="fas fa-step-forward"></i>
                </button>
                <button class="transparent-button pointer" style="font-size: 24px; margin-left: 1em"
                        title="Back to overview" (click)="unsetConversationUid()">
                    <i class="fas fa-arrow-alt-circle-left"></i>
                </button>
            </div>
        </div>

        <div style="margin-bottom: 30px;" *ngIf="hasPendingReviewOfType(selectedOpenReview,'HQ')">
            <mat-card-title>HQ Review Messages</mat-card-title>
            <app-open-review-messaging [staticMessages]="staticMessages"></app-open-review-messaging>
        </div>
        <div style="text-align: center; padding: 25px;"
             *ngIf="!hasPendingReviewOfType(selectedOpenReview,'HQ') && selectedOpenReview.reviewType !== 'QUEUE_ERROR'">
            No pending HQ review
        </div>
        <div style="text-align: center; padding: 25px; color: red"
             *ngIf="selectedOpenReview.message">{{selectedOpenReview.message}}
        </div>
        <div>

            <mat-card-title>Classification</mat-card-title>
            <app-classify [chatClassification]="chatClassification"
                          [enabled]="!resolved || reviewType === 'QUEUE_ERROR'"
                          [openReview]="selectedOpenReview"
                          (validatorEvent)="updateClassificationValidity($event)"
                          *ngIf="chatClassification"></app-classify>
        </div>
    </mat-card>
</section>


