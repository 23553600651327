<section class="row" *ngIf="scores">
    <app-teamlead-scores class="third-card left" title= 'Total Happiness'
                         [teamScore]="teamTotalScore" [orgScore]="organizationTotalScore" [decimalFormatter]="'1.0-2'"
                         [cardClass]="cardClass" [colorClass]="greaterThanAlertFn(teamTotalScore, organizationTotalScore)" [noOfCards]=6>
    </app-teamlead-scores>
    <app-teamlead-scores class="third-card left" title= 'Average € per hour'
                         [teamScore]="scores?.teamEuroPerHour" [orgScore]="scores?.organizationEuroPerHour"
                         [prefix]="'€'" [decimalFormatter]="'1.0-2'"
                         [cardClass]="cardClass" [colorClass]="greaterThanAlertFn(scores?.teamEuroPerHour, scores?.organizationEuroPerHour)" [noOfCards]=6>
    </app-teamlead-scores>
    <app-teamlead-scores class="third-card left" title= 'Average chats per hour'
                         [teamScore]="scores?.teamChatsPerHour" [orgScore]="scores?.organizationChatsPerHour" [decimalFormatter]="'1.0-1'"
                         [cardClass]="cardClass" [colorClass]="greaterThanAlertFn(scores?.teamChatsPerHour, scores?.organizationChatsPerHour)" [noOfCards]=6>
    </app-teamlead-scores>
    <app-teamlead-scores class="third-card left" title= '%Leads'
                         [teamScore]="scores?.teamLeads" [orgScore]="scores?.organizationLeads" [postfix]="'%'" [decimalFormatter]="'1.0-2'"
                         [cardClass]="cardClass" [colorClass]="greaterThanAlertFn(scores?.teamLeads, scores?.organizationLeads)" [noOfCards]=6>
    </app-teamlead-scores>
    <app-teamlead-scores class="third-card left" title= '%Wasted'
                         [teamScore]="scores?.teamWasted" [orgScore]="scores?.organizationWasted" [postfix]="'%'" [decimalFormatter]="'1.0-2'"
                         [cardClass]="cardClass" [colorClass]="smallerThanAlertFn(scores?.teamWasted, scores?.organizationWasted)" [noOfCards]=6>
    </app-teamlead-scores>
    <app-teamlead-scores class="third-card left" title= 'Workload'
                         [teamScore]="scores?.teamWorkload" [orgScore]="100" [postfix]="'%'" [decimalFormatter]="'1.0-0'"
                         [cardClass]="cardClass" [colorClass]="greaterThanAlertFn(scores?.teamWorkload, 100)" [noOfCards]=6>
    </app-teamlead-scores>
</section>
<section class="row">
    <mat-card class="full-card limited-larger" *ngIf="isLineChartInitialized" >
        <mat-card-header >
            <div class="toggles">
                <mat-button-toggle-group #Cogroup="matButtonToggleGroup" value="menuHappiness">
                    <mat-button-toggle value="happinessGraphs" [matMenuTriggerFor]="ComenuHappiness">
                        {{'Happiness: ' +'▼'}}
                    </mat-button-toggle>
                    <mat-menu #ComenuHappiness="matMenu">
                        <button mat-menu-item (click)="setGraphType('Total Score')">Total happiness</button>
                        <button mat-menu-item (click)="setGraphType('Client Score')">Client happiness</button>
                        <button mat-menu-item (click)="setGraphType('Visitor Score')">Visitor happiness</button>
                    </mat-menu>
<!--                    <mat-button-toggle value="average euro per hour"-->
<!--                                       (click)="setGraphType('Euro per hour')">€ per period-->
<!--                    </mat-button-toggle>-->
<!--                    <mat-button-toggle value="average chats per hour"-->
<!--                                       (click)="setGraphType('Chats per hour')">Chats per period-->
<!--                    </mat-button-toggle>-->
                </mat-button-toggle-group>

            </div>
            <mat-card-title>
                {{'Chat Operator Home'}}<span class="subtitle">{{subtitle}}</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content  >
            <p-chart  type="line" [data]="dataSource" [options]="options" height="85%" ></p-chart>
        </mat-card-content>
    </mat-card>
</section>
<section class="row" [hidden]="isLineChartInitialized" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
