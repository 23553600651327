<section class="row">
    <mat-card class="half-card left">

        <mat-card-header>
            <mat-card-title>Chat bot <span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>


        <mat-card-content *ngIf="data">


            <div class="w100 flex-column p1em">

                <div class="flex w100 pb1em">
                    <div class="w40">

                    </div>
                    <div class="w15" [style.color]="'grey'">
                        Total
                    </div>
                    <div class="w15" [style.color]="'grey'">
                        CR X/Chats
                    </div>
                    <div class="w15" [style.color]="'grey'">
                        CR Automated
                    </div>
                    <div class="w15" [style.color]="'grey'">
                        Visitor rating
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats
                    </div>
                    <div class="w15">
                        {{data.chats}}
                    </div>
                    <div class="w15" *ngIf="data?.chats > 0">
                        100%
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats proactive
                    </div>
                    <div class="w15">
                        {{data.chatsProactive}}
                    </div>
                    <div class="w15">
                        <span *ngIf="data?.chatsProactive > 0">{{(data.chatsProactive / data.chats * 100) | number:'1.0-2'}}%</span>
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats passive
                    </div>
                    <div class="w15">
                        {{data.chatsPassive}}
                    </div>
                    <div class="w15">
                        <span *ngIf="data?.chatsPassive > 0">{{(data.chatsPassive / data.chats * 100) | number:'1.0-2'}}%</span>
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats bot total
                    </div>
                    <div class="w15">
                        {{data.chatsAutomated}}
                    </div>
                    <div class="w15">
                        <span *ngIf="data?.chatsAutomated > 0">{{(data.chatsAutomated / data.chats * 100) | number:'1.0-2'}}%</span>
                    </div>
                    <div class="w15" *ngIf="data.chatsAutomated > 0">
                        100%
                    </div>
                    <div class="w15">
                        {{data.visitorRatingAutomated ? (data.visitorRatingAutomated | number: '1.1-2') : '-'}}
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats bot only
                    </div>
                    <div class="w15">
                        {{data.chatsFullyAutomated}}
                    </div>
                    <div class="w15">
                        <span *ngIf="data.chatsFullyAutomated > 0">{{(data.chatsFullyAutomated / data.chats * 100) | number:'1.0-2'}}%</span>
                    </div>
                    <div class="w15">
                        <span *ngIf="data.chatsFullyAutomated + data.chatsHandover > 0">
                            {{(data.chatsFullyAutomated / (data.chatsFullyAutomated + data.chatsHandover) * 100) | number:'1.0-2' || '0'}}%
                        </span>
                    </div>
                    <div class="w15">
                        {{data.visitorRatingFullyAutomated ? (data.visitorRatingFullyAutomated | number: '1.1-2') : '-'}}
                    </div>
                </div>

                <div class="flex w100 data-row">
                    <div class="w40">
                        Chats handover
                    </div>
                    <div class="w15">
                        {{data.chatsHandover}}
                    </div>
                    <div class="w15">
                        <span *ngIf="data.chatsHandover > 0">{{(data.chatsHandover / data.chats * 100) | number:'1.0-2'}}%</span>
                    </div>
                    <div class="w15">
                        <span *ngIf="data.chatsFullyAutomated + data.chatsHandover > 0">
                            {{(data.chatsHandover / (data.chatsFullyAutomated + data.chatsHandover) * 100) | number:'1.0-2' || '0'}}%
                        </span>
                    </div>
                    <div class="w15">
                        {{data.visitorRatingHandover ? (data.visitorRatingHandover | number: '1.1-2') : '-'}}
                    </div>
                </div>

                <div *ngFor="let counts of data.countsAll; let i = index">
                    <div class="flex w100 data-row" >
                        <div class="w40">
                            {{counts.automatedResponseFlowId}}
                        </div>
                        <div class="w15">
                            {{counts.chats}}
                        </div>
                        <div class="w15">
                            <span *ngIf="counts?.chats > 0">{{(counts.chats / data.chats * 100) | number:'1.0-2'}}%</span>
                        </div>
                        <div class="w15">
                            <span *ngIf="data.chatsFullyAutomated + data.chatsHandover > 0">
                                {{(counts.chats / (data.chatsFullyAutomated + data.chatsHandover) * 100) | number:'1.0-2' || '0'}}%
                            </span>
                        </div>
                        <div class="w15">
                            {{counts.visitorRating | number:'1.1-2' || '-'}}
                        </div>
                    </div>

                    <div class="flex-start w100 data-row"
                         *ngFor="let label of counts.chatsByClassification.labels; let i = index" [style.opacity]="0.7"
                         [style.font-size.%]="90">
                        <div class="pl1em w40">
                            {{label}}
                        </div>
                        <div class="w15">
                            {{counts.chatsByClassification.data[i]}}
                        </div>
                        <div class="w15">
                            <span *ngIf="counts?.chatsByClassification?.data[i] > 0">{{(counts.chatsByClassification.data[i] / data.chats * 100) | number:'1.0-2'}}%</span>
                        </div>
                        <div class="w15">
                        <span *ngIf="data.chats > 0">
                            {{(counts.chatsByClassification.data[i] / data.chats * 100) | number:'1.0-2' || '0'}}%
                        </span>
                        </div>
                    </div>
                </div>
            </div>


        </mat-card-content>

    </mat-card>

    <mat-card class="half-card right">

        <mat-card-header>
            <mat-card-title>Chat bot - bot only <span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>


        <mat-card-content *ngIf="data?.countsFullyAutomated">

            <app-automated-response-counts [counts]="data.countsFullyAutomated"
                                           [chats]="data.chats"
                                           [automatedChats]="data.chatsHandover + data.chatsFullyAutomated">
            </app-automated-response-counts>

        </mat-card-content>

    </mat-card>

</section>

<section class="row">

    <mat-card class="half-card left">

        <mat-card-header>
            <mat-card-title>Chat bot - handover <span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="data?.countsHandover">

            <app-automated-response-counts [counts]="data.countsHandover"
                                           [chats]="data.chats"
                                           [automatedChats]="data.chatsHandover + data.chatsFullyAutomated">
            </app-automated-response-counts>

        </mat-card-content>

    </mat-card>

    <mat-card class="half-card right">

        <mat-card-header>
            <mat-card-title>Chat bot - proactive <span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>


        <mat-card-content *ngIf="data?.countsProactive">

            <app-automated-response-counts [counts]="data.countsProactive"
                                           [chats]="data.chats"
                                           [automatedChats]="data.chatsHandover + data.chatsFullyAutomated">
            </app-automated-response-counts>


        </mat-card-content>

    </mat-card>

</section>

<section class="row">

    <mat-card class="half-card left">

        <mat-card-header>
            <mat-card-title>Chat bot - passive <span class="subtitle">{{subtitle}}</span></mat-card-title>
        </mat-card-header>


        <mat-card-content *ngIf="data?.countsPassive">

            <app-automated-response-counts [counts]="data.countsPassive"
                                           [chats]="data.chats"
                                           [automatedChats]="data.chatsHandover + data.chatsFullyAutomated">
            </app-automated-response-counts>

        </mat-card-content>

    </mat-card>

</section>
