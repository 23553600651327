<div *ngIf="dataSource">
    <section class="row" *ngIf ="dataSource" [hidden]="errorMessage">
        <mat-card class="full-card">
            <mat-card-header class="mat-card-header-operator-funnel">
                <mat-card-title>Operator funnel
                    <span class="subtitle" *ngIf="subtitle">{{subtitle}}</span></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="label">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.label}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.total}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="percentageOfTotalChats">
                        <mat-header-cell *matHeaderCellDef>X/Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.percentageOfTotalChats | number:'1.2-2' | percentConcat}}</mat-cell>
                    </ng-container>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </section>
    <section class="row" *ngIf ="classificationInviteDataSource" [hidden]="errorMessage">
        <mat-card class="full-card">
            <mat-card-header class="mat-card-header-chats-invitation">
                <mat-card-title>Chats following an invitation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-table #table [dataSource]="classificationInviteDataSource">
                    <ng-container matColumnDef="label">
                        <mat-header-cell *matHeaderCellDef>Classification</mat-header-cell>
                        <mat-cell class="wider" *matCellDef="let element">{{element.label}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.total}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="percentageOfTotalChats">
                        <mat-header-cell *matHeaderCellDef>X/Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.percentageOfTotalChats | number:'1.2-2' | percentConcat}}</mat-cell>
                    </ng-container>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </section>
    <section class="row" *ngIf ="classificationNoInviteDataSource" [hidden]="errorMessage">
        <mat-card class="full-card">
            <mat-card-header class="mat-card-header-chats-no-invite">
                <mat-card-title>Chats started without an invite</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-table #table [dataSource]="classificationNoInviteDataSource">
                    <ng-container matColumnDef="label">
                        <mat-header-cell *matHeaderCellDef>Classification</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.label}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.total}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="percentageOfTotalChats">
                        <mat-header-cell *matHeaderCellDef>X/Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.percentageOfTotalChats | number:'1.2-2' | percentConcat}}</mat-cell>
                    </ng-container>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </section>
</div>
<div [hidden]="!trendsDataSource">
    <section class="row">
        <mat-card class="full-card">
            <mat-card-header>
                <mat-card-title>Trends</mat-card-title>
                <div class="toggles">
                    <mat-button-toggle-group class="filter-button" value="percentage">
                        <mat-button-toggle value="percentage" (click)="setNumberType('percentage')"> %
                        </mat-button-toggle>
                        <mat-button-toggle value="number" (click)="setNumberType('number')">#</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </mat-card-header>
            <mat-card-content>
                <mat-table #table [dataSource]="trendsDataSource">
                    <ng-container matColumnDef="label">
                        <mat-header-cell *matHeaderCellDef>Time period</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.label}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="visitorRating">
                        <mat-header-cell *matHeaderCellDef>Visitor rating</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.visitorRating ? (element.visitorRating | number:
                            '1.2-2') :'n/a')}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="clientRating">
                        <mat-header-cell *matHeaderCellDef>Client rating</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.clientRating ? (element.clientRating | number:
                            '1.2-2') :'n/a')}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="visits">
                        <mat-header-cell *matHeaderCellDef>Visits</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.visits || 'n/a'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="chatsTotal">
                        <mat-header-cell *matHeaderCellDef>Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.chatsTotal}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invitesDividedByVisits">
                        <mat-header-cell *matHeaderCellDef>Invites/visits</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{(element.invitesDividedByVisits | number:'1.2-2' | percentConcat) || 'n/a'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="chatsVisitsPerc">
                        <mat-header-cell *matHeaderCellDef>CR-Chat</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.visits ? (((element.chatsTotal / element.visits) * 100) | number:'1.2-2' | percentConcat) : 'n/a'}}</mat-cell>
                    </ng-container>
                    <ng-container [matColumnDef]="column" *ngFor="let column of dynamicColumns; index as i">
                        <mat-header-cell *matHeaderCellDef>{{column}}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ getDate(i, element) }}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedTrendsColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedTrendsColumns;"></mat-row>
                </mat-table>
                <mat-paginator [length]=trendsDataSource?.data.length
                               [pageSize]="10"
                               [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </section>
</div>
<div *ngIf="dataLineChart">
    <section class="row">
        <mat-card class="full-card limited-larger">
            <mat-card-header>
                <mat-card-title>{{graphName || 'Trends'}}<span class="subtitle">{{subtitle}}</span></mat-card-title>
                <div class="toggles row">
                    <div>
                        <mat-button-toggle-group #group="matButtonToggleGroup" value="classifications">
                            <mat-button-toggle value="classifications" (click)="setGraphType('classifications', 'Categories')">
                                Categories
                            </mat-button-toggle>
                            <mat-button-toggle value="chats" (click)="setGraphType('chats', 'Chats')">
                                Chats
                            </mat-button-toggle>
                            <mat-button-toggle value="visits" (click)="setGraphType('visits', 'Visits')">
                                Visits
                            </mat-button-toggle>
                            <mat-button-toggle value="visitorRating" (click)="setGraphType('visitorRating', 'Visitor rating')">
                                Visitor rating
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="percentage-toggles">
                        <mat-button-toggle-group *ngIf="checkGraphType('classifications')" class="filter-button"
                                                 value="percentage">
                            <mat-button-toggle value="percentage" (click)="setChartType('percentage')"> %
                            </mat-button-toggle>
                            <mat-button-toggle value="number" (click)="setChartType('number')">#</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="dataLineChart" type="line" [data]="dataLineChart" [options]="optionsLineChart"
                         height="86%"></p-chart>
            </mat-card-content>
        </mat-card>
    </section>
</div>
<section class="row" [hidden]="!errorMessage" >
    <mat-card class="full-card" >
        <mat-card-content>
            <span>{{errorMessage}}</span>
        </mat-card-content>
    </mat-card>
</section>
