<section class="row" *ngIf="!noData && !loading">
    <div class="half-card left">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Online logging <span class="subtitle">{{subtitle}}</span></mat-card-title>
                <div class="toggles">
                    <mat-button-toggle-group #group="matButtonToggleGroup" value="{{ selectedYearMonth }}">
                        <mat-button-toggle value="categoryGraphs" [matMenuTriggerFor]="menuYearMonth">
                            {{'Year-month: '+ selectedYearMonth +'▼'}}
                        </mat-button-toggle>
                        <mat-menu #menuYearMonth="matMenu">
                            <button mat-menu-item
                                    *ngFor="let ym of yearMonths"
                                    (click)="selectYearMonth(ym)">{{ym}}</button>
                        </mat-menu>
                    </mat-button-toggle-group>
                </div>
            </mat-card-header>
            <mat-card-content>
                <mat-table [dataSource]="dataSource" matSort matSortActive="operatorName" matSortDirection="desc"
                           matSortDisableClear="true" >
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.date}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="hoursOnline">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Hours online</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.hoursOnline | number: '1.1-1'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="chats">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Chats</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.chats}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="leads">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Leads</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.leads}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="service">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Service</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.service}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="missed">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Missed</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.missed}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="wasted">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Wasted</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.wasted}}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-table [dataSource]="totals" *ngIf="isAdmin()">
                    <ng-container matColumnDef="date">
                        <mat-cell *matCellDef="let element">{{element.date}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="hoursOnline">
                        <mat-cell *matCellDef="let element">{{element.hoursOnline | number: '1.1-1'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="chats">
                        <mat-cell *matCellDef="let element">{{element.chats}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="leads">
                        <mat-cell *matCellDef="let element">{{element.leads}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="service">
                        <mat-cell *matCellDef="let element">{{element.service}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="missed">
                        <mat-cell *matCellDef="let element">{{element.missed}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="wasted">
                        <mat-cell *matCellDef="let element">{{element.wasted}}</mat-cell>
                    </ng-container>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="half-card-online-hours right">
        <mat-card style="margin-bottom: 16px;" *ngIf="dataHoursPerDay">
            <mat-card-header>
                <mat-card-title>No. of online hours per day
                    <span class="subtitle">{{accountFilter}}</span>
                </mat-card-title>
                <div class="toggles">
                    <mat-button-toggle-group #group="matButtonToggleGroup" value="{{ selectedYearMonth }}">
                        <mat-button-toggle value="categoryGraphs" [matMenuTriggerFor]="menuYearMonth2">
                            {{'Year-month: '+ selectedYearMonth +'▼'}}
                        </mat-button-toggle>
                        <mat-menu #menuYearMonth2="matMenu">
                            <button mat-menu-item
                                    *ngFor="let ym of yearMonths"
                                    (click)="selectYearMonth(ym)">{{ym}}</button>
                        </mat-menu>
                    </mat-button-toggle-group>
                </div>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="dataHoursPerDay" type="bar" [data]="dataHoursPerDay" [options]="optionsHoursPerDay"></p-chart>
            </mat-card-content>
        </mat-card>
        <mat-card class="" *ngIf="dataPeriodsPerDay">
            <mat-card-header>
                <mat-card-title>Online periods per day
                    <span class="subtitle">{{accountFilter}}</span>
                </mat-card-title>
                <div class="toggles">
                    <mat-button-toggle-group #group="matButtonToggleGroup" value="{{ selectedYearMonth }}">
                        <mat-button-toggle value="categoryGraphs" [matMenuTriggerFor]="menuYearMonth3">
                            {{'Year-month: '+ selectedYearMonth +'▼'}}
                        </mat-button-toggle>
                        <mat-menu #menuYearMonth3="matMenu">
                            <button mat-menu-item
                                    *ngFor="let ym of yearMonths"
                                    (click)="selectYearMonth(ym)">{{ym}}</button>
                        </mat-menu>
                    </mat-button-toggle-group>
                </div>
            </mat-card-header>
            <mat-card-content>
                <p-chart *ngIf="dataHoursPerDay" type="bar" [data]="dataPeriodsPerDay" [options]="optionsPeriodPerDay"></p-chart>
            </mat-card-content>
        </mat-card>
    </div>
</section>

<section class="row" *ngIf="noData && !loading">
    <mat-card class="third-card">
        <mat-card-content>
            <span>{{noDataMessage}}<span *ngIf="errorMessage">. {{errorMessage}}</span></span>
        </mat-card-content>
    </mat-card>
</section>

<section class="row" *ngIf="loading">
    <div style="padding-top: 30px; display: flex; justify-content: center; width: 100%">
        <mat-spinner></mat-spinner>
    </div>
</section>
