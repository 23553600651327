<section style="padding-top: 10px" *ngIf="conversationUid">
    <div class="flex flex-column" style="height: 100%">
        <div class="w100" style="position: relative">
            <mat-form-field style="width: 100%;" appearance="outline">
                <textarea matInput placeholder={{placeholder}}
                          [style.height.em]="5"
                          [style.visibility]="historyOnly ? 'hidden' : 'visible'"
                          [readonly]="disabled"
                          (keydown.enter)="$event.preventDefault(); emitReviewMessage()"
                          [formControl]="message"></textarea>

                <span (click)="$event.stopImmediatePropagation()">
                    <app-messages-history
                        [staticMessages]="staticMessages"
                        [reviewMessages]="reviewMessages"
                        [conversationUid]="conversationUid">
                    </app-messages-history>
                </span>
            </mat-form-field>

            <button *ngIf="!disabled" class="send-button" mat-mini-fab (click)="emitReviewMessage()" [disabled]="!hasMessage()">
                <i class="far fa-paper-plane"></i>
            </button>
        </div>
    </div>
</section>


