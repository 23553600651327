<div class="w100 flex-column p1em">

    <div class="flex-start w100 pb1em">
        <div class="w40">

        </div>
        <div class="w15" [style.color]="'grey'">
            Total
        </div>
        <div class="w15" [style.color]="'grey'">
            CR X/Chats
        </div>
        <div class="w15" [style.color]="'grey'">
            CR Automated
        </div>
        <div class="w15" [style.color]="'grey'">
            Visitor rating
        </div>
    </div>

    <div class="flex-column" *ngFor="let c of _counts">
        <div class="flex-start w100 data-row">
            <div class="w40">
                {{c.automatedResponseFlowId}}
            </div>
            <div class="w15">
                {{c.chats}}
            </div>
            <div class="w15">
                {{(c.chats / chats * 100) | number:'1.0-2'}}%
            </div>
            <div class="w15">
                <span *ngIf="automatedChats > 0">
                    {{(c.chats / automatedChats * 100) | number:'1.0-2' || '0'}}%
                </span>
            </div>
            <div class="w15">
                {{c.visitorRating ? (c.visitorRating | number: '1.1-2') : '-'}}
            </div>
        </div>

        <div class="flex-start w100 data-row" *ngFor="let cc of c.chatsByClassification.labels; let i = index" [style.opacity]="0.7" [style.font-size.%]="90">
            <div class="pl1em w40">
                {{cc}}
            </div>
            <div class="w15">
                {{c.chatsByClassification.data[i]}}
            </div>
            <div class="w15">
                {{(c.chatsByClassification.data[i] / chats * 100) | number:'1.0-2'}}%
            </div>
            <div class="w15">
                <span *ngIf="automatedChats > 0">
                    {{(c.chatsByClassification.data[i] / automatedChats * 100) | number:'1.0-2' || '0'}}%
                </span>
            </div>
            <div class="w15"></div>
        </div>
    </div>


</div>
