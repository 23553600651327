import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { GraphFilterService } from '../../../graph/graph-filter.service';
import { ColorService } from '../../../graph/graph-services/color.service';
import { ColorMeaning } from '../../../model/color-meaning.enum';
import { CoreParameters } from '../../../model/core-parameters.model';
import { KeyValuePair } from '../../../model/key-value-pair';
import { SupportService } from '../../service/support.service';
import { SupportFilterService } from '../support-filter.service';

@Component({
  selector: 'app-support-core-front-filter',
  templateUrl: './support-core-front-filter.component.html',
})
export class SupportCoreFrontFilterComponent implements OnInit, OnDestroy {

  supportFilterForm: FormGroup;

  public categoryList: string[];
  public departmentList: string[];
  public categories: string[] = [];
  public departments: string[] = [];

  private subscriptions: Subscription[] = [];

  private clientRemark: boolean = undefined;
  private bconnectRemark: boolean = undefined;
  private operatorRemark: boolean = undefined;
  private operatorFeedback: boolean = undefined;

  private correction: boolean = undefined;
  private hqReview: boolean = undefined;

  private markedTopChat: boolean = false;

  private operatorErrorList: string[];
  private operatorErrors: string[] = [];
  private showTlChats: boolean = !this.authorizationService.isOperator ? undefined : false;

  private qualities: string[] = [];
  private qualityCheckKeyValueList: Array<KeyValuePair<string, boolean>> = [];
  private qualityCheckList: string[] = [];

  private bConnectRatingList: number[] = [];
  private clientRatingList: number[] = [];
  private visitorRatingList: number[] = [];
  private closedReviews: boolean = undefined;
  private languageReview: boolean = undefined;
  private classificationFields: string = '';

  constructor(private supportService: SupportService, private supportFilterService: SupportFilterService,
              private formBuilder: FormBuilder, private graphFilter: GraphFilterService,
              private colorService: ColorService, private authorizationService: AuthorizationService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public departmentToolTip() {
    let toolTip: string;
    if (this.supportFilterForm.get('departments').disabled) {
      toolTip = 'Please select a single Account';
    } else {
      toolTip = this.toolTip('departmentList');
    }
    return toolTip;
  }

  /**
   * generic tooltip generator, takes the arrayList name as argument and uses it to generate a tooltip.
   */
  public toolTip(value: string): string {
    return (this[value] && this[value].length > 0) ?
      this[value].join(', ') : '';
  }

  public clearThisForm(event: any, list: string, formLabel: string): void {
    this.supportFilterForm.get(formLabel).reset();
    this[list] = [];
    event.stopPropagation();
    this.submit();
  }

  ngOnInit() {
    this.createForm();
    this.getData();
    this.onChanges();
    this.submit();
  }

  get isOperator(): boolean {
    return this.authorizationService.isOperator;
  }

  get isClient(): boolean {
    return this.authorizationService.isClient;
  }

  /**
   * submit the form and added the arrays to generate a filterParameter
   */
  submit(): void {
    if (this.supportFilterForm.valid) {
      const form = this.supportFilterForm.value;
      this.supportFilterService.generate({
        bconnectComments: this.bconnectRemark,
        bconnectRating: this.bConnectRatingList,
        categories: form.categories,
        clientComments: this.clientRemark,
        clientRatings: this.clientRatingList,
        closedReviews: this.closedReviews,
        correction: this.correction,
        departments: form.departments,
        hasQualityChecks: this.qualityCheckKeyValueList.find((filterQ) => filterQ.value != null) !== undefined,
        hqReview: this.hqReview,
        languageReview: this.languageReview,
        markedTopChat: this.markedTopChat,
        operatorComments: this.operatorRemark,
        operatorFeedback: this.operatorFeedback,
        operatorErrors: form.operatorErrors,
        qualityChecks: this.qualityCheckKeyValueList,
        search: null,
        searchClassifications: null,
        showTlChats: this.showTlChats,
        visitorRatings: this.visitorRatingList,
        classificationFields: this.classificationFields
      });
    }
  }

  /**
   * a fix for not being able to have a mat-button-toggle-group multiple part of a FormGroup
   * data is the number added to the list, or removed if the source of the event === false.
   */
  clicked(data: number, event: any, list: string) {
    if (event.source.checked) {
      if (this[list].indexOf(data) === -1) {
        this[list].push(data);
      }
    } else {
      const index = this[list].indexOf(data, 0);
      if (index > -1) {
        this[list].splice(index, 1);
      }
    }
    this.submit();
  }

  toggleQualityValue(quality: string, event: MouseEvent) {

    const qualityPair = this.qualityCheckKeyValueList.find((q) => q.key === quality);

    if (qualityPair.value == null) {
      qualityPair.value = true;
    } else if (qualityPair.value === true) {
      qualityPair.value = false;
    } else {
      qualityPair.value = null;
    }

    this.qualityCheckList = this.qualityCheckKeyValueList
      .filter((q) => q.value != null)
      .map((q) => q.key);
    this.supportFilterForm.patchValue({
      qualityChecks: this.qualityCheckList,
    });
    this.submit();
  }

  getBackgroundColor(getAttribute: string, b: boolean) {
    const attribute: boolean = this[getAttribute];
    let color: string;
    if (b) {
      if (attribute === undefined || attribute === true) {
        color = this.colorService.getColorByMeaning(ColorMeaning.GOOD);
      } else {
        color = '#fafafa';
      }
    } else {
      if (attribute === undefined || attribute === false) {
        color = this.colorService.getColorByMeaning(ColorMeaning.BAD);
      } else {
        color = '#fafafa';
      }
    }
    return this.colorService.hexToRGB(color, 0.7);
  }

  getFontColor(getAttribute: string, b: boolean) {
    const attribute: boolean = this[getAttribute];
    let color: string;
    if (b) {
      if (attribute === undefined || attribute === true) {
        color = 'white';
      } else {
        color = 'black';
      }
    } else {
      if (attribute === undefined || attribute === false) {
        color = 'white';
      } else {
        color = 'black';
      }
    }
    return color;
  }

  toggle(getAttribute: string, b: boolean) {
    const attribute: boolean = this[getAttribute];

    if (attribute === undefined) {
      this[getAttribute] = !b;
    } else if (attribute === b) {
      this[getAttribute] = !b;
    } else {
      this[getAttribute] = undefined;
    }

    this.submit();
  }

  getQualityValue(quality: string): string {
    const qualityPair = this.qualityCheckKeyValueList.find((q) => q.key === quality);
    if (qualityPair.value === true) {
      return 'fas fa-thumbs-up';
    } else if (qualityPair.value === false) {
      return 'fas fa-thumbs-down';
    } else {
      return '';
    }
  }

  get isHidden(): boolean {
    return !this.supportFilterService.coreParameterReturnedData;
  }

  visitorHappinessColor(n: number): string {
    return this.happinessColor(n, this.visitorRatingList);
  }

  bConnectRatingListHappinessColor(n: number): string {
    return this.happinessColor(n, this.bConnectRatingList);
  }

  clientHappinessColor(n: number): string {
    return this.happinessColor(n, this.clientRatingList);
  }

  private happinessColor(n: number, list: number[]): string {
    if (list.indexOf(n) > -1) {
      return this.colorService.getColorByGrade(n);
    } else {
      return 'gray';
    }
  }

  private onChanges(): void {
    this.subscriptions.push(this.supportFilterForm.valueChanges.subscribe(() => this.submit()));
    this.subscriptions.push(this.supportFilterForm.get('categories').valueChanges.subscribe(
      (category: string[]) => {
        if (category) {
          this.categoryList = category;
        }
      }));
    this.subscriptions.push(this.supportFilterForm.get('departments').valueChanges.subscribe(
      (department: string[]) => {
        if (department) {
          this.departmentList = department;
        }
      }));

    this.subscriptions.push(this.supportFilterForm.get('operatorErrors').valueChanges.subscribe(
      (operatorErrors: string[]) => {
        if (operatorErrors) {
          this.operatorErrorList = operatorErrors;
        }
      }));

  }

  private createForm(): void {
    this.supportFilterForm = this.formBuilder.group({
      bconnectRemark: null,
      categories: [''],
      clientRemark: null,
      closedReviews: null,
      correction: null,
      departments: [''],
      goed: null,
      hqReview: null,
      markedTopChat: false,
      operatorErrors: [''],
      qualityChecks: [''],
      review: null,
      showTlChats: null,
    });
  }

  private clearQualityCheckList(event: any) {
    this.supportFilterForm.get('qualityChecks').reset();
    this.qualityCheckKeyValueList = this.generateQualityChecks(this.qualities);
    this.qualityCheckList = [];
    event.stopPropagation();
    this.submit();
  }

  private getData() {
    this.subscriptions.push(this.supportService.getOperatorErrorOptions().subscribe((list: string[]) => {
        this.operatorErrors = list;
      },
    ));
    this.subscriptions.push(this.supportService.getQualitychecks().subscribe((list: string[]) => {
        this.qualities = list;
        this.qualityCheckKeyValueList = this.generateQualityChecks(this.qualities);
      },
    ));

    this.subscriptions.push(this.graphFilter.coreParameters$.subscribe((coreParameter) => {
      if (coreParameter.accounts != null && coreParameter.accounts.length === 1) {
        this.getDepartmentsForAccount(coreParameter);
        this.getCategoriesForAccount(coreParameter);
        this.supportFilterService.singleAccountSelected = true;
      } else {
        this.getAllDepartments();
        this.getAllCategories();
        this.supportFilterService.singleAccountSelected = false;
      }
    }));
  }

  private getAllCategories() {
    this.subscriptions.push(this.supportService.getAllCategories().subscribe((list: string[]) =>
      this.categories = list,
    ));
  }

  private getCategoriesForAccount(coreParameter: CoreParameters) {
    this.subscriptions.push(this.supportService
      .getClassificationsForAccount(coreParameter.accounts[0].id).subscribe((list) => {
        this.categories = list;
      }));
  }

  private getAllDepartments() {
    this.departments = [];
    this.departmentList = [];
    const departmentController: AbstractControl = this.supportFilterForm.get('departments');
    if (departmentController != null && departmentController.enabled) {
      departmentController.disable();
    }
  }

  private getDepartmentsForAccount(coreParameter: CoreParameters) {
    this.subscriptions.push(this.supportService
      .getDepartmentsForAccount(coreParameter.accounts[0].id).subscribe((x) => {
        this.departments = x !== null ? x : [];
        this.supportFilterForm.get('departments').enable();
      }));
  }

  private generateQualityChecks(qualities: string[]): Array<KeyValuePair<string, boolean>> {
    return qualities.map((quality) => ({key: quality, value: null}));
  }
}
