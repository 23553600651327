<div style="position: relative">
    <p *ngIf="data.html" [innerHTML]="data.html"></p>
    <p *ngIf="!data.html">{{data.text}}</p>

    <i class="fas fa-times dialog-close-button" (click)="close()"></i>

    <div style="position: relative">


        <mat-form-field style="width: 100%;" appearance="outline">
                <textarea matInput
                          [style.height.px]="100"
                          [style.max-height.px]="textAreaMaxHeight"
                          placeholder={{data.inputPlaceholder}}
                          (keydown.enter)="$event.preventDefault(); submit()"
                          [formControl]="message"></textarea>
        </mat-form-field>

        <app-send-button (onClick)="submit()"></app-send-button>

    </div>

    <div *ngIf="data.optional" class="flex-center">
        <app-small-button [label]="'No comment'" [color]="'white'" (onClick)="noComment()"></app-small-button>
    </div>

</div>
