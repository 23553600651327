import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { CoreParameters } from '../../model/core-parameters.model';
import { OperatorScoreRowsWrapper } from '../../model/operator-score-rows-wrapper-model';
import { OperatorScoreRow } from '../../model/operator-score-rows.model';
import { GraphFilterService } from '../graph-filter.service';
import { ColorService } from '../graph-services/color.service';
import { GraphService } from '../graph.service';
import { CoreParametersConsumer } from '../shared/core-parameters-consumer';
import { KeyLabeledNumericData } from "../../model/key-labeled-numeric-data.model";

@Component({
  selector: 'app-operator-leaderboard',
  templateUrl: './operator-leaderboard.component.html',
  styleUrls: ['./operator-leaderboard.component.scss'],
})
export class OperatorLeaderboardComponent extends CoreParametersConsumer implements OnDestroy {
  subtitle: string;

  readonly defaultColumns1: string[] = [
    'name',
    'combinedRating',
    'visitorRating',
    'clientRating',
    'sessions',
  ];
  readonly defaultColumns2: string[] = [
    'averagePickupTime',
    'averageResponseTime',
    'duration',
    'euroPerHour',
    'chatsPerHour',
    'workLoad',
  ];

  options = {
    legend: {
      position: 'left',
    },
  };

  barOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 5,
          suggestedMin: 0,
        },
      },
    },
  };

  dynamicColumns: string[] = [];
  dynamicColumnsWithExpanded: { name: string, expanded: boolean }[] = [];

  onlyOneAccountActive: boolean;
  displayedColumns: string[] = [];
  operatorDisplayedColumns: string[] = [];
  categoryColumns: KeyLabeledNumericData[][] = [];
  dataSubscription: Subscription;
  operatorDataSource: MatTableDataSource<OperatorScoreRow>;
  teamLeadDataSource: MatTableDataSource<OperatorScoreRow>;
  teamDataSource: MatTableDataSource<OperatorScoreRow>;
  organizationDataSource: MatTableDataSource<OperatorScoreRow>;
  lcdDataSource: MatTableDataSource<OperatorScoreRow>;
  selectedGraph: string = 'leads';
  radarSource: any;
  barSource: any;
  leadSource: any;
  ratingSource: any;
  timeSource: any;
  teamFilter: string;
  errorMessage: string;
  numberView: string = 'percentage';
  numberView$: BehaviorSubject<string> = new BehaviorSubject<string>('percentage');
  categoryCountsByCategoryTypeColumnNames: string[] = [];

  @ViewChild('teamSort', {static: false}) teamSort: MatSort;
  @ViewChild('lcdSort', {static: false}) lcdSort: MatSort;
  @ViewChild('operatorSort', {static: false}) operatorSort: MatSort;
  @ViewChild('teamleadSort', {static: false}) teamleadSort: MatSort;

  constructor(graphFilterService: GraphFilterService,
              private graphService: GraphService,
              private authorizationService: AuthorizationService,
              private colorService: ColorService,
  ) {
    super(graphFilterService);
  }

  setGraphType(graph: string): void {
    this.selectedGraph = graph;
    switch (graph) {
      case 'leads' : {
        this.barSource = this.leadSource;
        this.subtitle = 'Leads per operator';
        break;
      }
      case 'rating' : {
        this.barSource = this.ratingSource;
        this.subtitle = 'Average combined rating per operator';
        break;
      }
      case 'time' : {
        this.barSource = this.timeSource;
        this.subtitle = 'Average times per operator';
        break;
      }
    }
  }

  addColumnToDynamicColumns(parentColumn: string, column: string) {
    const parentIndex = this.operatorDisplayedColumns.findIndex(foundColumn => parentColumn === foundColumn);

    if (!this.categoryCountsByCategoryTypeColumnNames.includes(column)) {
      this.categoryCountsByCategoryTypeColumnNames.push(column);
    }

    this.operatorDisplayedColumns.splice(parentIndex + 1, 0, column);
  }

  removeColumnToDynamicColumns(childColumn: string) {
    const index = this.operatorDisplayedColumns.findIndex(foundColumn => childColumn === foundColumn);

    if (!this.categoryCountsByCategoryTypeColumnNames.includes(childColumn)) {
      const indexOfChildColumn = this.categoryCountsByCategoryTypeColumnNames.findIndex(column => childColumn === column);

      this.categoryCountsByCategoryTypeColumnNames.splice(indexOfChildColumn, 1);
    }

    this.operatorDisplayedColumns.splice(index, 1);
  }

  addOrRemoveColumnToDynamicColumns(parentColumn: { name: string, expanded: boolean }): void {
    const columnNames = Array.from(
      new Set(this.categoryColumns
        .flatMap((klndList) => klndList
          ?.filter((klnd) => klnd.key === parentColumn.name)
          .flatMap((klnd) => klnd.data.labels))
      ))?.filter((label) => label !== "Geen Dialoog" && label !== "Wasted");

    const dynamicColumn = this.dynamicColumnsWithExpanded.find(dynamicColumn => dynamicColumn.name === parentColumn.name);

    columnNames.forEach(column => {
      if (!dynamicColumn.expanded) {
        this.addColumnToDynamicColumns(parentColumn.name, column);
      } else {
        this.removeColumnToDynamicColumns(column);
      }
    });

    dynamicColumn.expanded = !dynamicColumn.expanded;
  }

  getColumnLabelsByKey(key: string) {
    const columns = Array.from(
      new Set(
        this.categoryColumns
          .flatMap((klndList) =>
            klndList
              ?.filter((klnd) => klnd.key === key)
              .flatMap((klnd) => klnd.data.labels)
          )
      )
    )?.filter((label) => label !== "Geen Dialoog" && label !== "Wasted");

    columns.forEach((columnName) => {
      const index = this.dynamicColumns.findIndex((foundColumn) => foundColumn === key);

      this.dynamicColumns.splice(index + 1, 0, columnName);
    });
  }

  loadData(coreParameters: CoreParameters): void {
    this.teamFilter = this.graphService.getLabel(coreParameters);
    this.onlyOneAccountActive = coreParameters.accounts?.length === 1;

    // if (this.hasRequiredParameters(coreParameters)) {
    this.dataSubscription = this.graphService.getOperatorLeaderBoardData(
      coreParameters).subscribe((operatorScoreRows: OperatorScoreRowsWrapper) => {

        if (this.hasInformation(operatorScoreRows.operators)||
          this.hasInformation(operatorScoreRows.teamLeads) ||
          this.hasInformation(operatorScoreRows.teams) ||
          this.hasInformation(operatorScoreRows.lcd) ||
          this.hasInformation(operatorScoreRows.organization)) {


          this.generateDataPercentage(operatorScoreRows.operators);
          this.operatorDataSource = new MatTableDataSource(operatorScoreRows.operators);

          this.operatorDataSource.sort = this.operatorSort;
          this.operatorDataSource.sortData = (data: OperatorScoreRow[], sort: MatSort) => {
            return data.sort((a: OperatorScoreRow, b: OperatorScoreRow) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              } else {
                return 0;
              }
            });
          };

          this.dynamicColumns = this.getDynamicColumns([
            ...operatorScoreRows.operators,
            ...operatorScoreRows.teamLeads,
            ...operatorScoreRows.teams,
            ...operatorScoreRows.lcd,
          ]);

          if (operatorScoreRows.operators.length > 0) {
            this.categoryColumns = operatorScoreRows.operators.map(operatorScoreRow => {
              if (operatorScoreRow.categoryCountsByCategoryType !== null) {
                return operatorScoreRow.categoryCountsByCategoryType
              } else {
                return [];
              }
            });

            this.categoryColumns?.forEach(keyedLabelList => keyedLabelList?.forEach(keyedLabel => {
              if (keyedLabel.data.data[0] === undefined) {
                keyedLabel.data.data.splice(0, 1);
              }
            }));

            this.getColumnLabelsByKey('Lead');
            this.getColumnLabelsByKey('Service');

            this.dynamicColumns.forEach(column => this.dynamicColumnsWithExpanded.push({ name: column, expanded: false }));
          }

          if (this.hasInformation(operatorScoreRows.teamLeads)) {
            this.generateDataPercentage(operatorScoreRows.teamLeads);
            this.teamLeadDataSource = new MatTableDataSource(operatorScoreRows.teamLeads);
            this.teamLeadDataSource.sort = this.teamleadSort;
          } else {
            this.teamLeadDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.teams)) {
            this.generateDataPercentage(operatorScoreRows.teams);
            this.teamDataSource = new MatTableDataSource(operatorScoreRows.teams);
            this.teamDataSource.sort = this.teamSort;
          } else {
            this.teamDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.lcd)) {
            this.generateDataPercentage(operatorScoreRows.lcd);
            this.lcdDataSource = new MatTableDataSource(operatorScoreRows.lcd);
            this.lcdDataSource.sort = this.lcdSort;
          } else {
            this.lcdDataSource = undefined;
          }

          if (this.hasInformation(operatorScoreRows.organization)) {
            this.generateDataPercentage(operatorScoreRows.organization);
            this.organizationDataSource = new MatTableDataSource(operatorScoreRows.organization);
          } else {
            this.organizationDataSource = undefined;
          }

          const subCategories = Array.from(
            new Set(this.categoryColumns
              .flatMap((klndList) => klndList
                .filter((klnd) => klnd.key === 'Lead' || klnd.key === 'Service')
                .flatMap((klnd) => klnd.data.labels))
            )).filter((label) => label !== "Geen Dialoog" && label !== "Wasted");
          this.displayedColumns = this.defaultColumns1.concat(this.dynamicColumns).concat(this.defaultColumns2);

          subCategories.forEach(subCategory => {
            const index = this.displayedColumns.findIndex(column => column === subCategory);

            this.displayedColumns.splice(index, 1);
          });

          this.operatorDisplayedColumns = this.displayedColumns.slice();


          this.operatorDataSource.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          // @ts-ignore
          this.teamDataSource?.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          this.organizationDataSource.sortingDataAccessor = (data: OperatorScoreRow, header: string): string | number => {
            switch (this.numberView) {
              case 'percentage': {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)] / data.sessions;
                } else {
                  return data[header];
                }
              }
              default: {
                if (this.dynamicColumns.indexOf(header) >= 0) {
                  return data.categoryTypeCounts.data[this.dynamicColumns.indexOf(header)];
                } else {
                  return data[header];
                }
              }
            }
          };

          const leadData = [];
          const rating = [];
          const labels = [];
          // const leads = [];
          const avgDuration = [];
          const visitorRating = [];
          const averageResponseTime = [];
          const averagePickupTime = [];
          operatorScoreRows.operators.sort((a, b) => a.name.localeCompare(b.name));
          operatorScoreRows.operators.forEach((operatorScoreRow: OperatorScoreRow) => {
            // leads.push(operatorScoreRow.categoryTypeCounts)
            labels.push(operatorScoreRow.name);
            rating.push(operatorScoreRow.combinedRating);
            avgDuration.push(operatorScoreRow.duration);
            leadData.push(operatorScoreRow.categoryTypeCounts.data[operatorScoreRow.categoryTypeCounts.labels.indexOf('Lead')]);
            visitorRating.push(operatorScoreRow.visitorRating);
            averagePickupTime.push(operatorScoreRow.averagePickupTime);
            averageResponseTime.push(operatorScoreRow.averageResponseTime);
          });
          this.leadSource = undefined;
          this.ratingSource = undefined;

          if (labels.length > 1) {
            this.leadSource = this.generateBarSource(labels, leadData, 'Leads');
            this.ratingSource = this.generateBarSource(labels, rating, 'Rating');
            this.timeSource = {
              datasets: [
                {
                  backgroundColor: this.colorService.getColor(1),
                  data: avgDuration,
                  label: 'Average chat duration',
                },
                {
                  backgroundColor: this.colorService.getColor(3),
                  data: averagePickupTime,
                  label: 'Average pickup time',
                },
                {
                  backgroundColor: this.colorService.getColor(5),
                  data: averageResponseTime,
                  label: 'Average response time',
                },
              ],
              labels,
            };
}

          if (operatorScoreRows.operators.length > 3) {
            this.radarSource = {
              datasets: [
                {
                  backgroundColor: 'rgba(144,202,249,0.2)',
                  borderColor: 'rgba(144,202,249,1)',
                  data: visitorRating,
                  label: 'Vistor rating',
                  pointBackgroundColor: 'rgba(144,202,249,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(144,202,249,1)',
                },
                {
                  backgroundColor: 'rgba(13,71,161,0.2)',
                  borderColor: 'rgba(13,71,161,1)',
                  data: leadData,
                  label: 'Leads',
                  pointBackgroundColor: 'rgba(13,71,161,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(13,71,161,1)',
                },
              ],
              labels,
            };

          } else {
            this.radarSource = undefined;}
          }

        this.setGraphType(this.selectedGraph);
      });
  }

  isAdmin() {
    return this.authorizationService.isAdmin;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  isLCD() {
    return this.authorizationService.isLcd;
  }

  public setNumberType(type: string) {
    this.numberView = type;
    this.numberView$.next(type);
  }

  public getData(key: string, element: any): string {

    const i = element.categoryTypeCounts.labels.indexOf(key);

    let date: string = 'n/a';
    if (element.categoryTypeCounts.data[i] !== 0) {
      switch (this.numberView) {
        case 'percentage' : {
          date = (Math.round(element.categoryTypeCounts.dataPercentage[i] * 100) / 100).toFixed(2) + '%';
          break;
        }
        case 'number' : {
          date = element.categoryTypeCounts.data[i];
          break;
        }
      }
    }
    return date;
  }

  public getDataForSubCategoryOrCategory(key: string, element: OperatorScoreRow): string {
    const labeledNumericData = key === 'Lead' || key === 'Service' ? element.categoryTypeCounts :
      element.categoryCountsByCategoryType?.find((numericData) => numericData.data.labels.some(label => label === key))?.data;
    const i = labeledNumericData?.labels.indexOf(key);
    let date: any = 'n/a';

    if (!!labeledNumericData?.data[i]) {
      switch (this.numberView) {
        case 'percentage': {
          date = (Math.round(labeledNumericData.dataPercentage[i] * 100) / 100).toFixed(2) + '%';
          break;
        }
        case 'number': {
          date = labeledNumericData.data[i];
          break;
        }
      }
    }

    return date;
  }

  public getWorkLoadColor(workLoad: number): string {
    if (workLoad == null) {
      return 'black';
    } else if (workLoad >= 100) {
      return 'green';
    } else if (workLoad < 100) {
      return 'red';
    } else {
      return 'black';
    }
  }

  private hasInformation(rows: OperatorScoreRow[]): boolean {
    let errorMessage: string;
    if (rows.length === 0) {
      errorMessage = 'There is no data available';
    }
    this.errorMessage = errorMessage;
    return this.errorMessage == null;
  }

  private generateBarSource(labels: any[], leadData: number[], label: string): any {
    return {
      datasets: [
        {
          backgroundColor: this.colorService.getColor(0),
          data: leadData,
          label,
        },
      ],
      labels,
    };
  }

  private generateDataPercentage(operatorScoreRows: OperatorScoreRow[]) {
    operatorScoreRows.forEach((operatorScoreRow) => {
      const total = operatorScoreRow.sessions;

      operatorScoreRow.categoryTypeCounts.dataPercentage =
        operatorScoreRow.categoryTypeCounts.data.map(value => (value / total) * 100);

      operatorScoreRow.categoryCountsByCategoryType?.forEach(categoryCounts =>
        categoryCounts.data.dataPercentage = categoryCounts.data.data.map(value => (value / total) * 100)
      );
    });
  }

  private getDynamicColumns(operatorScoreRows: OperatorScoreRow[]): string[] {
    const columns = [];

    operatorScoreRows.forEach(r => {
      r.categoryTypeCounts.labels.forEach((label) => {
        if (columns.indexOf(label) < 0) {
          columns.push(label);
        }
      });
    });

    columns.sort((a, b) => {
      const order = ['Lead', 'Service', 'Missed', 'Wasted', 'Geen Dialoog'];
      return order.indexOf(a) - order.indexOf(b);
    });

    return columns;
  }
}
