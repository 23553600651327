<mat-card [ngClass]="cardClass">
    <mat-card-header class="mat-card-header-score-card-title">
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="score?.toString().length > 2; else displayCircle" class="pill-shape" [ngClass]="colorClass">
            <div id="score" class="pill-text"
                 [ngStyle]="{'font-size':getFontSize()}">
                {{(score | number:'1.0-2') || 'n/a'}}
            </div>
        </div>
        <ng-template  #displayCircle>
            <div class="circle" [ngClass]="colorClass">
                <div class="circle-text"
                     [ngStyle]="{'font-size':getFontSize()}">
                    {{(score | number:'1.0-2') || 'n/a'}}
                </div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
