<div [style.background-color]="'white'">
    <div>
        <div *ngIf="isLCDOrBetter && maySelectClient">
            <mat-form-field style="width: 100%" appearance="outline">
                <mat-select placeholder="Select a client" [(ngModel)]="clientReceiver" [disabled]="(stage === 'TO_CLIENT')  || reviewClosed">
                    <mat-option (click)="resetReceiver()" value="null">
                        <i>Please select a client</i>
                    </mat-option>
                    <mat-option *ngFor="let userShort of receiverList" [value]="userShort">
                        {{userShort.userName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <div class="faq-buttons">
                <app-small-button [color]="'white'"
                                  [label]="'Save'"
                                  (onClick)="save()">
                </app-small-button>

                <app-small-button [color]="'white'"
                                  *ngIf="isLCDOrBetter && maySelectClient"
                                  [disabled]="disabled || !clientReceiver"
                                  [label]="'Send to client'"
                                  (onClick)="sendToClient()">
                </app-small-button>

                <app-small-button [color]="'white'"
                                  *ngIf="(isTeamLead || isOperator) && stage === 'CREATED'"
                                  [disabled]="disabled"
                                  [label]="'Send to supervisor'"
                                  (onClick)="sendToSupervisor()">
                </app-small-button>

                <app-small-button [color]="'white'"
                                  *ngIf="isClient && (stage === 'CREATED' || stage === 'TO_CLIENT')"
                                  [disabled]="disabled"
                                  [label]="'Submit FAQ'"
                                  (onClick)="sendFromClient()">
                </app-small-button>

                <app-small-button [color]="'white'"
                                  *ngIf="isLCDOrBetter && (stage !== 'ACCEPTED' && stage !== 'TO_CLIENT')"
                                  [disabled]="disabled || !mayAddToKb()"
                                  [label]="'Add to KB'"
                                  (onClick)="addToKb()">
                </app-small-button>

                <app-small-button [color]="'white'"
                                  *ngIf="isLCDOrBetter && stage !== 'ACCEPTED' && stage !== 'CANCELED'"
                                  [label]="'Cancel addition'"
                                  (onClick)="cancelFaqAddition()">
                </app-small-button>

            </div>
            <div class="delete-button" *ngIf="mayDelete()">
                <app-delete-button (onConfirm)="deleteFaqAddition()"></app-delete-button>
            </div>
        </div>
    </div>

</div>
