<section class="row">
    <ng-container *ngFor="let d of data">
        <mat-card class="full-card">
            <mat-card-header >
                <div>
                    <mat-card-title>
                        Word cloud<span class="subtitle">{{subtitle}}</span><span class="subtitle" style="margin-right: 14px">-</span><span> {{d.language}}</span><span class="subtitle">-</span><span class="subtitle">Over {{d.totalChats}} chats</span>
                    </mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content [hidden]= " errorMessage">
                <div class="flex-between" style="height: 500px">
                    <!--                <mat-grid-list cols="4" rowHeight="400px">-->
                    <!--                    <mat-grid-tile [colspan]="3">-->
                    <div class="w70">
                        <angular-tag-cloud [config]="cloudOptions" [data]="d.data"  style="font-family: 'RocGrotesk-Regular', Arial, Helvetica, sans-serif;"></angular-tag-cloud>
                    </div>
                    <!--                    </mat-grid-tile >-->
                    <!--                    <mat-grid-tile [colspan]="1">-->
                    <div style="overflow-y: scroll; height: 100%; border: 1px solid #ccc; border-radius: 4px;">
                        <mat-table [dataSource]="d.data" *ngIf="tableData !== undefined" style="width: max-content">
                            <ng-container matColumnDef="text">
                                <th mat-header-cell *matHeaderCellDef> Text </th>
                                <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="weight">
                                <th mat-header-cell *matHeaderCellDef> Weight </th>
                                <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                            </ng-container>

                            <!-- Count Column -->
                            <ng-container matColumnDef="count">
                                <th mat-header-cell *matHeaderCellDef> Count </th>
                                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </mat-table>
                    </div>
                    <!--                    </mat-grid-tile>-->
                    <!--                    <mat-grid-tile [colspan]="4" *ngIf="!(data[lang] === undefined || data[lang].length >= 1)">-->
                    <!--                        <p>No workcloud data available for this language. Try again.</p>-->
                    <!--                    </mat-grid-tile >-->
                    <!--                </mat-grid-list>-->
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</section>